<nb-card class="p-1">


    <nb-card-header>
        <div class="c-header d-flex d-flex justify-content-between">
            <button nbButton outline status="primary" class="btnAddClient  m-1 w-100" (click)="filter('A')">Todas</button>
            <button nbButton outline status="primary" class="btnAddClient  m-1 w-100" (click)="filter('P')">Pendientes</button>
            <button nbButton outline status="primary" class="btnAddClient  m-1 w-100" (click)="filter('C')">Hechas</button>
        </div>
    </nb-card-header>


    <nb-card-body class="">



        <h6 class="">{{ title }}</h6>
        <ul class="list-group">
            <app-item [item]="item" [index]="i" *ngFor="let item of listItems | filterItems:codeFilter,
                let i = index"></app-item>
        </ul>



    </nb-card-body>

    <nb-card-footer>


        <div class="c-footer" [class.valid-input]="txtInput.invalid">
            <input class="border border-primary" type="text" [formControl]="txtInput" (keydown.enter)="save()" placeholder="Añadir tarea" nbInput
                fullWidth>
        </div>

    </nb-card-footer>


</nb-card>