

<section class="page-section m-2 pt-1" id="services">
  <div class="container-fluid">
      <div class="text-center">
          
      </div>

      <div class="jumbotron">
          <h1 class="display-4">Gastos fijos</h1>
          <!-- <p class="lead">Este es el panel de administracion de los clientes de Wilapp</p>
          <hr class="my-4">
          <p>Podrás administrar tus clientes desde este panel para dar de alta, baja y soporte para los clientes</p>
          <p class="lead">
          
            <button nbButton outline status="primary" class="btnAddClient m-1" routerLink="/pages/admin/empresas">Profesionales</button>
            <button nbButton outline status="primary" class="btnAddClient m-1" routerLink="/pages/admin/empresas/new">Nuenva empresa</button>
            <button nbButton outline status="primary" class="btnAddClient m-1" routerLink="/pages/admin/empresas/new">facturación</button>
            <button nbButton outline status="primary" class="btnAddClient m-1" routerLink="/pages/admin/empresas/new">Soporte</button> -->
            <!-- <button nbButton outline status="danger" class="btnAddClient btn btn-danger m-1 btn-lg" (click)="salir()">
              
              Salir
            </button> -->
          <!-- </p> -->

          
        </div>

  </div>
</section>


<!-- <section class="mt-1 pt-1">

  <div class="container-fluid pl-0 pr-0">
    <div class="text-center d-flex justify-content-between">
      <div>
        <h3 class="section-heading">Gastos

        </h3>


      </div>
      <div>

        <i class="fas fa-chart-bar text-danger display-3"></i>

      </div>

    </div>

    <nb-card>
      <nb-card-body class="text-center">

        <div class="row text-center p-2 d-flex justify-content-center">

          <h4 class="text-center m-1">La Empresa ha gastado este mes<br><span
              class="display-4">{{totalIngresadoMes}}€</span> <br></h4>

        </div>

      </nb-card-body>
    </nb-card>


    <div class="row">
      <div class="col">



        <h5>Gastos por usuarios</h5>

        <div class="col-sm-12 mb-2" *ngFor="let i of datosTotales">
          <nb-accordion>
            <nb-accordion-item>
              <nb-accordion-item-header class="d-flex justify-content-between  pr-5">
                <div>
                  {{ i.usuario}}
                </div>
                <div>
                  {{i.sumaTotalMes}}€
                </div>


              </nb-accordion-item-header>
              <nb-accordion-item-body>
                <div class="row text-center">
                  <div class="col-md col-sm-12">
                    <ng-container *ngFor="let item of datos">

                      <nb-card class="fondo m-1 mr-0 ml-0" *ngIf="i.usuario==item.usuario">
                        <nb-card-body class="text-center ">

                          <div class="row no-gutters align-items-center">
                            <div class="col d-flex justify-content-start">
                              <p class="mb-0"> {{item.concepto}}</p>
                            </div>
                            <div class="col-auto">

                              {{item.cantidad}}€

                            </div>
                          </div>

                        </nb-card-body>
                      </nb-card>

                    </ng-container>

                  </div>
                </div>
              </nb-accordion-item-body>
            </nb-accordion-item>
          </nb-accordion>
        </div>

      </div>

    </div>




  </div>


</section> -->
