
<div class="card border-0 m-2 bg-transparent">
    <div class="card-body w-100 ">
        <div class="sizeChart d-flex justify-content-center flex-wrap">
            <h5 class="card-title text-primary mb-0">Tu día a día</h5>
            <ngx-charts-heat-map [legend]="legend" [scheme]="colorScheme" [xAxis]="xAxis"
            [yAxis]="yAxis" 
                [results]="multi" (select)="onSelect($event)" (activate)="onActivate($event)"
                (deactivate)="onDeactivate($event)">
            </ngx-charts-heat-map>
        </div>
    </div>
</div>