import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { NbAuthService, NbAuthJWTToken } from 'src/framework/auth/public_api';

@Component({
  selector: 'app-Correcto',
  templateUrl: './Correcto.component.html',
  styleUrls: ['./Correcto.component.scss']
})
export class CorrectoComponent implements OnInit {


  user :any;
  nombreCompleto:any;
  myArray: any;
  nombre: any;

  constructor(private authService: NbAuthService,private _location: Location) {

    this.authService.onTokenChange()
    .subscribe((token: NbAuthJWTToken) => {

      this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
        this.nombreCompleto = this.user.data.fullname;
        this.myArray = this.nombreCompleto.split(" ");
        this.nombre = this.myArray[0];

        // alert(this.nombreCompleto);

      // if (token.isValid()) {
      //   this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
      // }
    });

   }

  ngOnInit() {
  }


  goBack(){

    this._location.back();
  }

}
