<div class="header-container">
    <div class="logo-container">
        <a (click)="toggleSidebar()" href="#" class="sidebar-toggle ">
            <!-- <nb-icon class="text-info" icon="menu-2-outline"></nb-icon> -->
            <!-- <i class="fas fa-business-time fa-inverse text-primary display-4 m-1"></i> -->
            <!-- <i class=" fas fa-solid fa-bars text-primary"></i> -->
            <i class="fa fa-bars" aria-hidden="true"></i>
        </a>
        <!-- <a class="logo  control-item" routerLink="/pages/inicio"><img class="img-logo"
                src="assets/img/logo_accionext.png"></a> -->

        <a class="logo  control-item" routerLink="/pages/inicio"><img class="img-logo-min"
                src="assets/img/favicon.png"></a>


        <a class='history-back mr-1' (click)="goBack()" *ngIf="enInicio == false" >
            <i class="fas fa-arrow-left text-primary" style="font-size: 25px!important;"></i>
        </a>
    </div>
    <div class="userMenuContainer d-flex justify-content-end align-items-center w-100" onclick="activaBuscador()">
        <div class="w-100 buscador-container d-flex justify-content-between align-items-center">
            <nb-search type="rotate-layout" placeholder="Buscar..." hint="Intruduce lo que quieras buscar"></nb-search>
            <app-menu-user class="m-1"></app-menu-user>
        </div>


    </div>
    <ng-container *ngIf="notificacionesNuevas == false; else elseTemplate">
        <a class="ml-2 control-item" routerLink="notificaciones"><i class="far fa-bell  mr-3  text-primary"
                style="font-size:25px!important;"></i></a>
    </ng-container>
    <ng-template #elseTemplate>
        <a class="ml-2 control-item" routerLink="notificaciones"><i class="far fa-bell  mr-3 text-danger"
                style="font-size:25px!important;"></i></a>
    </ng-template>
    <!-- <app-menu-user></app-menu-user> -->
</div>
