import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Respuesta } from './respuesta';
import { Peticion } from './peticion';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private url: string = "";
  private ArrayG: Array<Respuesta>;
  constructor(private http: HttpClient) { }

  postRespuesta(_body: any, url:string): Observable<any> {
    	this.url=url;
    return this.http.post<Respuesta>(this.url, _body);
  }

  getRespuesta(): Observable<Respuesta[]> {
    return this.http.get<Respuesta[]>(this.url);
  }

  putRespuesta(_id: number, _body: Peticion): Observable<Respuesta> {
    let urlcom = this.url + "/" + _id;
    return this.http.put<Respuesta>(urlcom, _body);
  }

  //Recibe la suscripción push del service-worker cuando se suscribe, toma la key, el token y el contentEncoding y cifra el token de la suscripcion y la key para mandarla a la API.
  
  addSubscription(sub: PushSubscription, body: any, url: string){
    this.url=url;
    const key = sub.getKey('p256dh');
    const token = sub.getKey('auth');
    const contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0];
    let jsonKey: string = "";
    let jsonToken: string = "";

    (new Uint8Array(key!)).forEach(function (byte: number) {
      jsonKey += String.fromCharCode(byte);
    });

    (new Uint8Array(token!)).forEach(function (byte: number) {
      jsonToken += String.fromCharCode(byte);
    });

    return this.http.post(this.url, JSON.stringify({ 
        endpoint: sub.endpoint,
        publicKey: jsonKey ? btoa(jsonKey): null,
        authToken: jsonToken ? btoa(jsonToken): null,
        contentEncoding,
        token: body
        })
      
      
    );
  }

  
}
