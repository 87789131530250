import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';


import { FormControl, NgForm } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/conexionApi.service';
import { NbAuthService, NbAuthJWTToken } from 'src/framework/auth/public_api';
import { Peticion } from 'src/app/services/peticion';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-completar-cliente',
  templateUrl: './completar-cliente.component.html',
  styleUrls: ['./completar-cliente.component.scss']
})
export class CompletarClienteComponent implements OnInit {

  value: string;

  servicios: string[];
  filteredServicios$: Observable<string[]>;
  inputFormControlServicios: FormControl;

  datos = [];

  //
  options: string[];
  filteredOptions$: Observable<string[]>;
  inputFormControlClientes: FormControl;


  // variables para los inputs del ingreso
  inputServicio: string = null;
  inputImporte: string = null;

  inputCliente: string = null;

  datosFormulario = [];

  parametro = this.route.snapshot.url.toString();
  id_cliente = this.parametro.split("-");



  constructor(private authService: NbAuthService, private router: Router,private route: ActivatedRoute, private http: ApiService, private cdRef: ChangeDetectorRef, private formBuilder: FormBuilder,private activatedRoute: ActivatedRoute) {

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {

        // if (token.isValid()) {
        //   this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
        // }
      });


  }

  ngOnInit() {

    var t = localStorage.getItem('auth_app_token');
    this.obtenerDatosClientes({ token: t });
    this.obtenerDatosServicios({ token: t });

    this.servicios = [];
    this.options = [];

  }

  t = localStorage.getItem('auth_app_token');


  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.servicios.filter(optionValue => optionValue.toLowerCase().includes(filterValue));
  }

  private filterClientes(value: string): string[] {
    const filterValueClientes = value.toLowerCase();
    return this.options.filter(optionValue => optionValue.toLowerCase().includes(filterValueClientes));
  }

  trackByFn(index, item) {
    return item.name;
  }


  ingresarPost(body: Peticion) {
    this.http.postRespuesta(body, "https://app.accionext.com/api/ingresos/ingresar.php").subscribe(
      data => {
        //this.datos = data;
        // this.user['data']['fullname'] = this.datos['fullname'];
        // localStorage.setItem('auth_app_token', JSON.stringify({'name':'nb:auth:jwt:token','ownerStrategyName':'email','createdAt':1589115670000,'value': this.datos['token']}));

      },
      err => { }
    );

  }

  // ingresar() {


  //   if (this.inputServicio == null) {

  //     alert("El campo servicio está vació");
  //   } else {

  //     if (this.inputImporte == null) {

  //       alert("El campo importe está vació");

  //     } else {

  //       if (this.inputCliente == null) {

  //         alert("El campo cliente está vació");
  //       } else {

  //         this.datosFormulario = [this.inputServicio, this.inputImporte, this.inputCliente];
  //         this.ingresarPost({ servicio: this.inputServicio, importe: this.inputImporte, cliente: this.inputCliente, token: this.t });
  //         this.router.navigate(['/pages/correcto']);

  //       }
  //     }
  //   }

  //   this.cdRef.markForCheck();

  // }


  obtenerDatosClientes(body: Peticion) {
    this.http.postRespuesta(body, "https://app.accionext.com/api/clientes/clientes.php").subscribe(
      info => {
        this.datos = info;

        for (let index = 0; index < this.datos.length; index++) {
          this.options.push(+this.datos[index].id + " - " + this.datos[index].nombre + " " + this.datos[index].apellidos + " | " + this.datos[index].dni);
          // aqui esta el id para edentificar al cliente
        }

        this.filteredOptions$ = of(this.options);
        this.inputFormControlClientes = new FormControl();

        this.filteredOptions$ = this.inputFormControlClientes.valueChanges
          .pipe(
            startWith(''),
            map(filterStringClientes => this.filterClientes(filterStringClientes)),
          );

        this.inputImporte = "20";
        this.cdRef.markForCheck();


      },
      err => {
        console.log(err);
      }
    );
  }

  obtenerDatosServicios(body: Peticion) {
    this.http.postRespuesta(body, "https://app.accionext.com/api/servicios/servicios.php").subscribe(
      info => {
        this.datos = info;

        for (let index = 0; index < this.datos.length; index++) {
          this.servicios.push(this.datos[index].nombre);
          // aqui esta el id para edentificar al cliente
        }

        this.filteredServicios$ = of(this.servicios);
        this.inputFormControlServicios = new FormControl();

        this.filteredServicios$ = this.inputFormControlServicios.valueChanges
          .pipe(
            startWith(''),
            map(filterString => this.filter(filterString)),
          );

        this.cdRef.markForCheck();


      },
      err => {
        console.log(err);
      }
    );
  }

  seleccionarCliente(){

    // alert(this.inputCliente);


    this.id_cliente = this.inputCliente.split(" ");
    var id = this.id_cliente[0];

    this.router.navigate(['./',id], {relativeTo: this.activatedRoute})


  }

}
