import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class TokenServices {

  constructor() { }

  //Recibe el token por parámetro, lo decodifica y devuelve el rol contenido en el token.

  public getDecodedTokenRole(token: string){
    try{
       const DECODED_TOKEN: any = jwt_decode(token);

      return DECODED_TOKEN.data.rol;

    } catch(error){
      return error;
    }
  }

}
