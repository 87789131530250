import { Component, Inject, OnInit } from '@angular/core';
import { NB_WINDOW, NbMenuService } from '@nebular/theme';
import { filter, map } from 'rxjs/operators';
import { NbAuthService, NbAuthJWTToken } from 'src/framework/auth/public_api';
import { Router } from '@angular/router';



@Component({
  selector: 'app-menu-user',
  templateUrl: './menu-user.component.html',
  styles: [`
    :host nb-layout-header ::ng-deep nav {
      justify-content: flex-end;
    }
  `],
})
export class MenuUserComponent implements OnInit {
  items = [
    { title: 'Perfil' },
    { title: 'Salir' },
  ];

  user :any;
  x:any;
  constructor(private nbMenuService: NbMenuService, @Inject(NB_WINDOW) private window, private authService: NbAuthService,private router: Router) {
    
    this.authService.onTokenChange()
    .subscribe((token: NbAuthJWTToken) => {

      if (token.isValid()) {
        this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable 
      }
    });
  
  }
  
  ngOnInit() {
    this.nbMenuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'user-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => this.opcion(title));
  }

  opcion(t){
    if (t=="Salir") {
      this.router.navigate(['/auth/logout']);
    } else {
      this.router.navigate(['/pages/perfil']);
      
    }
  }
  esconder(){
    if (window.innerWidth>550) { 
      return false;
    } else {
      return true;
    }
  }
}
