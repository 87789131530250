import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-gestor-de-tareas',
  templateUrl: './gestor-de-tareas.component.html',
  styleUrls: ['./gestor-de-tareas.component.scss']
})
export class GestorDeTareasComponent implements OnInit {

  checkedNotif:boolean = true;
  todo: String[] = [
    'Complemento de infancia X7353719K',
    'Nacionalidad',
    'Arraigo por formación',
    'Renovación',
    'Arrago social',
    'Registrar en almacenes',
    'Enviar curriculum',
    'Arrago laboral',
    'Readgupación familiar'
  ];

  enProceso: String[] = [
    'Complemento de infancia X7353719K',
    // 'Nacionalidad',
    // 'Arraigo por formación',
    // 'Renovación',
    
    // 'Arrago laboral',
    // 'Readgupación familiar'
  ];

  hecho: String[] = [
    
    'Nacionalidad',
    // 'Arraigo por formación',
    // 'Renovación',
    // 'Arrago social',
    // 'Registrar en almacenes',
    
  ];

  // done = ['Get up', 'Brush teeth', 'Take a shower', 'Check e-mail', 'Walk dog'];

  constructor() { }

  ngOnInit(): void {
  }

  // drop(event: CdkDragDrop<string[]>) {
  //   moveItemInArray(this.movies, event.previousIndex, event.currentIndex);
  // }


  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  changePushSubscription(): boolean{
    
    

      return this.checkedNotif; //Devuelve el estado de la notificación.

    

  }


}
