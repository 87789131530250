import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';


import { FormControl, NgForm } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/conexionApi.service';
import { NbAuthService, NbAuthJWTToken } from 'src/framework/auth/public_api';
import { Peticion } from 'src/app/services/peticion';
import { FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-autocompletar',
  templateUrl: './autocompletar.component.html',
  styleUrls: ['./autocompletar.component.scss']
})
export class AutocompletarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
