<nb-card>
  <nb-card-header class="text-center">

    <h3>Se ha realizado correctamente</h3>
    <i class="fas fa-thumbs-up display-1 m-3 text-primary"></i>

  </nb-card-header>

  <nb-card-body class="text-center">
    <h4 class="m-3">Muchas Gracias {{nombre}}</h4>

    <button nbButton fullWidth outline status="primary" class="text-white" (click)="goBack()">
      Volver
    </button>
  </nb-card-body>

</nb-card>
