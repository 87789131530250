

<nb-chat [title]="nombre" size="medium" status="primary" class="chatEspaciado">
  <nb-chat-message *ngFor="let message of messages" [type]="message.type" [message]="message.text"
      [reply]="message.reply" [sender]="message.user.name" [date]="message.date" [files]="message.files"
      [quote]="message.quote" [latitude]="message.latitude" [longitude]="message.longitude"
      [avatar]="message.user.avatar"></nb-chat-message>

      <nb-chat-form  #divClick (send)="sendMessage($event, 'Yo,', 'http://www.reactiongifs.com/r/wnd1.gif', true)"
          [showButton]="false" [dropFiles]="true" [messagePlaceholder]="'Escribe tu mensaje'">
      </nb-chat-form>


</nb-chat>

<!-- <p>holaaaaaaa</p> -->
