import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule} from '@angular/core';
import localeEs from '@angular/common/locales/es';
import { DatePipe, registerLocaleData } from '@angular/common';
registerLocaleData(localeEs, 'es');

import {FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';



import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './componentes/footer/footer.component';
import { HeaderComponent } from './componentes/header/header.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbThemeModule, NbLayoutModule, NbMenuModule, NbContextMenuModule, NbUserModule, NbWindowModule, NbSearchComponent, NbSearchModule, NbTabsetModule, NbSelectModule, NbDatepickerModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';


import { NbAlertModule, NbButtonModule, NbCheckboxModule, NbInputModule, NbSidebarModule } from '@nebular/theme';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NbIconModule } from '@nebular/theme';

import { PagesComponent } from './componentes/pages/pages.component';


import { NbSecurityModule } from '@nebular/security';
import { NbTokenLocalStorage } from 'src/framework/auth/public_api';
import { PagesModule } from './componentes/pages/pages.module';
import { MenuUserComponent } from './componentes/header/menu-user/menu-user.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgFallimgModule } from 'ng-fallimg';
import { NbAutocompleteModule, NbRouteTabsetModule } from 'src/framework/theme/public_api';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ToastrModule } from 'ngx-toastr';
import { NbDialogService } from '../framework/theme/components/dialog/dialog.service';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';





@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,

    PagesComponent,

    MenuUserComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    PagesModule,

    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    NbSidebarModule.forRoot(),




    NbThemeModule.forRoot({ name: 'default' }),

    NbLayoutModule,

    NbEvaIconsModule,

    NbMenuModule.forRoot(),
    NbSecurityModule.forRoot(),
    NbContextMenuModule,
    NbUserModule,
    NbIconModule,
  
    FormsModule,
    NbSearchModule,

    NbRouteTabsetModule,
    NbWindowModule.forRoot(),

    ToastrModule.forRoot(),
    NbTabsetModule,
    NbDatepickerModule.forRoot(),

    BrowserAnimationsModule,

    // NgxToastNotifierModule.forRoot(),

   



    NgFallimgModule.forRoot({
      default: '/app/media/no_disponible.png',
      picture1:  'assets/mypicture.png',
      picture2: 'data:image/jpeg;base64, ... ',
    }),
            ServiceWorkerModule.register('ngsw-worker.js', {
              enabled: environment.production,
              // Register the ServiceWorker as soon as the app is stable
              // or after 30 seconds (whichever comes first).
              registrationStrategy: 'registerWhenStable:30000'
            }),
            ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })

  ],
  providers: [
    NbTokenLocalStorage,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: LOCALE_ID, useValue: 'es',

    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
