<section class="page-section mt-1 pt-1" id="services">
    <div class="container-fluid">
        <div class="text-center">
            <!-- <h2 class="section-heading text-uppercase">¡Bienvenido a accioges!</h2> -->
        </div>

        <!-- Content Row -->
        <div class="row mt-1 mb-3">
            <div class="col-xl-3 col-md-6 mb-2 p-0">
                <div class="card border-secondary py-1 btn bAzul" [routerLink]="['/pages/economia/tesoreria']">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2 d-flex justify-content-start">
                                <h6 class="text-primary">Tesorería</h6>
                            </div>
                            <div class="col-auto">
                                <i class="fa fa-tasks fa-inverse text-primary h1 m-1"></i>
                                <i class="iconoir-small-shop-alt lead"> </i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-3 col-md-6 mb-2 p-0">
                <div class="card border-primary py-1 btn bAzul" [routerLink]="['/pages/economia/probabilidad']">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2 d-flex justify-content-start">
                                <h6 class="text-primary">Probabilidad</h6>
                            </div>
                            <div class="col-auto">
                                <!-- <i class="fas fa-hand-holding-usd fa-inverse text-success h1 m-1"></i> -->
                                <i class="fa fa-comments fa-inverse text-primary h1 m-1"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>