<nb-card>
    <nb-card-header>
        <nb-user [name]="user.data.fullname" [title]="user.data.email"></nb-user>
    </nb-card-header>
    <nb-card-body>
        <p>Permisos</p>
        <div class="row">
            <div class="col-sm-12 flex-md-column col-md-6 d-flex justify-content-between align-items-center">
                <label class="label text-primary">Notificaciones push</label>
                <nb-toggle [checked]="checkedNotif" (checkedChange)="changePushSubscription()"></nb-toggle>
            </div>
            <div class="col-sm-12 flex-md-column col-md-6 d-flex justify-content-between align-items-center" *ngIf="ubicacionSoportada">
                <label class="label text-primary">Ubicación</label>
                <nb-toggle [checked]="checkedUbi" (checkedChange)="changeLocationPermissions()"></nb-toggle>
            </div>
        </div>
    </nb-card-body>
    <!-- <nb-card-body>
        <form #usuarioForm="ngForm" (ngSubmit)="guardar(usuarioForm)" novalidate>
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <label for="nombre" class="label">Nombre completo</label>
                    <input id="nombre" name="nombre" pattern="^(\D+)$" ngModel #nombre="ngModel" type="text" nbInput fullWidth placeholder="{{user.data.fullname}} ">
                    <span *ngIf="!nombre.valid " class="text-danger ">Introduzca un nombre valido</span>
                </div>
                <div class="col-sm-12 col-md-3">
                    <label for="password " class="label ">Contraseña</label>
                    <input id="password" name="password" ngModel #password="ngModel" minlength="4" type="password" nbInput fullWidth placeholder="Nueva contraseña ">
                    <span *ngIf="!password.valid " class="text-danger ">La contraseña debe tener minimo 4 caracteres</span>
                </div>
                <div class="col-sm-12 col-md-3">
                    <label for="password2" class="label ">Repite contraseña</label>
                    <input id="password2" name="password2" ngModel #password2="ngModel" minlength="4" type="password" nbInput fullWidth placeholder="Respite contraseña ">
                    <span *ngIf="!password2.valid" class="text-danger ">La contraseña debe tener minimo 4 caracteres</span><br>
                    <span *ngIf="password2.value!=password.value" class="text-danger ">Las contraseñas son distintas.</span>
                </div>
                <div class="row w-100">
                    <div class="col-md-12 d-flex justify-content-center w-100 mt-3">
                        <button type="submit" nbButton class="text-primary p-2 m-2 ">Guardar
                      <nb-icon class="text-center" icon="save-outline" data-eva-animation="shake" status="primary"></nb-icon></button>
                    </div>
                </div>

            </div>
        </form>
    </nb-card-body> -->
</nb-card>
