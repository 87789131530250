import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cau',
  templateUrl: './cau.component.html',
  styleUrls: ['./cau.component.scss']
})
export class CauComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
