
<div class="card border-0 m-2 bg-transparent">
    <div class="card-body ">
        <div class="sizeChart d-flex justify-content-center flex-wrap">
            <h5 class="card-title p-0 text-secondary mb-0">Estadísticas basadas en tus preguntas</h5>
            <ngx-charts-advanced-pie-chart  [scheme]="colorScheme" [results]="single" [gradient]="gradient"
            (select)="onSelect($event)" (activate)="onActivate($event)" (deactivate)="onDeactivate($event)">
            </ngx-charts-advanced-pie-chart>
        </div>
    </div>
</div>
