<nb-card>
  <nb-card-header>
    <div class="row">
      <div class="col-sm-12 mb-1">


        <div class="text-center d-flex justify-content-between">
          <div>
            <h4 class="section-heading">Facturas</h4>

          </div>
          <div>

            <button nbButton outline status="warning" data-toggle="modal" data-target="#modelId"
              class="btnAddClient mt-2">
              <i class="fas fa-info-circle"></i>
            </button>


            <div class="modal fade w-100" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
              aria-hidden="true">
              <div class="modal-dialog w-100" role="document">
                <div class="modal-content p-1">
                  <div class="modal-header">
                    <h5 class="modal-title text-dark font-weight-bold">Información</h5>

                  </div>
                  <div class="modal-body">
                    <h6 class="text-success text-left m-1">Inversión de los socios</h6>
                    <p class="text-dark text-left">Aquí se reflejan las inversiones de los socios</p>



                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cerrar</button>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>

    <div class=" p-2 ">

      <h6 class="text-start m-1">Todas las facturas<br><span class=""></span>
        <br>
      </h6>

    </div>

    <div class="col version-ordenador ">
      <ng2-smart-table [settings]="settings" [source]="source" (edit)="edit($event)" width="100%"></ng2-smart-table>

    </div>

    <div class="col-12 version-movil p-1">
      <div class="row" *ngFor="let item of datos; let i=index">
        <div class="col-12 p-1">
          <!-- <button nbButton outline status="primary" class="btnAddClient d-flex justify-content-start w-100 mb-1" (click)="verCliente(i)"> -->
          <button nbButton outline status="primary" class="btnAddClient d-flex justify-content-start w-100 mb-1">
            <p size="small" picture="">{{item.codigo}} | {{item.nombrecliente}} | {{item.fecha}} | {{item.total}}€</p>
          </button>

        </div>
      </div>
    </div>

  </nb-card-body>
</nb-card>
