<div class="row">
  <div class="col-sm-12 mb-4">
    <h4 class="text-center text-info">Nueva domicialiacion
      <i class="fas fa-user-plus pl-2"></i>
    </h4>
  </div>
</div>


<form (ngSubmit)="ingresar()" novalidate>
  <div class="row">
    <div class="col-md-12 justify-content-around">
      <input type="text" class="mb-3 mw-100 w-100" placeholder="DNI" [(ngModel)]="inputDNI" name="dni" nbInput
        fullWidth />
        <input type="text" class="mb-3 mw-100 w-100" placeholder="Nombre" [(ngModel)]="inputNombre" name="nombre" nbInput
        fullWidth />
        <input type="text" class="mb-3 mw-100 w-100" placeholder="Apellido" [(ngModel)]="inputApellidos" name="apellidos" nbInput
        fullWidth />
        <input type="text" class="mb-3 mw-100 w-100" placeholder="Nº Teléfono" [(ngModel)]="inputTelefono" name="telefono" nbInput
        fullWidth />
        <input type="text" class="mb-3 mw-100 w-100" placeholder="Email" [(ngModel)]="inputEmail" name="email" nbInput
        fullWidth />

        <!-- <p>- {{inputEmail}}</p> -->

    </div>

  </div>

  <div class="row">
    <div class="col-6">
      <!-- <button nbButton fullWidth status="primary">Ingresar</button> -->
      <button type="submit" nbButton fullWidth  outline status="primary" class="text-primary">
        Crear
      </button>


    </div>
    <div class="col-6">
      <!-- <button nbButton fullWidth status="primary">Ingresar</button> -->

      <button nbButton fullWidth outline status="danger" class="text-danger" routerLink="/pages/clientes">
        Cancelar
      </button>
    </div>
  </div>




</form>
