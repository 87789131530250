<section class="mt-1 pt-1">
  <div class="container-fluid">
    <div class="text-center d-flex justify-content-between">
      <div>
        <h1 class="section-heading">Clientes

        </h1>


      </div>
      <div>
        <button nbButton outline status="primary" class="btnAddClient m-1" routerLink="/pages/addClient"><i
            class="fas fa-user-plus"></i> </button>

      </div>

    </div>


    <div class="row">
      <div class="col version-ordenador ">
        <ng2-smart-table [settings]="settings" [source]="source" (edit)="edit($event)" (delete)="delete($event)" width="100%"></ng2-smart-table>

      </div>

      <div class="col-12 version-movil p-1" >

        <div class="row" *ngFor="let item of datos; let i=index">
          <div class="col-12 p-1">
            <button nbButton outline status="primary" class="btnAddClient d-flex justify-content-start w-100 mb-1" (click)="verCliente(i)">
              <nb-user [name]="item.nombre + ' ' + item.apellidos"  size="small" picture="" ></nb-user>
            </button>


          </div>
        </div>


        <div class="col" >

        </div>


      </div>

    </div>




  </div>


</section>
