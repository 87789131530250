import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';

import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/conexionApi.service';

import { Peticion } from 'src/app/services/peticion';
import { NbAuthService, NbAuthJWTToken } from 'src/framework/auth/public_api';


@Component({
  selector: 'app-retirar',
  templateUrl: './retirar.component.html',
  styleUrls: ['./retirar.component.scss']
})
export class RetirarComponent implements OnInit {

  options: string[];

  datos =[] ;


  datosTotales = [];

  porcentaje = 0;

  user :any;
  nombreCompleto:any;
  myArray: any;
  nombre: any;



  inputDescripcion: string = '';

  inputPrecio: string = '';



  constructor(private authService: NbAuthService,private router: Router, private http: ApiService, private cdRef: ChangeDetectorRef, private cdr: ChangeDetectorRef ) {

    this.authService.onTokenChange()
    .subscribe((token: NbAuthJWTToken) => {

      this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
        this.nombreCompleto = this.user.data.fullname;
        this.myArray = this.nombreCompleto.split(" ");
        this.nombre = this.myArray[0];

        // alert(this.nombreCompleto);

      // if (token.isValid()) {
      //   this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
      // }
    });


  }
  ngOnInit() {

    var t = localStorage.getItem('auth_app_token');
    this.obtenerDatos({ token: t });



    this.totalIngresoso({ token: t });



    setTimeout(() => {
      this.cdr.markForCheck ();
    }, 500);
  }



  obtenerDatos(body: Peticion) {
    this.http.postRespuesta(body, "https://app.accionext.com/api/retirar/retiros.php").subscribe(
      info => {
        this.datos = info;
        //  console.log(this.datos);
        // this.source = new LocalDataSource(this.datos);

      },
      err => {
        console.log(err);
      }
    );
  }



  totalIngresoso(body: Peticion) {
    // alert("this.datosTotales");
    this.http.postRespuesta(body, "https://app.accionext.com/api/retirar/totalRetiros.php").subscribe(
      info => {
        this.datosTotales = info;
        //  console.log(this.datosTotales);
        //  alert(this.datosTotales[0].sumaTotalMes);
        // this.source = new LocalDataSource(this.datos);
        // this.totalIngresosMes =
        var objetivo = 1000;

        this.porcentaje = (this.datosTotales[0].sumaTotalMes*100)/objetivo;

      },
      err => {
        console.log(err);
      }
    );
  }

  ingresarPost(body: Peticion) {



    this.http.postRespuesta(body, "https://app.accionext.com/api/retirar/retirar.php").subscribe(
      data => {

      },
      err => { }
    );

  }

  ingresar() {
    // alert(this.inputNombre + " -- "+ this.inputDescripcion + " -- "+ this.inputTipo + " -- "+ this.inputPrecio);

    var t = localStorage.getItem('auth_app_token');

    if (this.inputDescripcion == '') {

      alert("El campo concepto está vació");
    } else {

      if (this.inputPrecio == '') {

        alert("El campo importe está vació");

      }else{
        this.ingresarPost({descripcion:this.inputDescripcion,importe:this.inputPrecio, token: t });

        this.router.navigate(['/pages/correcto']);
      }

    }






 }

}
