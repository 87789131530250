import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { ApiService } from 'src/app/services/conexionApi.service';
import { Peticion } from 'src/app/services/peticion';
import { NbTokenService } from 'src/framework/auth/public_api';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-polizas',
  templateUrl: './polizas.component.html',
  styleUrls: ['./polizas.component.scss']
})
export class PolizasComponent implements OnInit {

  source: LocalDataSource;
  datos:any = {};
  totalPolizas:number = 0;
  primaTotalPolizas:number= 0;
  polizasCanceladasMes:number = 0;
  totalPolizasMes:number = 0;
  primaTotalPolizasMes:number = 0;



  settings = {
    actions: {
      columnTitle: 'Acciones',
      add: false,
      edit: true,
      delete: false,
      position: 'right',

    },
    add: {

      addButtonContent: '<i class="fas fa-plus" title="Añadir " ></i>',
      createButtonContent: '<i class="far fa-check-square" title="Añadir " ></i>',
      cancelButtonContent: '<i class="far fa-window-close" title="cancelar " ></i>'
    },
    edit: {
      editButtonContent: '<i class="fa fa-user-circle text-primary m-1" title="Ver cliente"></i>',
      saveButtonContent: '<i class="far fa-edit" title="actualizar " ></i>',
      cancelButtonContent: '<i class="far fa-window-close" title="cancelar " ></i>'
    },
    delete: {
      deleteButtonContent: '<i class="far fa-trash-alt" title="Eliminar" ></i>',
      confirmDelete: true
    },

    mode: 'external',

    pager: {

      display: true
    },


    columns:
    {

      tipo:{
        title: 'Tipo',
      },

      compania:{
        title: 'Compania',
      },

      num_poliza:{
        title: 'Póliza',

      },

      periodo:{
        title: 'Periodo',

      },
      estado_actual:{
        title: 'Estado',

      },

      dni:{
        title: 'DNI/NIE',
      },
      nombre:{
        title: 'Titular',

      },
      apellidos:{
        title: 'Tipo',

      },




    }
  }


  constructor(private https: ApiService,private router: Router,  private t: NbTokenService, private activatedRoute: ActivatedRoute, private cdr: ChangeDetectorRef) {


  }

  ngOnInit(): void {
    var t = localStorage.getItem('auth_app_token');
    this.obtenerDatos({ token: t });
    this.obtenerDatosEstadisticos({token:t});
    this.cdr.markForCheck();

    setTimeout(() => {
      this.obtenerDatos({ token: t });
      this.obtenerDatosEstadisticos({token:t});
      this.cdr.markForCheck();
    }, 5000);


  }

  ngAfterViewInit(): void {
      var t = localStorage.getItem('auth_app_token');
      this.obtenerDatos({ token: t });
      this.cdr.markForCheck();
  }


  obtenerDatos(body: Peticion) {
    this.https.postRespuesta(body, "https://app.accionext.com/api/seguros/polizas.php").subscribe(
      info => {
        this.datos = info;
        this.source = new LocalDataSource(this.datos);
        this.cdr.markForCheck();
      },
      err => {
        console.log(err);
      }
    );

  }

  edit(event){
    this.router.navigate(['./', event.index], {relativeTo: this.activatedRoute})
    console.log(event)
  }

  verCliente(id){
    this.router.navigate(['./', id], {relativeTo: this.activatedRoute})

  }

  obtenerDatosEstadisticos(body: Peticion) {
    this.https.postRespuesta(body, "https://app.accionext.com/api/seguros/datosEstadisticos.php").subscribe(
      info => {
        this.totalPolizas = info.total;
        this.primaTotalPolizas= info.totalPrima;
        this.polizasCanceladasMes = info.totalMesCanceladas;
        this.totalPolizasMes = info.totalMes;
        this.primaTotalPolizasMes = info.totalPrimaMes;
        this.cdr.markForCheck();
      },
      err => {
        console.log(err);
      }
    );

  }

}
