import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/conexionApi.service';
import { Peticion } from 'src/app/services/peticion';


@Component({
  selector: 'app-chart3',
  templateUrl: './chart3.component.html',
  styleUrls: ['./chart3.component.scss']
})
export class Chart3Component implements OnInit{
  
  ancho=150;
  value = 0;
  t = localStorage.getItem('auth_app_token');
  blueAutoSchool = '#44d9e6';
  orangeAutoSchool = '#ffbd4a'; 
  greenAutoSchool = '#35b729';
  redAutoSchool = '#ff5c5c';

  constructor(private https: ApiService){}

  ngOnInit(): void {
    this.postEnviarDatosEstadisticas({token: this.t});
  }

  postEnviarDatosEstadisticas(body: Peticion) {
    this.https.postRespuesta(body,"https://autoschool.es/api/estadisticas/estadisticasTotal.php").subscribe(
      info => {
        if(info<0){
          this.value = 0;
        }else{
          this.value = info;
        } 
      },
      err => {}
    );
  }

  colorScheme = {
    domain: [this.greenAutoSchool,this.greenAutoSchool, this.blueAutoSchool, this.redAutoSchool]
  };
  
  previousValue: number = 80;
  units: string = '%';

  onSelect(event) {
    console.log(event);
  }

}