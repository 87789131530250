<!-- <app-form-retirar></app-form-retirar> -->

<nb-card>
  <nb-card-body>
    <div class="row">
      <div class="col-sm-12 mb-4">


        <div class="text-center d-flex justify-content-between">
          <div>
            <h1 class="section-heading">Retirar</h1>

          </div>
          <div>

            <button nbButton outline status="warning" data-toggle="modal" data-target="#modelId" class="btnAddClient mt-2" >
              <i class="fas fa-info-circle"></i>
            </button>

              <!-- Modal -->
      <div class="modal fade w-100" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
        <div class="modal-dialog w-100" role="document">
          <div class="modal-content p-1">
            <div class="modal-header">
              <h5 class="modal-title text-dark font-weight-bold">Información</h5>
                <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button> -->
            </div>
            <div class="modal-body">
              <h6 class="text-success text-left">Retiros</h6>
              <p class="text-dark">Aquí fijamos todos los retiros de dinero y gastos mensuales</p>


            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

          </div>

        </div>
      </div>
    </div>

    <form (ngSubmit)="ingresar()" novalidate>
      <div class="row">



        <div class="col-md-6 d-flex justify-content-around">

          <input type="text" class="mb-3 mw-100 w-100" placeholder="Concepto" [(ngModel)]="inputDescripcion"
            name="apellidos" nbInput fullWidth />



        </div>

        <div class="col-md-6 d-flex justify-content-around">

          <input type="text" class="mb-3 mw-100 w-100" placeholder="Importe" [(ngModel)]="inputPrecio" name="email"
            nbInput fullWidth />


        </div>



      </div>

      <div class="col-sm-12 mb-3 p-0">

        <button type="submit" nbButton fullWidth outline status="primary" class="text-white  m-0 ">
          Retirar <nb-icon nbPrefix class="" icon="credit-card-outline">
          </nb-icon>
        </button>
      </div>


    </form>

  </nb-card-body>
</nb-card>





<nb-card>
  <nb-card-body>

      <div class="row">
          <div class="col-sm-12 " *ngFor="let item of datosTotales">
              <h4 class="text-center mb-4">Hemos gastado {{item.sumaTotalMes}} € este mes</h4>
              <nb-progress-bar [value]="porcentaje" status="primary" size="giant"></nb-progress-bar>
              <h1 class="text-center"></h1>
          </div>
      </div>

  </nb-card-body>
</nb-card>




<nb-card>
  <nb-card-body>

    <div class="row">
      <div class="col-sm-12 m-2">
        <h4 class="text-center text-white">Resumen de retiros de este mes</h4>
      </div>
    </div>
    <div class="row  d-flex justify-content-center">
      <table>
        <thead>
          <tr>
            <th class="text-center">Usuario</th>
            <th class="text-center">Concepto</th>
            <th class="text-center">Importe</th>
            <th class="text-center">Fecha</th>

          </tr>
        </thead>
        <tbody class="text-left">
          <tr *ngFor="let item of datos">
            <td class="text-center">{{item.usuario}}</td>
            <td class="text-center">{{item.concepto}}</td>
            <td class="text-center">{{item.importe}} €</td>
            <td class="text-center">{{item.fecha}}</td>

          </tr>
          <!-- <tr>
                          <td>Reparación móvil</td>
                          <td>{{da}}</td>
                      </tr> -->
          <!-- <tr>
                          <td>Gestión empadronamiento</td>
                          <td>10€</td>
                      </tr>
                      <tr>
                          <td>Pagina web</td>
                          <td>1200€</td>
                      </tr> -->

        </tbody>
      </table>

    </div>


  </nb-card-body>
</nb-card>
