<nb-layout>
      <nb-layout-header fixed class="header-stilo">
            <app-header class="w-100 header-stilo"></app-header>
      </nb-layout-header>

      <nb-sidebar left fixed valor state="compacted" class="menu-sidebar sidebar-stilo d-flex justify-content-center align-items-center " tag="menu-sidebar" responsive (mouseenter)="abrir()"  (mouseleave) ="cerrar()">
            <nb-menu [items]="items" class="mt-5 align-self-center "></nb-menu>
            <hr class="m-3 ">
            <!-- <button nbButton outline status="warning"  class="btnAddClient mt-2 w-100" >
              Ajustes<i class="fas fa-info-circle"></i>
            </button> -->
      </nb-sidebar>

      <nb-layout-column class="header-stilo">
        <nb-route-tabset [tabs]="tabs" class="header-stilo"  activeLinkOptions="{exact: false}" fullWidth></nb-route-tabset>

           <!-- <nb-tabset>
            <nb-tab tabTitle="Users" tabIcon="person-outline" responsive>
              <p>List of <strong>users</strong>.</p>
            </nb-tab>
            <nb-tab tabTitle="Orders" tabIcon="bell-outline" responsive>
              <p>List of <strong>orders</strong>.</p>
            </nb-tab>
            <nb-tab tabTitle="Transactions" tabIcon="email-outline" responsive>
              <p>List of <strong>transactions</strong>.</p>
            </nb-tab>
          </nb-tabset> -->


      </nb-layout-column>

      <!-- <nb-layout-footer fixed>
            <app-footer></app-footer>
      </nb-layout-footer> -->


</nb-layout>
