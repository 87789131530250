import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Router,ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/services/conexionApi.service";

import { Peticion } from "src/app/services/peticion";
import { NbAuthService, NbAuthJWTToken } from "src/framework/auth/public_api";
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { LocalDataSource } from 'ng2-smart-table';
@Component({
  selector: "app-facturas",
  templateUrl: "./facturas.component.html",
  styleUrls: ["./facturas.component.scss"],
})
export class FacturasComponent implements OnInit {

  source: LocalDataSource;
  datos:any = {};


  settings = {
    actions: {
      columnTitle: 'Acciones',
      add: false,
      edit: true,
      delete: false,
      position: 'right',

    },
    add: {

      addButtonContent: '<i class="fas fa-plus" title="Añadir " ></i>',
      createButtonContent: '<i class="far fa-check-square" title="Añadir " ></i>',
      cancelButtonContent: '<i class="far fa-window-close" title="cancelar " ></i>'
    },
    edit: {
      editButtonContent: '<i class="fa fa-user-circle text-primary m-1" title="Ver cliente"></i>',
      saveButtonContent: '<i class="far fa-edit" title="actualizar " ></i>',
      cancelButtonContent: '<i class="far fa-window-close" title="cancelar " ></i>'
    },
    delete: {
      deleteButtonContent: '<i class="far fa-trash-alt" title="Eliminar" ></i>',
      confirmDelete: true
    },

    mode: 'external',

    pager: {

      display: true
    },



    columns:
    {

      codigo:{
        title: 'Código',
        editable: true
      },
      nombrecliente:{
        title: 'Nombre del cliente',
        editable: true
      },
      fecha:{
        title: 'Fecha',
        editable: true
      },
      total:{
        title: 'Total',
        editable: true
      },
      pagada:{
        title: 'Pagada',
        editable: true
      },



    }
  }

  constructor(
    private authService: NbAuthService,
    private router: Router,

    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    var t = "hm9AyHYpRWs5tDOeKFz2";
    this.obtenerFacturas({ token: t });
    this.cdr.markForCheck();

    setTimeout(() => {
      this.obtenerFacturas({ token: t });
      this.cdr.markForCheck();
    }, 5000);
  }

  ngAfterViewInit(): void {
      var t = localStorage.getItem('auth_app_token');
      this.obtenerFacturas({ token: t });
      this.cdr.markForCheck();
  }



  obtenerFacturas(body: Peticion) {
    let header = new HttpHeaders().append('token', 'hm9AyHYpRWs5tDOeKFz2');

    this.http
      .get(
        "https://facturacion.accionext.com/api/3/facturaclientes?sort[fecha]=DESC",
        {headers: new HttpHeaders().set('token', "hm9AyHYpRWs5tDOeKFz2" )}
      )
      .subscribe(
        (info) => {
          //variables texto
          this.datos = info;
          this.source = new LocalDataSource(this.datos);
          this.cdr.markForCheck();

        },
        (err) => {
          console.log(err);
        }
      );
  }

  edit(event){
    this.router.navigate(['./', event.index], {relativeTo: this.activatedRoute})
    console.log(event)
  }
}
