<nb-card>
  <nb-card-header>
    <div class="row">
      <div class="col-sm-12 mb-1">


        <div class="text-center d-flex justify-content-between">
          <div>
            <h1 class="section-heading">NextChat</h1>

          </div>
          <div>

            <button nbButton outline status="warning" data-toggle="modal" data-target="#modelId"
              class="btnAddClient mt-2">
              <i class="fas fa-info-circle"></i>
            </button>

            <!-- Modal -->
            <div class="modal fade w-100" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
              aria-hidden="true">
              <div class="modal-dialog w-100" role="document">
                <div class="modal-content p-1">
                  <div class="modal-header">
                    <h5 class="modal-title text-dark font-weight-bold">Información</h5>
                    <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button> -->
                  </div>
                  <div class="modal-body">
                    <h6 class="text-success text-left m-1">CAU | Centro de Atención al Usuario</h6>
                    <p class="text-dark text-left">Todas las sujencias y mejoras las puedes presentar por aquí</p>
                    <!-- <ul >
                <li class="text-dark">Tu continuidad con respecto al uso de la aplicación.</li>
                <li class="text-dark">Numero de preguntas bien y mal respondidas.</li>
                <li class="text-dark">Numero de videos vistos.</li>
                <li class="text-dark">Numero de pdf leídos.</li>
              </ul> -->
                    <hr>
                    <!-- <h6 class="text-secondary">Estadísticas basadas en tus preguntas</h6>
              <p class="text-dark">Este apartado de estadísticas cuenta cuantas preguntas están bien respondidas y mal respondidas y las compara entre las dos.</p>
              <hr>
              <h6 class="text-primary">Tu dia a dia</h6>
              <p class="text-dark">Este apartado de estadísticas muestra tus acciones de los últimos 7 dias.</p>
              <ul>
                <li class="text-dark">Numero de apartados leídos y vistos</li>
                <li class="text-dark">Numero de tests por días.</li>
              </ul>
              <hr>
              <h6 class="text-danger">Tu progreso por temas</h6>
              <p class="text-dark">Este apartado de estadísticas muestra el porcentaje de preguntas bien respondidas por tema.</p>
              <hr> -->
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cerrar</button>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>


    <div class="row">
      <div class="col-12 col-md-4">
        <nb-card>
          <nb-card-body class="text-start">

            <ng-container>

              <div class="row my-flex-card">
                <!-- SECCION DE GUIA -->

                <div class="col-12 pr-0 pl-0">



                  <div class="row d-flex" *ngFor="let item of datos; let i=index">
                    <div class="col-12 " (click)="verCliente(i)">
                      <!-- <i style="font-size: 35px !important;"  class="fas fa-hand-holding-usd text-info"></i> -->
                      <nb-user [name]="item.fullname" size="large" badgeText="9+"
                        badgeStatus="success" badgePosition="bottom left" title="Engineer" picture=""
                        class="w-100 d-flex align-items-start"></nb-user>



                      <!-- <hr style="background-image: -webkit-linear-gradient(left, rgba(66,133,244,.8), rgba(66, 133, 244,.6), rgba(0,0,0,0));"> -->
                      <!-- class="hr-primary" -->
                      <hr>
                  </div>

                </div>

              </div>
      </div>

      </ng-container>


  </nb-card-body>
</nb-card>
</div>
<div class="col-12 col-md-8">
  <nb-card>
    <nb-card-body class="text-center p-0">


      <app-nextChat></app-nextChat>

      <!-- <p>holaaa</p> -->

      <!-- <app-ingresos></app-ingresos> -->


    </nb-card-body>
  </nb-card>
</div>
</div>

</nb-card-body>
</nb-card>
