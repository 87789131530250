import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';

import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/conexionApi.service';

import { Peticion } from 'src/app/services/peticion';
import { NbAuthService, NbAuthJWTToken } from 'src/framework/auth/public_api';
@Component({
  selector: 'app-gastosFijos',
  templateUrl: './gastosFijos.component.html',
  styleUrls: ['./gastosFijos.component.scss']
})
export class GastosFijosComponent implements OnInit {

  totalIngresadoMes = 0;

  datos =[] ;

  constructor(private authService: NbAuthService,private router: Router, private http: ApiService, private cdRef: ChangeDetectorRef, private cdr: ChangeDetectorRef ) {

   }

  ngOnInit(): void {

    var t = localStorage.getItem('auth_app_token');
    this.obtenerDatos({ token: t });

    this.totalIngresoso({ token: t });

    setTimeout(() => {
      this.cdr.markForCheck ();
    }, 500);
  }


  datosTotales = [];



  totalIngresoso(body: Peticion) {
    // alert("this.datosTotales");
    this.http.postRespuesta(body, "https://app.accionext.com/api/estadisticas/gastosMes.php").subscribe(
      info => {
        this.datosTotales = info;
        //  console.log(this.datosTotales);
        //  alert(this.datosTotales[0].sumaTotalMes);
        // this.source = new LocalDataSource(this.datos);
        // this.totalIngresosMes =
        var objetivo = 1000;

       for (let index = 0; index < info.length; index++) {
        this.totalIngresadoMes += parseInt(info[index].sumaTotalMes) ;
       }
        // this.porcentaje = (this.datosTotales[0].sumaTotalMes*100)/objetivo;

      },
      err => {
        console.log(err);
      }
    );
  }


  obtenerDatos(body: Peticion) {
    this.http.postRespuesta(body, "https://app.accionext.com/api/estadisticas/gastosPorUsuario.php").subscribe(
      info => {
        this.datos = info;
        //  console.log(this.datos);
        // this.source = new LocalDataSource(this.datos);

      },
      err => {
        console.log(err);
      }
    );
  }


}
