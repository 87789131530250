import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tesoreria',
  templateUrl: './tesoreria.component.html',
  styleUrls: ['./tesoreria.component.scss']
})
export class TesoreriaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
