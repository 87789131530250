import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Peticion } from 'src/app/services/peticion';
import { ApiService } from '../../../../services/conexionApi.service';

@Component({
  selector: 'app-ver-cliente',
  templateUrl: './ver-cliente.component.html',
  styleUrls: ['./ver-cliente.component.scss']
})
export class VerClienteComponent implements OnInit {

  cliente: any = {}
  indexCliente: any;
  editando: boolean = false;

  // graficos
  single: any[];

  singleMes: any[];
  multi: any[];

  // view: any[] = [700, 400];
  // view = [220,220];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = 'Días';
  showYAxisLabel = false;
  yAxisLabel = 'Ingresos';

  colorScheme = {
    domain: ['#93d3fb']
  };

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private https: ApiService ) { }

  ngOnInit(): void {
    var t = localStorage.getItem('auth_app_token');
    this.activatedRoute.params.subscribe(cliente => this.indexCliente = cliente.id)
    this.obtenerDatos({token: t});

      this.single = [
        {
          "name": "Servicio 1",
          "value": 5
        },
        {
          "name": "Servicio 2",
          "value": 19
        },
        {
          "name": "Servicio 3",
          "value": 10
        },
        {
          "name": "Servicio 4",
          "value": 5
        },
        {
          "name": "Servicio 5",
          "value": 18
        },
        {
          "name": "Servicio 7",
          "value": 11
        },
        {
          "name": "Servicio 8",
          "value": 25
        },
      ];

  }


  obtenerDatos(body: Peticion) {
    this.https.postRespuesta(body, "https://app.accionext.com/api/clientes/clientes.php").subscribe(
      info => {
        this.cliente = info[this.indexCliente];
        // console.log(this.datos);
      },
      err => {
        console.log(err);
      }
    );
  }

//Cambia el estado del atributo editando para mostrar o no el modal.
  showModal(datosCliente?){
    this.editando = !this.editando;
    if(datosCliente != undefined){
      this.cliente = datosCliente;
    }

  }

  onSelect(event) {
    console.log(event);
  }

  }


