<section class="page-section mt-1 pt-1" id="services">
  <div class="container-fluid">
    <div class="text-center">
      <!-- <h2 class="section-heading text-uppercase">¡Bienvenido a accioges!</h2> -->
    </div>

    <!-- Content Row -->
    <div class="row mt-1 mb-3">
      <div class="col-xl-3 col-md-6 mb-2 p-0 p-0">
        <div
          class="card border-primary py-1 btn bAzul"
          [routerLink]="['/pages/fichar']"
        >
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 d-flex justify-content-start">
                <h6 class="text-primary">Fichar</h6>
              </div>
              <div class="col-auto">
                <i
                  class="fas fa-business-time fa-inverse text-primary h1 m-1"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-2 p-0">
        <div
          class="card border-primary py-1 btn bAzul"
          [routerLink]="['/pages/ingresar']"
        >
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 d-flex justify-content-start">
                <h6 class="text-primary">Ingresar</h6>
              </div>
              <div class="col-auto">
                <!-- <i class="fas fa-hand-holding-usd fa-inverse text-success h1 m-1"></i> -->

                <i
                  class="fas fa-cash-register fa-inverse text-primary h1 m-1"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-2 p-0">
        <div
          class="card border-primary py-1 btn bAzul"
          [routerLink]="['/pages/caja']"
        >
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 d-flex justify-content-start">
                <h6 class="text-primary">Caja</h6>
              </div>
              <div class="col-auto">
                <!-- <i class="fas fa-hand-holding-usd fa-inverse text-success h1 m-1"></i> -->

                <i
                  class="fas fa-cash-register fa-inverse text-primary h1 m-1"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-2 p-0" *ngIf="rol == 'admin'">
        <div
          class="card border-primary py-1 btn bAzul"
          [routerLink]="['/pages/retirar']"
        >
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 d-flex justify-content-start">
                <h6 class="text-primary">Retirar</h6>
              </div>
              <div class="col-auto">
                <i
                  class="fas fa-hand-holding-usd fa-inverse text-primary h1 m-1"
                ></i>
                <!-- <i class="fas fa-arrow-down fa-inverse text-danger h1 m-1"></i> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-2 p-0" *ngIf="rol == 'admin'">
        <div
          class="card border-primary py-1 btn bAzul"
          [routerLink]="['/pages/estadisticas']"
        >
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 d-flex justify-content-start">
                <h6 class="text-primary">Estadisticas</h6>
              </div>
              <div class="col-auto">
                <i
                  class="fas fa-chart-bar fa-inverse text-primary h1 m-1"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-2 p-0" *ngIf="rol == 'admin'">
        <div
          class="card border-primary py-1 btn bAzul"
          [routerLink]="['/pages/contabilidad']"
        >
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 d-flex justify-content-start">
                <h6 class="text-primary">Facturación</h6>
              </div>
              <div class="col-auto">
                <!-- <i class="fab fa-cc-amazon-pay fa-inverse text-info h1 m-1"></i> -->
                <i
                  class="fas fa-calculator fa-inverse text-primary h1 m-1"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-2 p-0">
        <div
          class="card border-primary py-1 btn bAzul"
          [routerLink]="['/pages/servicios']"
        >
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 d-flex justify-content-start">
                <h6 class="text-primary">Servicios</h6>
              </div>
              <div class="col-auto">
                <i
                  class="fas fa-cogs fa-inverse text-primary h1 m-1"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-2 p-0">
        <div
          class="card border-primary py-1 btn bAzul"
          [routerLink]="['/pages/viajes']"
        >
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 d-flex justify-content-start">
                <h6 class="text-primary">Viajes</h6>
              </div>
              <div class="col-auto">
                <i
                  class="fas fa-solid fa-plane-departure text-primary h1 m-1"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-2 p-0">
        <div
          class="card border-primary py-1 btn bAzul"
          [routerLink]="['/pages/seguros']"
        >
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 d-flex justify-content-start">
                <h6 class="text-primary">Seguros</h6>
              </div>
              <div class="col-auto">
                <i
                  class="fas fa-umbrella fa-inverse text-primary h1 m-1"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-2 p-0">
        <div
          class="card border-primary py-1 btn bAzul"
          [routerLink]="['/pages/cau']"
        >
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 d-flex justify-content-start">
                <h6 class="text-primary">Soporte</h6>
              </div>
              <div class="col-auto">
                <i
                  class="fas fa-headset fa-inverse text-primary h1 m-1"
                ></i>
                <!-- <i class="fas fa-headset"></i> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-2 p-0" *ngIf="rol == 'admin'">
        <div
          class="card border-primary py-1 btn bAzul"
          [routerLink]="['/pages/inversion']"
        >
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 d-flex justify-content-start">
                <h6 class="text-primary">Inversión</h6>
              </div>
              <div class="col-auto">
                <i
                  class="fas fa-chart-line fa-inverse text-primary h1 m-1"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-2 p-0">
        <div
          class="card border-danger py-1 btn bAzul"
          [routerLink]="['/pages/clientes']"
        >
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 d-flex justify-content-start">
                <h6 class="text-primary">Clientes</h6>
              </div>
              <div class="col-auto">
                <i
                  class="fas fa-users fa-inverse text-primary h1 m-1"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-2 p-0">
        <div
          class="card border-danger py-1 btn bAzul"
          [routerLink]="['/pages/clientes']"
        >
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 d-flex justify-content-start">
                <h6 class="text-primary">Formación</h6>
              </div>
              <div class="col-auto">
                <i
                  class="fas fa-graduation-cap fa-inverse text-primary h1 m-1"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-2 p-0">
        <div
          class="card border-secondary py-1 btn bAzul"
          [routerLink]="['/pages/tareas']"
        >
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 d-flex justify-content-start">
                <h6 class="text-primary">G. Tareas</h6>
              </div>
              <div class="col-auto">
                <i
                  class="fa fa-tasks fa-inverse text-primary h1 m-1"
                ></i>
                <i class="iconoir-small-shop-alt lead"> </i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-2 p-0">
        <div
          class="card border-secondary py-1 btn bAzul"
          [routerLink]="['/pages/reuniones']"
        >
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 d-flex justify-content-start">
                <h6 class="text-primary">Reuniones</h6>
              </div>
              <div class="col-auto">
                <i
                  class="fa fa-tasks fa-inverse text-primary h1 m-1"
                ></i>
                <i class="iconoir-small-shop-alt lead"> </i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-2 p-0">
        <div
          class="card border-primary py-1 btn bAzul"
          [routerLink]="['/pages/chats']"
        >
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 d-flex justify-content-start">
                <h6 class="text-primary">NextChat</h6>
              </div>
              <div class="col-auto">
                <!-- <i class="fas fa-hand-holding-usd fa-inverse text-success h1 m-1"></i> -->
                <i
                  class="fa fa-comments fa-inverse text-primary h1 m-1"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6 mb-2 p-0">
        <div
          class="card border-primary py-1 btn bAzul"
          [routerLink]="['/pages/economia']"
        >
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 d-flex justify-content-start">
                <h6 class="text-primary">G. Económica</h6>
              </div>
              <div class="col-auto">
                <!-- <i class="fas fa-hand-holding-usd fa-inverse text-success h1 m-1"></i> -->
                <i
                  class="fa fa-comments fa-inverse text-primary h1 m-1"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
