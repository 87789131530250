<div class="container-fluid text-center mt-5">
    <div class="row justify-content-center mt-5">
      <div class="col-md-12 color p-0">
        <!-- <nb-icon style="font-size : 10rem" icon="settings-2-outline" [options]="{ animation: { type: 'shake' } }" status="warning"></nb-icon>
        <h1  class="mt-4 h1">En proceso......</h1> -->
        <nb-calendar class="w-100 calendario" [(date)]="date" [min]="min" [max]="max" ></nb-calendar>
        <h6 class="text-center mt-2 mb-2 text-black-50">Fecha: <span class="text-primary">{{ date | date }}</span> </h6>
      </div>
    </div>
  </div>
