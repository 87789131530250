<!-- <a class='history-back ' [routerLink]="['/pages/inicio']" >
  <i class="fas fa-arrow-left text-primary " style="font-size: 25px!important;"></i>
</a> -->

<nb-card>
  <nb-card-header>
    <div class="row">
      <div class="col-sm-12 mb-1">


        <div class="text-center d-flex justify-content-between">
          <div>
            <h4 class="section-heading">Notificaciones</h4>

          </div>
          <div>

            <button nbButton outline status="warning" data-toggle="modal" data-target="#modelId" class="btnAddClient mt-2" >
              <i class="fas fa-info-circle"></i>
            </button>


      <div class="modal fade w-100" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
        <div class="modal-dialog w-100" role="document">
          <div class="modal-content p-1">
            <div class="modal-header">
              <h5 class="modal-title text-dark font-weight-bold">Información</h5>

            </div>
            <div class="modal-body">
              <h6 class="text-success text-left m-1">Notificaciones</h6>
              <p class="text-dark text-left">Aquí se reflejan las notificaiones</p>



            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

          </div>

        </div>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>

    <div class="row text-center p-2 d-flex justify-content-center">

      <h4 class="text-center m-1">No hay notificaciones actualmente <br></h4>

    </div>

  </nb-card-body>
</nb-card>

<section class="page-section mt-1 pt-1 mb-3" id="services">
    <div class="container-fluid">

  <div class="row">


    <ng-container *ngFor="let item of datos; let i=index" >
      <div class="col-xl-4 col-md-6 mb-3 pl-0 pr-0" (click)="verMensaje(i)">
        <div class="card py-2 btn">
          <div class="card-body">

              <div class="row no-gutters align-items-center">
                <div class="col-auto mr-3">
                  <ng-container *ngIf="item.check == 'N'; else elseTemplate">
                    <i class="fas fa-check-circle text-danger" style="font-size: 25px!important;"></i>
                  </ng-container>
                  <ng-template #elseTemplate>
                    <i class="fas fa-check-circle text-primary" style="font-size: 25px!important;"></i>
                  </ng-template>


                </div>
                <div class="col d-flex justify-content-center align-items-start flex-column">

                  <p class="text-dark text-left">{{item.texto}}</p>
                  <p class="text-light text-left">{{item.subtitulo}}</p>
                </div>
              </div>


          </div>
        </div>
      </div>
    </ng-container>


   </div>
</div>
</section>
