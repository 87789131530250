
export class Peticion {
    public tipoTest?:String;
    public paxIncluidos?:Number;
    public token? : any;
    public comida? : any;
    public confirmacion? : any;
    public nombre? : any;
    public pass? : any;
    public tiempo ? :any;
    public accion? : any;
    public fecha?:any;
    public fechainicio?:any;
    public fechafin?:any;
    public excursion?: any;
    public tipo?: any;
    public tema?: any;
    public numeroTest?: any;
    public datosFichado?: any;
    public datosFormulario?: any;
    public servicio?: any;
    public importe?: any;
    public cliente?: any;
    public dni?: any;
    public apellidos?: any;
    public telefono?: any;
    public email?: any;
    public descripcion?: any;
    public precio?: any;
    public id_cliente?: any;

}
