<section class="mt-1 pt-1">
  <div class="container-fluid pl-1 pr-1">
    <div class="text-center d-flex justify-content-between">
      <div>
        <h3 class="section-heading">Ingresos

        </h3>


      </div>
      <div>

        <i class="fas fa-chart-bar text-primary display-3"></i>
      </div>

    </div>

    <nb-card>
      <nb-card-body class="text-center">

        <div class="row text-center p-2 d-flex justify-content-center flex-column">

          <h4 class="text-center m-1">La Empresa ha ingresado este mes<br><span
              class="display-4">{{totalIngresadoMes}}€</span> <br></h4>

          <h5>La media diaria de ingresos de este mes es: {{mediaIngresosDiariaDelMes}}€</h5>


        </div>
        <hr>
        <div class=" text-center p-2">

          <h5 class="text-center m-1">Objetivo de este mes<br>
            <nb-progress-bar [value]="porcentaje" [displayValue]="true" [status]="status" size="giant">
            </nb-progress-bar>
            <span class="">8.000€</span> <br>
          </h5>

          <!-- <h5>La media diaria de ingresos de este mes es: {{mediaIngresosDiariaDelMes}}€</h5> -->

        </div>
        <hr>

        <nb-card accent="info" class="">

          <nb-card-header>
            <h5 class="text-center">Evolución del mes</h5>
          </nb-card-header>


          <nb-card-body class="p-0 text-white">

            <ngx-charts-bar-vertical class="text-white" [scheme]="colorScheme" [results]="singleMes" [gradient]="gradient"
              [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
              [customColors]="customColors" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
              [yAxisLabel]="yAxisLabel" [scheme]="colorScheme" [dataLabelFormatting]="showDataLabel"
              (select)="onSelect($event)">
            </ngx-charts-bar-vertical>



          </nb-card-body>
        </nb-card>




      </nb-card-body>
    </nb-card>


    <div class="row">
      <div class="col-md-12 text-left">

        <nb-card accent="info">


          <nb-card-header>
            <h5 class="text-center">Datos</h5>
          </nb-card-header>


          <nb-card-body class="">



            <div class="d-flex justify-content-between">
              <p class="">Hoy se ha ingresado </p>
              <h6 class="">{{datosIngresos.hoy ? datosIngresos.hoy : '0'}} €</h6>
            </div>

            <div class="d-flex justify-content-between">
              <p class="">Ayer se ha ingresado </p>
              <h6 class="">{{datosIngresos.ayer ? datosIngresos.ayer : '0'}} €</h6>
            </div>


            <div class="d-flex justify-content-between">
              <p class="">Esta semana se ha ingresado</p>
              <h6 class="">{{datosIngresos.semana ? datosIngresos.semana : '0'}}
                €</h6>

            </div>



          </nb-card-body>

          <nb-card-footer>

            <div class="d-flex justify-content-between">
              <p class="">La semana pasada se ha ingresado</p>
              <h6 class="">{{datosIngresos.semanaPasada ?
                datosIngresos.semanaPasada : '0'}} €</h6>
              <!-- La fecha se puede poner aqui con unos cuantos espacios  -->
            </div>

          </nb-card-footer>


        </nb-card>

      </div>
      <div class="col-md-12">
        <nb-card accent="info" class="">

          <nb-card-header>
            <h5 class="text-center">Ingresos por usuario</h5>
          </nb-card-header>


          <nb-card-body class="p-0">

            <div class="col-sm-12 mb-2" *ngFor="let i of datosTotales">
              <nb-accordion>
                <nb-accordion-item>
                  <nb-accordion-item-header class="d-flex justify-content-between pr-5">
                    <div>
                      {{ i.usuario}}
                    </div>
                    <div>
                      {{i.sumaTotalMes}}€
                    </div>


                  </nb-accordion-item-header>
                  <nb-accordion-item-body>
                    <div class="row text-center">
                      <div class="col-md col-sm-12">
                        <ng-container *ngFor="let item of datos">

                          <nb-card class="fondo m-1 mr-0 ml-0" *ngIf="i.usuario==item.usuario">
                            <nb-card-body class="text-center">

                              <div class="row no-gutters flex-column">
                                <div class="d-flex justify-content-between">
                                  <!-- <div class="d-flex "> -->
                                  <p class="mb-0"> {{item.nombre}}</p>
                                  <!-- </div> -->
                                  <!-- <div> -->
                                  {{item.cantidad}}€
                                  <!-- </div> -->
                                </div>
                                <div class="d-flex justify-content-betweenalign-items-center">
                                  <p class="mb-0" *ngIf="item.cliente!=sinespecificar; else elseTemplate;"></p>
                                  <ng-template #elseTemplate>
                                    <p class="mb-0">{{item.cliente}}</p>
                                  </ng-template>
                                  <p class="mb-0">{{item.fecha}}</p>
                                </div>
                              </div>

                            </nb-card-body>
                          </nb-card>

                        </ng-container>

                      </div>
                    </div>
                  </nb-accordion-item-body>
                </nb-accordion-item>
              </nb-accordion>
            </div>

          </nb-card-body>

          <nb-card-footer>

            <!-- <div class="d-flex justify-content-between">
              <p class=" ">El total ingresado esta semana </p>
              <h6 class=" ">800 €</h6>

            </div> -->
          </nb-card-footer>

        </nb-card>

      </div>

    </div>

    <nb-card accent="info">


      <nb-card accent="info">


        <nb-card-header>
          <h5 class="text-center">Evolución por meses del año actual</h5>
        </nb-card-header>


        <nb-card-body class="p-0 text-white">


          <ngx-charts-bar-vertical class="text-white" [scheme]="colorScheme" [results]="todosMeses"
            [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel" [customColors]="customColors" [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabelMeses" [yAxisLabel]="yAxisLabel" [scheme]="colorScheme"
            [dataLabelFormatting]="showDataLabel" (select)="onSelect($event)">

          </ngx-charts-bar-vertical>



        </nb-card-body>

      </nb-card>
      <!-- <nb-card-footer>

            <div class="d-flex justify-content-between">
              <p class=" ">El total ingresado en este mes es</p>
              <h6 class=" ">{{sumaMes}} €</h6>

            </div>

          </nb-card-footer> -->
    </nb-card>





    <!-- <div class="row row-gastos-usuario">
      <div class="col">



        <h5>Ingresos por usuarios</h5>



      </div>

    </div> -->
  </div>


</section>
