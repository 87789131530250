<div class="row">
  <div class="col-sm-12 mb-4">
    <h4 class="text-center text-info">Servicio nuevo
      <i class="fas fa-cogs"></i>
    </h4>
  </div>
</div>

<form (ngSubmit)="ingresar()" novalidate>
  <div class="row">
    <div class="col-md-12 justify-content-around">

        <input type="text" required class="mb-3 mw-100 w-100" placeholder="Nombre" [(ngModel)]="inputNombre" name="nombre" nbInput
        fullWidth />
        <input type="text" class="mb-3 mw-100 w-100" placeholder="Descripción" [(ngModel)]="inputDescripcion" name="apellidos" nbInput
        fullWidth />
        <!-- <input type="text" class="mb-3 mw-100 w-100" placeholder="Tipo" [(ngModel)]="inputTipo" name="telefono" nbInput
        fullWidth /> -->


        <input type="text" class="mb-3 mw-100 w-100" placeholder="Precio" [(ngModel)]="inputPrecio" name="email" nbInput
        fullWidth />

        <label class="p-2 mb-0">Departamento</label>
        <nb-radio-group class="d-flex flex-row p-2  mb-1" [(ngModel)]="inputTipo " name="telefono">

          <nb-radio
            *ngFor="let option of options"
            [value]="option.value">
            {{ option.label }}
          </nb-radio>
        </nb-radio-group>

        <!-- <p>- {{inputEmail}}</p> -->

    </div>

  </div>

  <div class="row">
    <div class="col-6">
      <!-- <button nbButton fullWidth status="primary">Ingresar</button> -->
      <button type="submit" nbButton fullWidth  outline status="primary" class="text-primary" >
        Crear
      </button>


    </div>
    <div class="col-6">
      <!-- <button nbButton fullWidth status="primary">Ingresar</button> -->

      <button nbButton fullWidth outline status="danger" class="text-danger" routerLink="/pages/servicios">
        Cancelar
      </button>
    </div>
  </div>




</form>
