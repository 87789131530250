<nb-card accent="info">
    <nb-card-header>
        <div class="row">
            <div class="col">
              <nb-user [name]="cliente.nombre + ' ' + cliente.apellidos"  size="large" picture=""></nb-user>
            </div>
            <div class="col d-flex justify-content-end align-items-center">
                <!-- Al hacer click, muestra el modal.-->
                <!-- <span (click)="showModal()"><i class="fas fa-pen" title="Editar"></i></span> -->
                <button nbButton outline status="primary" class="btnAddClient m-1" (click)="showModal()">
                  <i class="fas fa-pen" title="Editar"></i>
                </button>
                <button nbButton outline status="danger" class="btnAddClient m-1">
                  <i class="fas fa-user-minus"></i>
                </button>
            </div>
        </div>

    </nb-card-header>
    <nb-card-body>
        <!-- <div class="row">
            <div class="col-sm-12  col d-flex justify-content-start align-items-center">
                <div class="row">
                    <div class="col">
                        <nb-user [name]="cliente.nombre + ' ' + cliente.apellidos"  size="large" picture=""></nb-user>
                    </div>
                </div>
            </div>
        </div>
        <hr> -->
        <div class="row">
            <div class="col-sm-12">
                <h5>Datos</h5>
            </div>
            <div class="col-sm-12 col-md-6">
                <h6>DNI</h6>
                <p>{{cliente.dni | isFound}}</p>
            </div>
            <div class="col-sm-12 col-md-6">
                <h6>Email</h6>
                <p>{{cliente.email | isFound}}</p>
            </div>
            <div class="col-sm-12 col-md-6">
                <h6>Teléfono</h6>
                <p>{{cliente.telefono | isFound}}</p>
            </div>
        </div>

        <div class="row">
            <div class="col">

            </div>
        </div>
    </nb-card-body>

</nb-card>


<!-- Se muestra el modal de edición de cliente cuando se hace click en el boton de editar-->
<app-modal-editar *ngIf="editando" (close)="showModal($event)" [datosCliente]="cliente"> </app-modal-editar>


<!-- servicios que ha consumido el cliente -->

<div class="row" >
  <div class="col-12 ">

    <nb-card accent="primary">


      <nb-card-header>
        <h5 class="text-center ">Servicios contratados</h5>
      </nb-card-header>


      <nb-card-body class=" text-white">

        <div class="row p-1">
          <div class="col-md-4 p-1">
            <nb-card >
              <nb-card-header>
                <h6 class="text-center ">Renovación</h6>
              </nb-card-header>

              <nb-card-body class=" text-white">

                <p class="text-left m-0 ">Fecha: 15/02/2022</p>
                <p class="text-left m-0 ">Importe pagado: 50€</p>
                <p class="text-left m-0 ">Atendido por: Hamza Beddouka</p>

              </nb-card-body>

            </nb-card>
          </div>

          <div class="col-md-4 p-1">
            <nb-card >
              <nb-card-header>
                <h6 class="text-center ">Fotmatear ordenador</h6>
              </nb-card-header>

              <nb-card-body class=" text-white">

                <p class="text-left m-0 ">Fecha: 15/02/2022</p>
                <p class="text-left m-0 ">Importe pagado: 50€</p>
                <p class="text-left m-0 ">Atendido por: Hamza Beddouka</p>

              </nb-card-body>

            </nb-card>
          </div>

          <div class="col-md-4 p-1">
            <nb-card >
              <nb-card-header>
                <h6 class="text-center ">Pagina Web</h6>
              </nb-card-header>

              <nb-card-body class=" text-white">

                <p class="text-left m-0 ">Fecha: 15/02/2022</p>
                <p class="text-left m-0 ">Importe pagado: 50€</p>
                <p class="text-left m-0 ">Atendido por: Hamza Beddouka</p>

              </nb-card-body>

            </nb-card>
          </div>






        </div>



      </nb-card-body >

      <!-- <nb-card-footer class="p-0">

        <p class="text-left m-0 ">footer</p>

      </nb-card-footer> -->








    </nb-card>



  </div>


</div>

<nb-card accent="info">
  <nb-card-header>
    <h3 class="p-2 m-1">Estadisticas por sevicios</h3>

  </nb-card-header>
  <nb-card-body class="p-0">

    <ngx-charts-bar-vertical
    class=" text-white"

    [scheme]="colorScheme"
    [results]="single"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [legend]="showLegend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    (select)="onSelect($event)">
  </ngx-charts-bar-vertical>
  </nb-card-body>

</nb-card>
