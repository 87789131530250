<div class="col-md-12">
  <nb-card accent="info" class="">

    <nb-card-header>
      <h5 class="text-center">Jornada de horas por usuario</h5>
    </nb-card-header>


    <nb-card-body class="p-0">

      <div class="col-sm-12 mb-2" *ngFor="let i of datos">
        <nb-accordion>
          <nb-accordion-item>
            <nb-accordion-item-header class="d-flex justify-content-between pr-5">
              <div>
               {{i.persona}}
              </div>
              <div>
               totalhoras
              </div> 


            </nb-accordion-item-header>
            <nb-accordion-item-body>
              <div class="row text-center">
                <div class="col-md col-sm-12">
                  <ng-container *ngFor="let item of datos">

                    <!-- <nb-card class="fondo m-1 mr-0 ml-0" *ngIf="i.usuario==item.usuario"> -->
                      <nb-card-body class="text-center ">

                        <div class="row no-gutters d-flex justify-content-betweenn">
                            <div class="d-flex ">
                              <p class="mb-0"> fecha</p>
                            </div>
                            <div>
                              horas del dia
                            </div>
                        </div>

                      </nb-card-body>
                    <!-- </nb-card> -->

                  </ng-container>

                </div>
              </div>
            </nb-accordion-item-body>
          </nb-accordion-item>
        </nb-accordion>
      </div>

    </nb-card-body>

    <nb-card-footer>

    </nb-card-footer>

  </nb-card>

</div>
