<section class="page-section mt-1 " id="services">
  <div class="container-fluid p-0">
    <div class="text-center">
      <!-- <h2 class="section-heading text-uppercase">¡Bienvenido a AutoSchool!</h2> -->
    </div>

    <!-- Content Row -->
    <div class="row mt-4 pl-2">

      <div class="col-xl-3 col-md-6 mb-4 ">
        <div class="card border-primary py-2 btn bAzulClaro" [routerLink]="['/pages/domicializaciones']">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 d-flex justify-content-start">
                <h6 class="text-info ">Domicialiaciones</h6>
              </div>
              <div class="col-auto">
                 <i class="fab fa-cc-amazon-pay fa-inverse text-info display-4 m-1"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-success py-2 btn bVerde" [routerLink]="['/pages/facturas']">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 d-flex justify-content-start">
                <h6 class="text-success">Facturas</h6>
              </div>
              <div class="col-auto">

                <i class="fas fa-file-invoice-dollar fa-inverse text-success display-4 m-1"></i>
              </div>
            </div>
          </div>
        </div>
      </div>



      <!-- <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-warning py-2 btn bAmarillo" [routerLink]="['/pages/gastos']">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 d-flex justify-content-start">
                <h6 class="text-warning ">Gastos</h6>
              </div>
              <div class="col-auto">
                <i class="fas fa-cogs fa-inverse text-warning display-4 m-1"></i>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-danger py-2 btn bRojo" [routerLink]="['/pages/clientes']">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 d-flex justify-content-start">
                <h6 class="text-danger ">Clientes</h6>
              </div>
              <div class="col-auto">

                <i class="fas fa-users fa-inverse text-danger display-4 m-1"></i>
              </div>
            </div>
          </div>
        </div>
      </div> -->







    </div>
  </div>
</section>
