<div *ngIf="shouldShowPrefix()" [ngClass]="prefixClasses$ | async">
  <ng-content select="[nbPrefix]"></ng-content>
</div>

<div class="nb-form-control-container"
     [class.nb-form-field-control-with-prefix]="shouldShowPrefix()"
     [class.nb-form-field-control-with-suffix]="shouldShowSuffix()">
  <ng-content></ng-content>
</div>

<div *ngIf="shouldShowSuffix()" [ngClass]="suffixClasses$ | async">
  <ng-content select="[nbSuffix]"></ng-content>
</div>
