<div class="backgroundModal">
  <div class="modalScan">
    <div class="modalWrapper container">
      <h5>Editar cliente</h5>
      <hr />
        <form action=""  [formGroup]="formularioEditar">
          <div class="row d-flex flex-row justify-content-center m-2">
            <div class="col-md-12 d-flex flex-column m-2">
              <label  for="">DNI:</label>
              <input nbInput fullWidth type="text" formControlName="dni"/>

              <label for="">Nombre:</label>
              <input nbInput fullWidth type="text" formControlName="nombre"/>

              <label for="">Apellidos:</label>
              <input nbInput fullWidth type="text" col-sm-12 formControlName="apellidos" />

              <label for="">Email:</label>
              <input nbInput fullWidth type="text" formControlName="email"/>

              <label for="">Telefono:</label>
              <input nbInput fullWidth type="text" formControlName="telefono" />
            </div>


          </div>
        </form>
      <div class="row mt-4">
        <div class="col d-flex flex-row align-content-center justify-content-center">
          <button nbButton outline status="primary" class="btn m-2" [nbPopover]="confirmationDialog" nbPopoverPlacement="bottom"> Guardar cambios </button>
          <ng-template #confirmationDialog>
              <p class="m-2">Estos cambios son irreversibles</p>
              <button nbButton outline status="primary" class="btn ml-4 mr-4 mb-2 w-75" (click)="saveAndClose()">Confirmar</button>
          </ng-template>

          <button nbButton outline status="danger" class="btn m-2" (click)="closeModal()">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</div>
