import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/conexionApi.service';

import { Peticion } from 'src/app/services/peticion';
import { NbAuthService, NbAuthJWTToken } from 'src/framework/auth/public_api';
import { EChartsOption } from 'echarts';


@Component({
  selector: 'app-ingresos',
  templateUrl: './ingresos.component.html',
  styleUrls: ['./ingresos.component.scss']
})
export class IngresosComponent implements OnInit {

  totalIngresadoMes = 0;
  mediaIngresosDiariaDelMes = "";
  datos =[] ;

  customColors: any;
  sinespecificar = "Sin especificar";
  // -----------------

  single: any[];
  multi: any[];

  singleMes: any[];

  todosMeses: any[];

  // view: any[] = [700, 400];
  // view = [220,220];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Días';
  showYAxisLabel = true;
  yAxisLabel = 'Ingresos';
  showDataLabel= true;
  legend = true;
  xAxisLabelMeses = 'Meses';


  porcentaje = 0;

  colorScheme = {
    domain: ['#93d3fb']
  };

  datosIngresos = {
    hoy: 0,
    ayer: 0,
    semana: 0,
    semanaPasada: 0
  }


  // ___________________

  constructor(private authService: NbAuthService,private router: Router, private http: ApiService,private cdr: ChangeDetectorRef ) {



   }

   get status() {
    if (this.porcentaje <= 25) {
      return 'danger';
    } else if (this.porcentaje <= 50) {
      return 'warning';
    } else if (this.porcentaje <= 75) {
      return 'info';
    } else {
      return 'success';
    }
  }


  ngOnInit(): void {

    var t = localStorage.getItem('auth_app_token');
    this.obtenerDatos({ token: t });

    this.totalIngresoso({ token: t });

    this.ingresosGenerales({token: t});

    this.obtenerIngresosMes({token:t});

    this.obtenerIngresosMesesAnioActual({token:t});

    this.obtenerIngresosVentas({token:t});

    setTimeout(() => {
      this.cdr.markForCheck ();
    }, 500);





    this.single = [
      {
        "name": "Lunes",
        "value": 185
      },
      {
        "name": "Martes",
        "value": 200
      },
      {
        "name": "Miercoles",
        "value": 150
      },
      {
        "name": "Jueves",
        "value": 250
      },
      {
        "name": "Viernes",
        "value": 130
      },
      {
        "name": "Sábado",
        "value": 300
      },
      {
        "name": "Domingo",
        "value": 20
      },
    ];
  }





  datosTotales = [];



  totalIngresoso(body: Peticion) {
    // alert("this.datosTotales");
    this.http.postRespuesta(body, "https://app.accionext.com/api/estadisticas/ingresosMes.php").subscribe(
      info => {
        this.datosTotales = info;
        //  console.log(this.datosTotales);
        //  alert(this.datosTotales[0].sumaTotalMes);
        // this.source = new LocalDataSource(this.datos);
        // this.totalIngresosMes =


       for (let index = 0; index < info.length; index++) {
        this.totalIngresadoMes += parseInt(info[index].sumaTotalMes) ;
       }

       var objetivo = 8000;

       this.porcentaje = (this.totalIngresadoMes*100)/objetivo;


        // this.porcentaje = (this.datosTotales[0].sumaTotalMes*100)/objetivo;

      },
      err => {
        console.log(err);
      }
    );
  }

  /**
   * Recoge los datos de los ingresos generales y los almacena en un objeto.
   * @param body
   */

  ingresosGenerales(body: Peticion){
    this.http.postRespuesta(body, "https://app.accionext.com/api/estadisticas/ingresosDatos.php").subscribe(
      info => {
        this.datosIngresos.hoy = info.totalHoy;
        this.datosIngresos.ayer = info.totalAyer;
        this.datosIngresos.semana = info.totalSemana;
        this.datosIngresos.semanaPasada = info.totalSemanaPasada;
      }, err => {
        console.log(err);
      }
    )
  }


  obtenerDatos(body: Peticion) {
    this.http.postRespuesta(body, "https://app.accionext.com/api/estadisticas/ingresosPorUsuario.php").subscribe(
      info => {
        this.datos = info;
        //  console.log(this.datos);
        // this.source = new LocalDataSource(this.datos);

      },
      err => {
        console.log(err);
      }
    );
  }

  obtenerIngresosMes(body: Peticion) {
    this.http.postRespuesta(body, "https://app.accionext.com/api/ingresos/ingresosMes.php").subscribe(
      info => {
        this.singleMes = info;
        //  console.log(this.datos);
        // this.source = new LocalDataSource(this.datos);

      },
      err => {
        console.log(err);
      }
    );
  }

  obtenerIngresosMesesAnioActual(body: Peticion) {
    this.http.postRespuesta(body, "https://app.accionext.com/api/ingresos/ingresosMeses.php").subscribe(
      info => {
        this.todosMeses = info;
        console.log(this.todosMeses);
      },
      err => {
        console.log(err);
      }
    );
  }


  obtenerIngresosVentas(body: Peticion) {
    this.http.postRespuesta(body, "https://app.accionext.com/api/servicios/estadisticasServicios.php").subscribe(
      info => {
        //variables texto
        this.mediaIngresosDiariaDelMes = info['mediaIngresosMes'];

        this.cdr.markForCheck();
        console.log(info);
      },
      err => {
        console.log(err);
      }
    );
  }


  diasEnUnMes(mes, año) {
    return new Date(año, mes, 0).getDate();
  }




  onSelect(event: any) {
    // this.customColors = [
    //   {
    //     name: event.name,
    //     value: '#092E7C'
    //   }
    // ];
  }






}
