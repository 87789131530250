<section class="mt-1 pt-1">
  <div class="container-fluid pl-0 pr-0">
    <div class="text-center d-flex justify-content-between">
      <div>
        <h3 class="section-heading">Gastos

        </h3>


      </div>
      <div>

        <i class="fas fa-chart-bar text-danger display-3"></i>
        
      </div>

    </div>

    <nb-card>
      <nb-card-body class="text-center">

        <div class="row text-center p-2 d-flex justify-content-center">

          <h4 class="text-center m-1">La Empresa ha gastado este mes<br><span
              class="display-4">{{totalIngresadoMes}}€</span> <br></h4>

        </div>

      </nb-card-body>
    </nb-card>


    <div class="row">
      <div class="col">



        <h5>Gastos por usuarios</h5>

        <div class="col-sm-12 mb-2" *ngFor="let i of datosTotales">
          <nb-accordion>
            <nb-accordion-item>
              <nb-accordion-item-header class="d-flex justify-content-between  pr-5">
                <div>
                  {{ i.usuario}}
                </div>
                <div>
                  {{i.sumaTotalMes}}€
                </div>


              </nb-accordion-item-header>
              <nb-accordion-item-body>
                <div class="row text-center">
                  <div class="col-md col-sm-12">
                    <ng-container *ngFor="let item of datos">

                      <nb-card class="fondo m-1 mr-0 ml-0" *ngIf="i.usuario==item.usuario">
                        <nb-card-body class="text-center ">

                        
                          <div class="row no-gutters flex-column">
                            <div class="d-flex justify-content-between">
                              <!-- <div class="d-flex "> -->
                              <p class="mb-0"> {{item.concepto}}</p>
                              <!-- </div> -->
                              <!-- <div> -->
                              {{item.cantidad}}€
                              <!-- </div> -->
                            </div>
                            <div class="d-flex justify-content-betweenalign-items-center">
    
                              
                              <p class="mb-0">{{item.fecha}}</p>
                            </div>
                          </div>

                        </nb-card-body>
                      </nb-card>

                    </ng-container>

                  </div>
                </div>
              </nb-accordion-item-body>
            </nb-accordion-item>
          </nb-accordion>
        </div>

      </div>

    </div>




  </div>


</section>
