import { Component, OnInit } from '@angular/core';

import { NbCalendarRange, NbDateService } from '@nebular/theme';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss']
})
export class CalendarioComponent implements OnInit {
  date: Date;
  min: Date;
  max: Date;
  constructor(protected dateService: NbDateService<Date>) {
    this.date = this.dateService.today();
   }

  ngOnInit() { }

}
