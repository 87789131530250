import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/conexionApi.service';

import { Peticion } from 'src/app/services/peticion';
import { NbAuthService, NbAuthJWTToken } from 'src/framework/auth/public_api';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-ingresosFijos',
  templateUrl: './ingresosFijos.component.html',
  styleUrls: ['./ingresosFijos.component.scss']
})
export class IngresosFijosComponent implements OnInit {

  options: string[];

  datos =[] ;

  totalMes:number = 0;

  datosTotales = [];

  porcentaje = 0;

  sinespecificar = "Sin especificar";
  user :any;
  nombreCompleto:any;
  myArray: any;
  nombre: any;


  // -------------------
  single: any[];

  singleMes: any[];
  multi: any[];

  // view: any[] = [700, 400];
  // view = [220,220];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = 'Días';
  showYAxisLabel = false;
  yAxisLabel = 'Ingresos';

  colorScheme = {
    domain: ['#93d3fb']
  };





  constructor(private authService: NbAuthService,private router: Router, private http: ApiService, private cdRef: ChangeDetectorRef, private cdr: ChangeDetectorRef ) {

    this.authService.onTokenChange()
    .subscribe((token: NbAuthJWTToken) => {

      this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
        this.nombreCompleto = this.user.data.fullname;
        this.myArray = this.nombreCompleto.split(" ");
        this.nombre = this.myArray[0];

        // alert(this.nombreCompleto);

      // if (token.isValid()) {
      //   this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
      // }
    });


  }

  get status() {
    if (this.porcentaje <= 25) {
      return 'danger';
    } else if (this.porcentaje <= 50) {
      return 'warning';
    } else if (this.porcentaje <= 75) {
      return 'info';
    } else {
      return 'success';
    }
  }

  ngOnInit() {

    var t = localStorage.getItem('auth_app_token');
    this.obtenerDatos({ token: t });
    this.obtenerIngresosMes({token:t});
    this.totalIngresoso({ token: t });

    setTimeout(() => {
      this.cdr.markForCheck ();
    }, 500);




    this.single = [
      {
        "name": "Lunes",
        "value": 185
      }
    ];


  }



  obtenerDatos(body: Peticion) {
    this.http.postRespuesta(body, "https://app.accionext.com/api/ingresos/ingresos.php").subscribe(
      info => {
        this.datos = info;
        //  console.log(this.datos);
        // this.source = new LocalDataSource(this.datos);

      },
      err => {
        console.log(err);
      }
    );
  }


  obtenerIngresosMes(body: Peticion) {
    this.http.postRespuesta(body, "https://app.accionext.com/api/ingresos/ingresosMes.php").subscribe(
      info => {
        this.singleMes = info;
        this.singleMes.forEach(element => {
          this.totalMes += Number(element.value);
        });
        //  console.log(this.datos);
        // this.source = new LocalDataSource(this.datos);

      },
      err => {
        console.log(err);
      }
    );
  }


  totalIngresoso(body: Peticion) {
    // alert("this.datosTotales");
    this.http.postRespuesta(body, "https://app.accionext.com/api/ingresos/totalIngresos.php").subscribe(
      info => {
        this.datosTotales = info;
        //  console.log(this.datosTotales);
        //  alert(this.datosTotales[0].sumaTotalMes);
        // this.source = new LocalDataSource(this.datos);
        // this.totalIngresosMes =
        var objetivo = 1500;

        this.porcentaje = (this.datosTotales[0].sumaTotalMes*100)/objetivo;

      },
      err => {
        console.log(err);
      }
    );
  }

  onSelect(event) {
    console.log(event);
  }

}
