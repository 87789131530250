<nb-card>
  <nb-card-header>
    <div class="row">
      <div class="col-sm-12 mb-1">
        <div class="text-center d-flex justify-content-between">
          <div>
            <h1 class="section-heading">Nueva reserva</h1>
          </div>
          <div>
            <button nbButton outline status="warning" data-toggle="modal" data-target="#modelId"
              class="btnAddClient mt-2">
              <i class="fas fa-info-circle"></i>
            </button>

            <!-- Modal -->
            <div class="modal fade w-100" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
              aria-hidden="true">
              <div class="modal-dialog w-100" role="document">
                <div class="modal-content p-1">
                  <div class="modal-header">
                    <h5 class="modal-title text-dark font-weight-bold">
                      Información
                    </h5>
                    <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button> -->
                  </div>
                  <div class="modal-body">
                    <h6 class="text-success text-left m-1">
                      ¿Como registramos los nuevos clientes?
                    </h6>
                    <p class="text-dark text-left">
                      Tomas todos los datos y los registramos con nosotros, tras
                      eso tarificamos con todas nuetras compañías y
                      colaboradores
                    </p>
                    <!-- <ul >
                <li class="text-dark">Tu continuidad con respecto al uso de la aplicación.</li>
                <li class="text-dark">Numero de preguntas bien y mal respondidas.</li>
                <li class="text-dark">Numero de videos vistos.</li>
                <li class="text-dark">Numero de pdf leídos.</li>
              </ul> -->
                    <hr />
                    <!-- <h6 class="text-secondary">Estadísticas basadas en tus preguntas</h6>
              <p class="text-dark">Este apartado de estadísticas cuenta cuantas preguntas están bien respondidas y mal respondidas y las compara entre las dos.</p>
              <hr>
              <h6 class="text-primary">Tu dia a dia</h6>
              <p class="text-dark">Este apartado de estadísticas muestra tus acciones de los últimos 7 dias.</p>
              <ul>
                <li class="text-dark">Numero de apartados leídos y vistos</li>
                <li class="text-dark">Numero de tests por días.</li>
              </ul>
              <hr>
              <h6 class="text-danger">Tu progreso por temas</h6>
              <p class="text-dark">Este apartado de estadísticas muestra el porcentaje de preguntas bien respondidas por tema.</p>
              <hr> -->
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger" data-dismiss="modal">
                      Cerrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <nb-stepper orientation="horizontal">
      <nb-step [label]="labelOne">
        <ng-template #labelOne>Titular</ng-template>
        <h4>Datos del titular</h4>

        <form (ngSubmit)="ingresar()" novalidate>
          <div class="row">
            <div class="col-md-12 d-flex flex-column justify-content-around">

              <app-completar-cliente class="mb-3 mw-100 w-100" fullWidth></app-completar-cliente>

              <input type="text" class="mb-3 mw-100 w-100" required placeholder="DNI" [(ngModel)]="inputDNI" name="dni"
                nbInput fullWidth />

              <input type="text" class="mb-3 mw-100 w-100" required placeholder="Nombre" [(ngModel)]="inputNombre"
                name="nombre" nbInput fullWidth />
              <input type="text" class="mb-3 mw-100 w-100" required placeholder="Apellido" [(ngModel)]="inputApellidos"
                name="apellidos" nbInput fullWidth />
              <input type="text" class="mb-3 mw-100 w-100" required placeholder="Nº Teléfono"
                [(ngModel)]="inputTelefono" name="telefono" nbInput fullWidth />
              <input type="text" class="mb-3 mw-100 w-100" required placeholder="Email" [(ngModel)]="inputEmail"
                name="email" nbInput fullWidth />
            </div>
          </div>

          <div class="w-100 d-flex justify-content-end">
            <button nbButton disabled nbStepperNext>Anterior</button>
            <button type="submit" class="ml-3" nbButton nbStepperNext>Siguiente</button>
          </div>
        </form>
      </nb-step>
      <nb-step [label]="labelTwo">
        <ng-template #labelTwo>Reserva</ng-template>
        <h4>Datos de la reserva</h4>
        <form (ngSubmit)="ingresar()" novalidate>
          <div class="row">
            <div class="col-md-12 justify-content-around">


              <!-- `id`, `id_Reserva`, `origen`, `destino`, `compania`, `fecha_Ida`, `fecha_Vuelta`, `importe_Total`, `tipo_Reserva`, `cantidad_Integrantes`, `id_cliente` -->

              <input type="text" class="mb-3 mw-100 w-100" required placeholder="ID Reserva"
                [(ngModel)]="inputIdReserva" name="inputIdReserva" nbInput fullWidth />

              <input type="text" class="mb-3 mw-100 w-100" required placeholder="Origen" [(ngModel)]="inputOrigen"
                name="inputOrigen" nbInput fullWidth />
              <input type="text" class="mb-3 mw-100 w-100" required placeholder="Destino" [(ngModel)]="inputDestino"
                name="inputDestino" nbInput fullWidth />
              <!-- <input type="text" class="mb-3 mw-100 w-100" required placeholder="Compañia" [(ngModel)]="inputCompania"
                name="inputCompania" nbInput fullWidth /> -->
              
              <input type="text" class="mw-100 w-100 mb-3" required placeholder="Tipo de reserva"
                [(ngModel)]="inputTipoReserva" name="inputTipoReserva" nbInput fullWidth />

                <input type="text" class="mw-100 w-100 mb-3" required placeholder="Compañia"
                [(ngModel)]="inputCompania" name="inputCompania" nbInput fullWidth />

                <!-- <select name="miselect" [(ngModel)]="inputCompania" nbInput fullWidth class="w-100 mt-2 mb-3" >
                  <option [value]="item" *ngFor="let item of listaCompanias" nbInput fullWidth>{{item}}</option>
                </select> Seguro que se ocurre algo mejor (esto tendria que activarse solo al completar el tipo de reserva para que muestre las compañias ok) -->

              <!-- <nb-select placeholder="Select Showcase">
                <nb-option value="">Option empty</nb-option>
                <nb-option value="0">Option 0</nb-option>
                <nb-option value="1">Option 1</nb-option>
                <nb-option value="2">Option 2</nb-option>
                <nb-option value="3">Option 3</nb-option>
                <nb-option value="4">Option 4</nb-option>
              </nb-select> -->


              <!-- <nb-select placeholder="Custom label" [(selected)]="selectedItem">
                  <nb-select-label>
                    Selected: {{ selectedItem }}
                  </nb-select-label>

                  <nb-option value="1">Option 1</nb-option>
                  <nb-option value="2">Option 2</nb-option>
                  <nb-option value="3">Option 3</nb-option>
                  <nb-option value="4">Option 4</nb-option>
                </nb-select> -->





              <input type="text" placeholder="Fechas de tu viaje" 
                [locale]="{ applyLabel: 'ok',format: 'YYYY-MM-DD', firstDay: 1,  separator: ' ➙ '}" [autoApply]="true"
                ngxDaterangepickerMd [(ngModel)]="selected" class="form-control mb-3 mw-100 w-100" nbInput fullWidth  name="daterange" />

              <!-- <fa-icon class=' iconMenuPeq p-1' [icon]="faCalendar"></fa-icon> -->

              <input type="text" class="mb-3 mw-100 w-100" required placeholder="Importe total"
                [(ngModel)]="inputImporteTotal" name="inputImporteTotal" nbInput fullWidth />

              <input type="text" class="mb-3 mw-100 w-100" required placeholder="Número de integrantes"
                [(ngModel)]="inputNumeroIntegrantes" name="inputNumeroIntegrantes" nbInput fullWidth />




            </div>
          </div>

          <div class="w-100 d-flex justify-content-end">
            <button nbButton nbStepperPrevious>Anterior</button>
            <button type="submit" class="ml-3" nbButton nbStepperNext>Siguiente</button>


          </div>
        </form>

      </nb-step>
      <nb-step label="Preguntas">
        <h4>Preguntas</h4>
        <p class="lorem">
          Lorizzle ipsum dolizzle stuff fizzle, consectetuer adipiscing break it
          down. Nullizzle sapien velizzle, my shizz pimpin', shizzle my nizzle
          crocodizzle shut the shizzle up, gravida vizzle, dang.
        </p>
        <div class="w-100 d-flex justify-content-end">
          <button nbButton nbStepperNext>Anterior</button>
          <button type="submit" class="ml-3" nbButton nbStepperNext>Siguiente</button>
        </div>
      </nb-step>
      <nb-step [label]="labelFour">
        <ng-template #labelFour>Guardar</ng-template>
        <h4>Guardar resumen</h4>
        <p class="lorem">
         El usuario acepta y confirma que tengamos sus datos registrados (LOPD) 
        </p> 
        <!-- Si le da a guardar es como que acepta que tengamos sus datos -->
        <div class="w-100 d-flex justify-content-end">
          <button nbButton nbStepperNext>Anterior</button>
          <button type="submit" class="ml-3" (click)="guardarInfo()" nbButton nbStepperNext>Guardar reserva</button>
        </div>
      </nb-step>
    </nb-stepper>
  </nb-card-body>
</nb-card>
