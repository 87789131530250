<section class="page-section mt-1 pt-1" id="services">
    <div class="container-fluid">
        <div class="text-center">
            <!-- <h2 class="section-heading text-uppercase">¡Bienvenido a accioges!</h2> -->
        </div>

        <!-- Content Row -->
        <div class="row mt-1 mb-3">
            <div class="col-md-8 text-left p-1">



                <nb-card accent="info">

                    <nb-card-header>

                        <div class="text-center d-flex justify-content-between">
                            <div>
                                <h5 class="text-center">Gestor de tareas</h5>

                            </div>
                            <div>

                                <nb-toggle [checked]="checkedNotif" labelPosition="left"
                                    (checkedChange)="changePushSubscription()">Solo las mías</nb-toggle>


                            </div>

                        </div>
                    </nb-card-header>


                    <nb-card-body class="p-2">

                        <div class="row">
                            <div class="col-md-4 p-3">
                                <nb-card>
                                    <nb-card-header>
                                        <div class="text-center d-flex justify-content-between">
                                            <div>
                                                <h5 class="text-center">Todas</h5>

                                            </div>
                                            <div>

                                            </div>

                                        </div>
                                    </nb-card-header>


                                    <nb-card-body class="p-1">
                                        <div cdkDropList id="list-todo" [cdkDropListConnectedTo]="['list-enProceso']"
                                            [cdkDropListData]="todo" (cdkDropListDropped)="drop($event)">
                                            <div class="example-box" *ngFor="let item of todo" cdkDrag>{{item}}</div>
                                        </div>
                                    </nb-card-body>

                                </nb-card>


                            </div>
                            <div class="col-md-4 p-3">

                                <nb-card>
                                    <nb-card-header>
                                        <div class="text-center d-flex justify-content-between">
                                            <div>
                                                <h5 class="text-center">En proceso</h5>

                                            </div>
                                            <div>

                                            </div>

                                        </div>
                                    </nb-card-header>


                                    <nb-card-body class="p-1">
                                        <div cdkDropList id="list-enProceso" [cdkDropListData]="enProceso"
                                            [cdkDropListConnectedTo]="['list-todo', 'list-hecho']"
                                            (cdkDropListDropped)="drop($event)">
                                            <div class="example-box" *ngFor="let item of enProceso" cdkDrag>{{item}}
                                            </div>
                                        </div>
                                    </nb-card-body>

                                </nb-card>


                            </div>
                            <div class="col-md-4 p-3">

                                <nb-card>
                                    <nb-card-header>
                                        <div class="text-center d-flex justify-content-between">
                                            <div>
                                                <h5 class="text-center">Hecho</h5>

                                            </div>
                                            <div>

                                            </div>

                                        </div>
                                    </nb-card-header>


                                    <nb-card-body class="p-1">
                                        <div cdkDropList id="list-hecho" [cdkDropListData]="hecho"
                                            [cdkDropListConnectedTo]="['list-enProceso']"
                                            (cdkDropListDropped)="drop($event)">
                                            <div class="example-box" *ngFor="let item of hecho" cdkDrag>{{item}}</div>
                                        </div>
                                    </nb-card-body>

                                </nb-card>


                            </div>
                        </div>

                    </nb-card-body>

                    <nb-card-footer>


                    </nb-card-footer>


                </nb-card>

            </div>

            <div class="col-md-4 text-left p-1">



                <nb-card accent="info">

                    <nb-card-header>

                        <div class="text-center d-flex justify-content-between">
                            <div>
                                <h5 class="text-center">lista rápida</h5>

                            </div>
                            <div>
                                <!-- <button nbButton outline status="primary" class=" " (click)="borrarDesglose()"> -->
                                <!-- <i class="iconoir-undo lead"> </i> -->
                                <!-- <i class="iconoir-undo-action lead h2" (click)="borrarDesglose()"> </i> -->
                                <!-- </button> -->


                            </div>

                        </div>
                    </nb-card-header>


                    <nb-card-body class="p-1">

                        <!-- <button nbButton outline status="primary" class="btnAddClient   m-1" (click)="executeCommand('toggleAudio')">
                            <i class="fas  grey-color"
                            [ngClass]="isAudioMuted ? 'fa-microphone-slash' : 'fa-microphone'" aria-hidden="true"
                            title="Mute / Unmute"></i> Micro
                          </button>

                          <button nbButton outline status="primary" class="btnAddClient   m-1" (click)="executeCommand('hangup')">
                            <i  class="fas fa-phone-slash  red-color"
                            aria-hidden="true" title="Leave"></i> Salir
                          </button>

                          <button nbButton outline status="primary" class="btnAddClient  m-1" (click)="executeCommand('toggleVideo')">
                            <i  class="fas "
                            [ngClass]="isVideoMuted ? 'fa-video-slash' : 'fa-video'" aria-hidden="true"
                            title="Start / Stop camera"></i>
                          </button>

                          <button nbButton outline status="primary" class="btnAddClient  m-1" (click)="executeCommand('toggleShareScreen')">
                            <i class="fas fa-film r"
                                aria-hidden="true" title="Share your screen"></i>
                          </button> -->



                        <app-to-do></app-to-do>







                    </nb-card-body>


                </nb-card>

            </div>


        </div>
    </div>
</section>