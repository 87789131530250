<ng-template><ng-content select="nb-step"></ng-content></ng-template>
<div class="header">
  <ng-container *ngFor="let step of steps; let index = index; let first = first">

    <div *ngIf="!first && !step.hidden"
         [class.connector-past]="index <= selectedIndex"
         class="connector"></div>

    <div *ngIf="!step.hidden" class="step"
         [class.selected]="isStepSelected(step)"
         [class.completed]="!isStepSelected(step) && step.completed"
         [class.noninteractive]="disableStepNavigation"
         (click)="!disableStepNavigation && step.select()">
      <div class="label-index">
        <span *ngIf="!step.completed || isStepSelected(step)">{{ index + 1 }}</span>
        <nb-icon *ngIf="!isStepSelected(step) && step.completed" icon="checkmark-circle-outline" pack="nebular-essentials">
        </nb-icon>
      </div>
      <div class="label">
        <ng-container *ngIf="step.isLabelTemplate">
          <ng-container *ngTemplateOutlet="getStepTemplate(step)"></ng-container>
        </ng-container>
        <span *ngIf="!step.isLabelTemplate">{{ step.label }}</span>
      </div>
    </div>
  </ng-container>
</div>
<div class="step-content">
  <ng-container [ngTemplateOutlet]="selected?.content"></ng-container>
</div>
