import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApiService } from 'src/app/services/conexionApi.service';
import { NbAuthService, NbAuthJWTToken } from 'src/framework/auth/public_api';
import { Peticion } from 'src/app/services/peticion';
import { DatePipe, Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { faCalendarAlt, faUser } from '@fortawesome/free-regular-svg-icons';
import { faMapMarkedAlt, faGlobeEurope, faSuitcase, faHotel, faShip, faGlobeAmericas, faPlaneDeparture } from '@fortawesome/free-solid-svg-icons';
import * as internal from 'assert';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss']
})
export class NewComponent implements OnInit {

  firstForm: FormGroup;
  secondForm: FormGroup;
  thirdForm: FormGroup;

  idCliente = 0;
  inputDNI: string = "";
  inputNombre: string = '';
  inputApellidos: string = '';
  inputTelefono: string = '';
  inputEmail: string = '';

  // campos de reserva

  inputIdReserva: string = "";
  inputOrigen: string = "";
  inputDestino: string = "";
  inputCompania: string = "";
  inputImporteTotal: string = "";
  inputNumeroIntegrantes: string = "";
  inputTipoReserva: string = "";


  listaCompanias:string[]=["Iberia","que","tal", "estas"];
  seleccionado="";

  // source: LocalDataSource;

  datos:any = {};



  public subscriber: Subscription;



  faCalendar = faCalendarAlt;
  faMapMarkedAlt = faMapMarkedAlt;
  faGlobeEurope = faGlobeEurope;
  faGlobeAmericas = faGlobeAmericas;
  faPlaneDeparture = faPlaneDeparture;
  faUser = faUser;
  faSuitcase = faSuitcase;
  faHotel = faHotel;
  faShip = faShip;




  constructor(private https: ApiService,private route: ActivatedRoute, private fb: FormBuilder, private authService: NbAuthService,private _location: Location, private router: Router,private cdr: ChangeDetectorRef, private http: ApiService, private cdRef: ChangeDetectorRef, private formBuilder: FormBuilder,public datepipe: DatePipe) {
  }


  selected: { startDate: Date, endDate: Date };
  origen: Text;
  destino: Text;
  habPer: Text;

  ngOnInit() {
    this.subscriber = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event) => {
      //  console.log('The URL changed to: ' + event['url'])
      //  alert("ha cambiado");
      var t = localStorage.getItem('auth_app_token');
      this.obtenerDatos({ token: t, id_cliente: this.route.snapshot.url.toString() });
      //  this.completarDatos();
    });
    var t = localStorage.getItem('auth_app_token');
      this.obtenerDatos({ token: t, id_cliente: this.route.snapshot.url.toString() });

  }

  ingresarPost(body: Peticion) {
    this.http.postRespuesta(body, "https://app.accionext.com/api/servicios/addservicio.php").subscribe(
      data => {
        //this.datos = data;
        // this.user['data']['fullname'] = this.datos['fullname'];
        // localStorage.setItem('auth_app_token', JSON.stringify({'name':'nb:auth:jwt:token','ownerStrategyName':'email','createdAt':1589115670000,'value': this.datos['token']}));

      },
      err => { }
    );

  }

  ingresar() {
    //alert(this.inputServicio + " -- " + this.inputImporte + " -- "+ this.inputCliente);
   var t = localStorage.getItem('auth_app_token');
   this.ingresarPost({ dni:this.inputDNI,nombre:this.inputNombre,apellidos:this.inputApellidos,telefono:this.inputTelefono,email:this.inputEmail, token: t });

  //  this.goBack();
   // alert(this.inputDNI + " -- " + this.inputNombre + " -- "+ this.inputApellidos + " -- "+ this.inputTelefono + " -- "+ this.inputEmail);
 }


  obtenerDatos(body: Peticion) {
    this.https.postRespuesta(body, "https://app.accionext.com/api/clientes/cliente.php").subscribe(
      info => {
        this.datos = info;
        this.idCliente = this.datos[0].id;
        this.inputDNI = this.datos[0].dni;
        this.inputNombre = this.datos[0].nombre;
        this.inputApellidos = this.datos[0].apellidos;
        this.inputTelefono = this.datos[0].telefono;
        this.inputEmail = this.datos[0].email;

        this.cdr.markForCheck();
      },
      err => {
        console.log(err);
      }
    );
  }

  guardarInfo() {
    var t = localStorage.getItem('auth_app_token');
    if (this.idCliente == 0) {
      //añadir cliente primero para sacar su id y establecer idCliente
      var body1 = {token: t,dni: this.inputDNI, nombre: this.inputNombre, apellidos: this.inputApellidos, telefono:this.inputTelefono, email: this.inputTelefono};
      this.https.postRespuesta(body1, "https://app.accionext.com/api/clientes/addcliente.php").subscribe(
        info => {
          this.idCliente = info.id; //falta hacer esto en la api
        },
        err => {
          console.log(err);
        }
      );
      console.log("No existe mi id");
    } 
    

    var fechainicio = this.datepipe.transform(this.selected.startDate, 'yyyy-MM-dd');
    var fechafin = this.datepipe.transform(this.selected.endDate, 'yyyy-MM-dd');


    var body = {token: t,idreserva: this.inputIdReserva, origen: this.inputOrigen, destino: this.inputDestino, compania:this.inputCompania, fechaIda: fechainicio, fechaVuelta:fechafin,importe:this.inputImporteTotal, tiporeserva: this.inputTipoReserva, cantidad:this.inputNumeroIntegrantes,idcliente:this.idCliente};
    this.https.postRespuesta(body, "https://app.accionext.com/api/viajes/addviaje.php").subscribe(
      info => {
       
      },
      err => {
        console.log(err);
      }
    );

    this.router.navigate(['/pages/viajes/reservas']);
  }

}
