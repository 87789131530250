import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from '../../../../../../services/conexionApi.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-editar',
  templateUrl: './modal-editar.component.html',
  styleUrls: ['./modal-editar.component.scss']
})
export class ModalEditarComponent implements OnInit {
  //Recibe los datos del elemento padre, ver-cliente.component.
  @Input() datosCliente: any;
  
  //Evento que emite cuando se cierra el modal
  @Output() close = new EventEmitter<boolean>();

  //Atributo de formulario
  formularioEditar: FormGroup;
  //token
  t;

  constructor(private fb: FormBuilder, private https: ApiService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.t = localStorage.getItem('auth_app_token');
    //Formulario con los datos del cliente a editar, recibidos del elemento padre.
    this.formularioEditar = this.fb.group({
      id: [this.datosCliente.id],
      dni: [this.datosCliente.dni],
      nombre: [this.datosCliente.nombre],
      apellidos: [this.datosCliente.apellidos],
      email: [this.datosCliente.email],
      telefono: [this.datosCliente.telefono]
    })
  }

//Emite un evento con el valor "false" para cerrar el modal una vez se hace click en cerrar
  closeModal(){
    this.close.emit(this.datosCliente);
  }



//Toma los campos del formulario y los añade a datosCliente. Llama a la base de datos para actualizar los cambios y si tiene éxito, muestra una alerta. Si ocurre un error, muestra otra alerta de error. Emite los datos cambiados del cliente al elemento padre.
  saveAndClose(){
    this.datosCliente = this.formularioEditar.value;
    this.https.postRespuesta({token: this.t, datosCliente: this.datosCliente}, "https://app.accionext.com/api/clientes/updatecliente.php").subscribe( () => {
    this.toastr.success("Cambios guardados", "Éxito", {timeOut: 2000});
    }, (err) =>{
    this.toastr.error("No se han podido guardar los cambios", "Error", {timeOut: 2000});
    });
    this.close.emit(this.datosCliente);

  }

}
