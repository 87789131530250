<nb-card accent="primary">
  <nb-card-body>
    <div class="row">
      <div class="col-sm-12 mb-4">


        <div class="text-center d-flex justify-content-between">
          <div>
            <h3 class="section-heading">Ingresar</h3>

          </div>
          <div>





          </div>

        </div>
      </div>
    </div>

    <form (ngSubmit)="ingresar()" novalidate>
      <div class="row">
        <div class="col-md-4 d-flex flex-row justify-content-around ">

          <nb-form-field class="mw-100 w-100">

            <input [formControl]="inputFormControlServicios" type="text" class="mb-3 mw-100 w-100"
              placeholder="Servicio" [nbAutocomplete]="servicio" [(ngModel)]='inputServicio' nbInput fullWidth />
            <!-- <i nbPrefix class="fas fa-euro-sign fa-inverse text-primary "></i> -->

            <i nbPrefix class="fas fa-cogs fa-inverse text-primary h1 pb-3 m-1 "></i>



          </nb-form-field>

          <nb-autocomplete #servicio>

            <nb-option *ngFor="let optionServicio of filteredServicios$ | async" [value]="optionServicio">
              {{ optionServicio }}
            </nb-option>

          </nb-autocomplete>

          <!-- [routerLink]="['/pages/facturas']" -->

          <button nbButton outline status="primary" class="btnAddClient mb-3 m-0 " routerLink="/pages/addServicio">
            <!-- <i class="fas fa-user-plus"></i> -->
            <!-- <i class="fas fa-cog"></i> -->
            <i class="fas fa-plus"></i>


          </button>





        </div>

        <div class="col-md-4 d-flex justify-content-around">

          <nb-form-field class="mw-100 w-100">

            <input id="importe" class="mb-3 mw-100 w-100" name="importe" type="text" [(ngModel)]='inputImporte' nbInput
              fullWidth placeholder="Importe">
            <i nbPrefix class="fas fa-euro-sign fa-inverse h2 pb-2 text-primary "></i>

          </nb-form-field>

          <button nbButton outline status="primary" class="btnAddClient mb-3 m-0 " routerLink="/pages/servicios">
            <i class="fas fa-search-dollar"></i>
          </button>


        </div>

        <!-- <div class="col-md-1 d-flex ustify-content-start align-items-center p-1">
          <label class="label text-primary  mb-2"> <span class=" text-primary h5 mr-2">IVA </span> </label>
        <nb-toggle [checked]="false"></nb-toggle>

        </div> -->

        <div class="col-md-4 d-flex justify-content-around">

          <nb-form-field class="mw-100 w-100 ">

            <input [formControl]="inputFormControlClientes" type="text" class="mb-3 mw-100 w-100" placeholder="Clientes"
              [nbAutocomplete]="cliente" [(ngModel)]='inputCliente' nbInput fullWidth />
            <!-- <i nbPrefix class="fas fa-euro-sign fa-inverse text-primary "></i> -->
            <i nbPrefix class="fas fa-users fa-inverse text-primary display-4 m-1 h1 pb-3"></i>

          </nb-form-field>

          <nb-autocomplete #cliente>
            <nb-option *ngFor="let optionCliente of filteredOptions$ | async" [value]="optionCliente">
              {{ optionCliente }}
            </nb-option>
          </nb-autocomplete>


          <button type="button" nbButton outline class="btnAddClient mb-3 m-0 " status="primary" data-toggle="modal"
            data-target="#modelId">
            <i class="fas fa-plus"></i>
          </button>

          <!-- Modal -->
          <div class="modal fade w-100" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
            aria-hidden="true">
            <div class="modal-dialog w-100" role="document">
              <div class="modal-content p-1">
                
                <div class="modal-body">

                  <app-addClient></app-addClient>

                </div>
                
              </div>
            </div>
          </div>

        </div>







      </div>

      <!-- <div class="col-sm-12 m-2  d-flex justify-content-start align-items-start">
        <label class="label h3 text-primary m-2"> IVA</label>
        <nb-toggle [checked]="false"></nb-toggle>
    </div> -->

      <div class="col-sm-12 mb-3 p-0">
        <!-- <button nbButton fullWidth status="primary">Ingresar</button> -->
        <button type="submit" nbButton fullWidth outline status="primary" class="text-primary  m-0 ">
          Ingresar <nb-icon nbPrefix class="" icon="credit-card-outline">
          </nb-icon>
        </button>
      </div>





    </form>

  </nb-card-body>
</nb-card>