

<h5 class="text-center text-danger">Tu progreso por temas</h5>
<div class=" d-flex justify-content-center flex-wrap align-items-center mb-3">
      <div class="card bg-transparent border-0 " *ngFor="let tema of temas">
        <div class="card-body p-0">
        <ngx-charts-pie-grid  
        [view]="view"
        [designatedTotal]="tema.totalPreguntas"
        [scheme]="colorScheme" 
        [results]="tema.single"
        (select)="onSelect($event)">
        </ngx-charts-pie-grid>
        </div>
      </div>
    </div>
      
