<nb-card>
  <nb-card-header>
    <div class="row">
      <div class="col-sm-12 mb-1">
        <div class="text-center d-flex justify-content-between">
          <div >
            <h3 class="section-heading">Servicios</h3>
          </div>
          <div>
            <button nbButton outline status="warning" data-toggle="modal" data-target="#modelId" class="btnAddClient mt-2" >
              <i class="fas fa-info-circle"></i>
            </button>
            <div class="modal fade w-100" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
              <div class="modal-dialog w-100" role="document">
                <div class="modal-content p-1">
                  <div class="modal-header">
                    <h5 class="modal-title text-dark font-weight-bold">Información</h5>
                  </div>
                  <div class="modal-body">
                    <h6 class="text-success text-left m-1">Estadisticas de servicios</h6>
                    <p class="text-dark text-left">Aqui podremos ver las estadisticas de los ervicios más vendidos en el año, los que más ingresos han producido, los servicios mas vendidos e ingresados por meses.</p>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cerrar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body class="pl-2 pr-2">
    <div class="row">
      <div class="col-12">
        <ng-container *ngIf="servicioMasIngresosAno == servicioMasVendidoAno; else elseTemplate1">
          <h5 class="pl-3">Servicio top del AÑO: {{servicioMasIngresosAno}}</h5>
        </ng-container>
        <ng-template #elseTemplate1> 
          <h5 class="pl-3">El servicio más vendido en el año: {{servicioMasVendidoAno}} y el servicio que más ingresos ha generado es: {{servicioMasIngresosAno}}</h5>
        </ng-template>
        
        <ng-container *ngIf="servicioMasIngresosMes == servicioMasVendidoMes; else elseTemplate">
          <h5 class="pl-3" ng-if >Servicio top del MES: {{servicioMasIngresosMes}} </h5>
        </ng-container>
        <ng-template #elseTemplate>
          <h5 class="pl-3">El servicio más vendido: {{servicioMasVendidoMes}} y el servicio que más ingresos ha generado este MES es: {{servicioMasIngresosMes}}</h5>
        </ng-template>
        
       
        
      </div>
    </div>
  </nb-card-body>
</nb-card>

<nb-card>
  <nb-card-body class="pl-2 pr-2">
    <div class="row">
      <div class="col-12">
        <h5 class="pl-3">Diez servicios más vendidos en el año</h5>
          <ngx-charts-pie-chart
          [view]="view"
          [scheme]="colorScheme"
          [results]="diezServiciosMasVendidosAno"
          [gradient]="gradient"
          [legend]="showLegend"
          [legendPosition]="legendPosition"
          [labels]="showLabels"
          [doughnut]="isDoughnut"
          (select)="onSelect($event)"
          (activate)="onActivate($event)"
          (deactivate)="onDeactivate($event)"
          >
        </ngx-charts-pie-chart>
      </div>
    </div>
  </nb-card-body>
</nb-card>


<nb-card>
  <nb-card-body class="pl-2 pr-2">
    <div class="row">
      <div class="col-12 col-md-6">
        <h5 class="pl-3">Servicio más vendido por mes</h5>
          <!-- <app-gastos></app-gastos> -->

          <ngx-charts-bar-vertical-stacked
            [view]="view2"
            [scheme]="colorScheme"
            [results]="serviciosMasVentasCadaMes"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            (select)="onSelect($event)">
          </ngx-charts-bar-vertical-stacked>



      </div>
    

    <div class="col-12 col-md-6">
      <h5 class="pl-3">Servicio con más ingresos por mes</h5>

      <ngx-charts-bar-vertical-stacked
            [view]="view2"
            [scheme]="colorScheme"
            [results]="serviciosMasIngresosCadaMes"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            (select)="onSelect($event)">
          </ngx-charts-bar-vertical-stacked>
    </div>
  </div>


  </nb-card-body>
</nb-card>
