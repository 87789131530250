<section class="page-section mt-1 pt-1" id="services">
    <div class="container-fluid">
      <div class="text-center">
        <!-- <h2 class="section-heading text-uppercase">¡Bienvenido a accioges!</h2> -->
      </div>
  
      <!-- Content Row -->
      <div class="row mt-1 mb-3">
        <div class="col-xl-3 col-md-6 mb-2 p-0 p-0">
          <div
            class="card border-primary py-1 btn bAzul"
            [routerLink]="['/pages/reuniones/meet']"
          >
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2 d-flex justify-content-start">
                  <h6 class="text-primary">Desarollo</h6>
                </div>
                <div class="col-auto">
                  <i
                    class="fas fa-business-time fa-inverse text-primary h1 m-1"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="col-xl-3 col-md-6 mb-2 p-0">
          <div
            class="card border-primary py-1 btn bAzul"
            [routerLink]="['/pages/reuniones/meet']"
          >
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2 d-flex justify-content-start">
                  <h6 class="text-primary">Administracion</h6>
                </div>
                <div class="col-auto">
                  <!-- <i class="fas fa-hand-holding-usd fa-inverse text-success h1 m-1"></i> -->
  
                  <i
                    class="fas fa-cash-register fa-inverse text-primary h1 m-1"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        
      </div>
    </div>
  </section>
  
