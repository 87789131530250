

<div class="card border-0 m-2 bg-transparent">
    <div class="card-body  pb-2">
        <div class="sizeChart d-flex justify-content-center flex-wrap">
            <h5 class="card-title text-success">La empresa tiene un benicifio de ...</h5>
            <ngx-charts-linear-gauge
            [scheme]="colorScheme"
            [value]="value"
            [previousValue]="previousValue"
            (select)="onSelect($event)"
            [units]="units">
        </ngx-charts-linear-gauge>
        </div> 
    </div>
</div> 