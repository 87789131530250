<nb-card>
  <nb-card-header>
    <div class="row">
      <div class="col-sm-12 ">


        <div class="text-center d-flex justify-content-between">
          <div>
            <h5 class="section-heading">Domicializaciones</h5>

          </div>
          <div>

            <button nbButton outline status="warning" data-toggle="modal" data-target="#modelId" class="btnAddClient ">
              <i class="fas fa-info-circle"></i>

            </button>

            <!-- Modal -->
            <div class="modal fade w-100" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
              aria-hidden="true">
              <div class="modal-dialog w-100" role="document">
                <div class="modal-content p-1">
                  <div class="modal-header">
                    <h5 class="modal-title text-dark font-weight-bold">Información</h5>
                    <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button> -->
                  </div>
                  <div class="modal-body">
                    <h6 class="text-success text-left m-1">Domicializaciones a los clientes</h6>
                    <p class="text-dark text-left">Aquí se refleja las domicializaciones a los clientes para reflejar el
                      estado y llevar un control</p>
                    <!-- <ul >
                <li class="text-dark">Tu continuidad con respecto al uso de la aplicación.</li>
                <li class="text-dark">Numero de preguntas bien y mal respondidas.</li>
                <li class="text-dark">Numero de videos vistos.</li>
                <li class="text-dark">Numero de pdf leídos.</li>
              </ul> -->

                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cerrar</button>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>

    <div class="row text-center p-2 ">

      <h6 class="text-center m-1">Resumen<br><span class="h6">Valor de Domiciliaciones al mes: 80€</span> <br></h6>

    </div>

  </nb-card-body>
</nb-card>


<nb-card>
  <nb-card-header>
    <div class="row">
      <div class="col-sm-12 ">


        <div class="text-center d-flex justify-content-between">
          <div>
            <h5 class="section-heading">Domiciliaciones</h5>

          </div>
          <div>

            <button nbButton outline status="warning" data-toggle="modal" data-target="#modelId2" class="btnAddClient ">
              <i class="fas fa-plus"></i>
            </button>

            <!-- Modal -->
            <div class="modal fade w-100" id="modelId2" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
              aria-hidden="true">
              <div class="modal-dialog w-100" role="document">
                <div class="modal-content p-1">
                  <div class="modal-header">
                    <h5 class="modal-title text-dark font-weight-bold">Nueva Domiciliacion</h5>
                    <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button> -->
                  </div>
                  <div class="modal-body">

                    <app-add-domiciliacion></app-add-domiciliacion>

                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cerrar</button>
                  </div>
                </div>
              </div>
            </div>




          </div>

        </div>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>

    <div class="row">
      <div class="col version-ordenador ">
        <ng2-smart-table [settings]="settings" [source]="source" (edit)="edit($event)" width="100%"></ng2-smart-table>

      </div>

      <div class="col-12 version-movil p-1">

        <div class="row" *ngFor="let item of datos; let i=index">
          <div class="col-12 p-1">
            <button nbButton outline status="primary" class="btnAddClient d-flex justify-content-start w-100 mb-1"
              (click)="verCliente(i)">
              <nb-user [name]="item.nombre + ' ' + item.apellidos" size="small" picture=""></nb-user>
            </button>

          </div>
        </div>


        <div class="col">

        </div>


      </div>

    </div>

  </nb-card-body>
</nb-card>
