import { Component, OnInit } from "@angular/core";
import { Observable, Observer } from "rxjs";
import { NbTokenService } from "src/framework/auth/public_api";
import { ApiService } from 'src/app/services/conexionApi.service';
import { Peticion } from 'src/app/services/peticion';
import {KeyValue} from '@angular/common';

@Component({
  selector: "app-fichar",
  templateUrl: "./fichar.component.html",
  styleUrls: ["./fichar.component.scss"],
})
export class FicharComponent implements OnInit {
  fechaActual = new Observable();

  token;

  datos: any[] = [];

  estadoActual:string = "";

  private onCompare(_left: KeyValue<any, any>, _right: KeyValue<any, any>): number {
    return -1;
  }

  constructor(private https: ApiService, private t: NbTokenService) {

    this.fechaActual = new Observable<string>((observer: Observer<string>) => {
      setInterval(() => observer.next(new Date().toString()), 1000);
    });

  }

  ngOnInit(): void {
    this.token = localStorage.getItem('auth_app_token');
    this.consultarJornada({ token: this.token });
    this.consultarEstadoActual({token: this.token});
  }

  //Este método añade la fecha (hora) en la que se clicka y se guarda en la variable correspondiente y posteriormente en el array datosFichar
  fichar(accion: string) {
    this.nuevaJornada({token: this.token, datosFichado: accion});

  }


  consultarJornada(body: Peticion) {
    this.https.postRespuesta(body, "https://app.accionext.com/api/jornada/consultarJornada.php")
      .subscribe(
        info => {
          this.datos = info;
          console.log(this.datos);
        },
        err => {
          console.log(err);
        }
      );
  }

  nuevaJornada(body: Peticion){
    this.https.postRespuesta(body, "https://app.accionext.com/api/jornada/nuevaJornada.php").subscribe(
      info => {
        this.consultarJornada({ token: this.token });
        this.consultarEstadoActual({token:this.token});
      },
      err => {
        console.log(err);
      }
    );


  }


  consultarEstadoActual(body: Peticion){
    this.https.postRespuesta(body, "https://app.accionext.com/api/jornada/consultarEstado.php").subscribe(
      info => {
        if (info == 'Entrada') {
          this.estadoActual = "Trabajando";
        } else {
          this.estadoActual = "No trabajando";
        }
        localStorage.setItem("estado",this.estadoActual);

      },
      err => {
        console.log(err);
      }
    );


  }

}
