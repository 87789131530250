<form  novalidate>

  <div class="col-md-12 d-flex justify-content-around pl-0 pr-0">

    <nb-form-field class="mw-100 w-100">

      <input [formControl]="inputFormControlClientes" type="text" class="mb-3 mw-100 w-100 border border-primary rounded" placeholder="Buscar en clientes"
        [nbAutocomplete]="cliente" [(ngModel)]='inputCliente' nbInput fullWidth />

      <i nbPrefix class="fas fa-users fa-inverse text-primary display-4 m-1 h1 pb-3"></i>

    </nb-form-field>

    <nb-autocomplete #cliente>
      <nb-option *ngFor="let optionCliente of filteredOptions$ | async" [value]="optionCliente">
        {{ optionCliente }}
      </nb-option>
    </nb-autocomplete>

    <button nbButton outline status="primary" class="btnAddClient mb-3 m-0 ml-3" (click)="seleccionarCliente()">

      <!-- <i class="fas fa-plus"></i> -->
      Seleccionar

    </button>

  </div>




</form>
