
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/conexionApi.service';

import { Peticion } from 'src/app/services/peticion';
import { NbAuthService, NbAuthJWTToken } from 'src/framework/auth/public_api';


@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.scss']
})
export class NotificacionesComponent implements OnInit {

  datos:any = [];



  constructor(private cdr: ChangeDetectorRef, private https: ApiService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(){
    var t = localStorage.getItem('auth_app_token');
    this.obtenerDatos({ token: t });

  }


  obtenerDatos(body: Peticion) {
    this.https.postRespuesta(body, "https://api.cisnea.com/notificaciones.php").subscribe(
      info => {
        this.datos = info;
        this.cdr.markForCheck ();
        console.log(this.datos);
      },
      err => {
        console.log(err);
      }
    );
  }



  verMensaje(id){
     this.router.navigate(['mensaje/'+id], {relativeTo: this.route});
  }

}
