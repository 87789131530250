import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ApiService } from 'src/app/services/conexionApi.service';
import { Peticion } from 'src/app/services/peticion';
import { NbTokenService } from 'src/framework/auth/public_api';
import { TokenServices } from '../../../services/token-services.service';



@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent {

  rol: any;

  constructor(private https: ApiService,  private t: NbTokenService, private decode: TokenServices) {


  }

  ngOnInit(): void {
    var t = localStorage.getItem('auth_app_token');
    // this.obtenerDatos({ token: t });
    this.decodeToken(t);
    


  }


  // obtenerDatos(body: Peticion) {
  //   this.https.postRespuesta(body, "https://app.accionext.com/api/auth/rol.php").subscribe(
  //     info => {
  //       this.rol = info;
  //       console.log(this.rol);
  //     },
  //     err => {
  //       console.log(err);
  //     }
  //   );
  // }

  decodeToken(token){
   this.rol =  this.decode.getDecodedTokenRole(token);
  }


}
