<section class="page-section mt-1 pt-1" id="services">
  <div class="container-fluid">
    <div class="text-center">
      <!-- <h2 class="section-heading text-uppercase">¡Bienvenido a AutoSchool!</h2> -->
    </div>

    <!-- Content Row -->
    <div class="row mt-5">

      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card  py-2 btn bAzulCorporativo" [routerLink]="['/pages/estadisticasGenerales']">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 d-flex justify-content-start">
                <h6 class="textAzulCorporativo ">Servicios</h6>
              </div>
              <div class="col-auto">

                <i class="fas fa-chart-bar fa-inverse textAzulCorporativo display-4 m-1"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-primary py-2 btn bAzul" [routerLink]="['/pages/estadisticas/jornadas']">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 d-flex justify-content-start">
                <h6 class="text-primary ">Jornadas</h6>
              </div>
              <div class="col-auto">
                <i class="fas fa-business-time fa-inverse text-primary display-4 m-1"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-success py-2 btn bVerde" [routerLink]="['/pages/ingresos']">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 d-flex justify-content-start">
                <h6 class="text-success">Ingresos</h6>
              </div>
              <div class="col-auto">
                <i class="fas fa-euro-sign fa-inverse text-success display-4 m-1"></i>
                
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-warning py-2 btn bAmarillo" [routerLink]="['/pages/gastos']">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 d-flex justify-content-start">
                <h6 class="text-warning ">Gastos</h6>
              </div>
              <div class="col-auto">
                <i class="fas fa-cogs fa-inverse text-warning display-4 m-1"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
