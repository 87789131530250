
<nb-card accent="info" >
  <nb-card-header >
    <div class="row " >
      <div class="col-sm-12 mb-1">


        <div class="text-center d-flex justify-content-between">
          <div>
            <h5 class="section-heading">Fichar | Jornadas</h5>


          </div>

          <div>

            <button nbButton outline status="warning" data-toggle="modal" data-target="#modelIdInfo" class="btnAddClient mt-2" >
              <i class="fas fa-info-circle"></i>
            </button>

              <!-- Modal -->
              <div class="modal fade w-100" id="modelIdInfo" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
                <div class="modal-dialog w-100" role="document">
                  <div class="modal-content p-1">
                    <div class="modal-header">
                      <h5 class="modal-title text-dark font-weight-bold">Información</h5>
                        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button> -->
                    </div>
                    <div class="modal-body">
                      <h6 class="text-success text-left m-1">Fichar | Jornadas</h6>
                      <p class="text-dark text-left">Es importante fichar a tiempo y no olvidar fichar al salir </p>

                      <hr>

                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cerrar</button>

                    </div>
                  </div>
                </div>
              </div>


          </div>

        </div>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>

    <section class="mt-1 pt-1">
      <div class="container">
        <div class="row align-items-center">
          <!-- <div class="col mt-4 text-center">
            <h2>
              {{ fechaActual | async | date: "EEEE, d/MM/yy, H:mm:ss " | titlecase }}

            </h2>

          </div> -->

          <div class="col-12 mt-4 text-center d-flex flex-wrap justify-content-center">
            <h2>
              <!-- {{ fechaActual | async | date: "EEEE, d/MM/yy, H:mm:ss " | titlecase }} -->
              Estado actual |
            </h2>
            <h2 [ngClass]="{'text-primary': estadoActual == 'Trabajando', 'text-success' : estadoActual == 'No trabajando'}" > {{estadoActual}}</h2>
            
          </div>
        </div>

        <div class="row mt-5 row-wrap justify-content-center">
          <div class="col-xl-3 col-md-6 mb-4 ">
            <div class="card border-primary py-2 btn bAzul rounded-circle" data-toggle="modal" data-target="#modelIdEntrar" >
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2 d-flex justify-content-start">
                    <h6 class="text-primary">Entrar</h6>
                  </div>
                  <div class="col-auto">
                    <i
                      class="
                        fas
                        fa-business-time fa-inverse
                        text-primary
                        display-4
                        m-1
                      "
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <!-- ---------------------- modal ------------ -->
            <div class="modal fade w-100" id="modelIdEntrar" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
              <div class="modal-dialog w-100" role="document">
                <div class="modal-content p-1">
                  <div class="modal-header">
                    <h5 class="modal-title text-dark font-weight-bold">Fichar entrada</h5>
                      <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button> -->
                  </div>
                  <div class="modal-body">
                    <h4 class="text-primary m-1">Son las {{ fechaActual | async | date: " H:mm:ss " | titlecase }}</h4>
                    <p class="text-dark text-left">Comenzará tu jornada ahora... que tengas un buen día</p>


                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cancelar</button>
                    <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="fichar('Entrada')">Aceptar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div class="col-xl-3 col-md-6 mb-4 " >
            <div class="card  border-success py-2 btn bVerde rounded-circle "  data-toggle="modal" data-target="#modelIdSalir">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2 d-flex justify-content-start">
                    <h6 class="text-success">Salir</h6>
                  </div>
                  <div class="col-auto">
                    <i
                      class="
                        fas
                        fa-check-circle fa-inverse
                        text-success
                        display-4
                        m-1
                      "
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <!-- ---------------------- modal ------------ -->
            <div class="modal fade w-100" id="modelIdSalir" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
              <div class="modal-dialog w-100" role="document">
                <div class="modal-content p-1">
                  <div class="modal-header">
                    <h5 class="modal-title text-dark font-weight-bold">Fichar salida</h5>

                  </div>
                  <div class="modal-body">
                    <h4 class="text-success  m-1">Son las {{ fechaActual | async | date: " H:mm:ss " | titlecase }}</h4>

                    <p class="text-dark text-left">Descansa... ha sido muy productivo</p>



                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cancelar</button>
                    <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="fichar('Salida')">Aceptar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div class="row mt-4 mb-4">
          <div class="col">
            <h5>Resumen de jornada laboral</h5>
          </div>

          <div class="col-sm-12 mb-2 pl-0 pr-0" *ngFor="let fichado of datos | keyvalue">
            <nb-accordion>
              <nb-accordion-item>
                <nb-accordion-item-header>
                  {{ fichado.key | date: "EEEE, dd/MM/yyyy" | titlecase }}
                </nb-accordion-item-header>
                <nb-accordion-item-body>
                  <div class="row text-center">
                    <div class="col-md col-sm-12">
                      <h4>Resumen del día</h4>
                      <hr />

                      <div class="row mt-1 row-wrap justify-content-center">
                        <div class="col-12 mb-4"  *ngFor="let item of fichado.value">
                          <div class="card btn" [ngClass]="{'border-primary bAzul': item.accion == 'Entrada','border-success bVerde' : item.accion == 'Salida' }" >
                            <div class="card-body p-0 ">
                              <div class="row no-gutters align-items-center">
                                <div class="col mr-2 d-flex justify-content-start">
                                  <h6 [ngClass]="{'text-primary': item.accion == 'Entrada', 'text-success': item.accion == 'Salida'}" class="text-primary">{{item.accion}} - {{item.fecha | date:'shortTime'}}</h6>
                                </div>
                                <div class="col-auto">
                                 <ng-container *ngIf="item.accion == 'Entrada'; else salida">
                                    <i class=" fas fa-business-time fa-inverse text-primary display-4 m-1"></i>
                                 </ng-container>
                                 <ng-template #salida>
                                    <i class=" fas fa-check-circle fa-inverse text-success display-4 m-1"></i>
                                 </ng-template>



                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                </nb-accordion-item-body>
              </nb-accordion-item>
            </nb-accordion>
          </div>
        </div>
      </div>
    </section>

  </nb-card-body>
</nb-card>




