import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isFound'
})

export class isFoundPipe implements PipeTransform {
//Pipe personalizada que transforma el string dado en "No hay datos" si es undefined o está vacío. Si no está vacío, devuelve el string tal cual.
  transform(value: string, ...args: unknown[]): string {
    if(value == undefined || value == ""){
      return "No hay datos"
    }
    return value;
  }

}
