import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { ApiService } from 'src/app/services/conexionApi.service';
import { Peticion } from 'src/app/services/peticion';
import { Router, ActivatedRoute } from '@angular/router';
import { NbTokenService } from 'src/framework/auth/public_api';


@Component({
  selector: 'app-reservas',
  templateUrl: './reservas.component.html',
  styleUrls: ['./reservas.component.scss']
})
export class ReservasComponent implements OnInit {

  source: LocalDataSource;
  datos:any = {};

  settings = {
    actions: {
      columnTitle: 'Acciones',
      add: false,
      edit: true,
      delete: false,
      position: 'right',

    },
    add: {

      addButtonContent: '<i class="fas fa-plus" title="Añadir " ></i>',
      createButtonContent: '<i class="far fa-check-square" title="Añadir " ></i>',
      cancelButtonContent: '<i class="far fa-window-close" title="cancelar " ></i>'
    },
    edit: {
      editButtonContent: '<i class="fa fa-user-circle text-primary m-1" title="Ver cliente"></i>',
      saveButtonContent: '<i class="far fa-edit" title="actualizar " ></i>',
      cancelButtonContent: '<i class="far fa-window-close" title="cancelar " ></i>'
    },
    delete: {
      deleteButtonContent: '<i class="far fa-trash-alt" title="Eliminar" ></i>',
      confirmDelete: true
    },

    mode: 'external',

    pager: {

      display: true
    },

    columns:
    {

      nombre:{
        title: 'Nombre',

      },
      reserva:{
        title: 'Reserva',

      },
      origendestino:{
        title: 'Origen/Destino',

      },
      compania:{
        title: 'Compañia',
      },
      fechaida:{
        title: 'Fecha Ida',

      },
      fechavuelta:{
        title: 'Fecha Vuelta',

      },
      importe:{
        title: 'Importe',

      },
      tiporeserva:{
        title: 'Tipo Reserva',
      },


    }
  }


  constructor(private https: ApiService,  private t: NbTokenService, private cdr: ChangeDetectorRef,private router: Router, private activatedRoute: ActivatedRoute,) {


  }

  ngOnInit(): void {
    var t = localStorage.getItem('auth_app_token');
    this.obtenerDatos({ token: t });
    this.cdr.markForCheck();

    setTimeout(() => {
      this.obtenerDatos({ token: t });
      this.cdr.markForCheck();
    }, 5000);


  }

  ngAfterViewInit(): void {
      var t = localStorage.getItem('auth_app_token');
      this.obtenerDatos({ token: t });
      this.cdr.markForCheck();
  }


  obtenerDatos(body: Peticion) {
    this.https.postRespuesta(body, "https://app.accionext.com/api/viajes/viajes.php").subscribe(
      info => {
        this.datos = info;
        this.source = new LocalDataSource(this.datos);
        this.cdr.markForCheck();
      },
      err => {
        console.log(err);
      }
    );
  }


  edit(event){
    this.router.navigate(['./', event.index], {relativeTo: this.activatedRoute})
  }

  verCliente(id){
    this.router.navigate(['./', id], {relativeTo: this.activatedRoute})
  }



}

