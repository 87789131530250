<div class="row p-0 m-0" *ngIf="video!=null; else noVideo">
  <div class="col-md-12 col-lg-8 m-0 p-0">
      
        <video class="widthCard" #videoPlayer  class="videoPlayer w-100" [poster]="imgPoster" controls controlslist="nodownload">

        
          <!-- video files -->
          <source [src]="ruta" type="video/mp4" />

          <source [src]="ruta" type="video/webm" />
        
          
        </video>
        <h5 class="m-3">{{ nombreVideo }}</h5>
     
  </div>
  <div class="col-md-12 col-lg-4 p-0 m-0">
    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12">
        <nb-card class="bg-transparent" size="large">

          <nb-card-header class="border border-primary">
            <h5 class="text-center pb-2">Lista de videos</h5>
          </nb-card-header>
          <nb-card-body >
            <nb-list>
              <nb-list-item *ngFor="let video of videos" class="pb-0">
                <div class="row abrirVideo" (click)="go( video.tema, video.nombre, video.titulo)">
                  <div class="col-md-4 col-lg-5 col-4 " >
                    <img class="img-fluid" [src]="'/app/media/'+video.nombre+'.png'"  alt="download video" fallimg />
                  </div>
                  <div class="col-md-8 col-lg-7 col-8 d-flex justify-content-start align-items-center"  >
                    <p class="text-white" >{{ video.titulo }}</p>
                  </div>
                  <div class="border border-bottom border-primary d-block w-100 mt-4"></div>
                </div>
                
              </nb-list-item>
            </nb-list>
          </nb-card-body>
        </nb-card>
      </div>
    
    </div>
    
  </div>
</div>
<ng-template #noVideo>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-3 col-lg-2 m-0 p-2 " *ngFor="let video of videos">
       <div class="card bg-transparent h-100 border-0 mb-3"  (click)="go( video.tema, video.nombre, video.titulo)" >
        <img class="img-fluid card-img-top" [src]="'/app/media/'+video.nombre+'.png'"  alt="download video" fallimg/>
        <div class="card-body p-1">
          <p class="card-title text-center p-2 text-white">{{ video.titulo }}</p>
          
        </div>
      </div> 
      

    </div>
  </div>
  
</ng-template>


