import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
declare var JitsiMeetExternalAPI: any;

@Component({
    selector: 'app-meet',
    templateUrl: './meet.component.html',
    styleUrls: ['./meet.component.scss']
})
export class MeetComponent implements OnInit {

    // @ViewChild('jitsi-iframe') myVideo: any;

    domain: string = "meet.acciogest.com/";
    room: any;
    options: any;
    api: any;
    user: any;

    // For Custom Controls
    isAudioMuted = false;
    isVideoMuted = false;

    constructor(
        private router: Router
    ) { }

    ngOnInit(): void {
        this.room = 'Desarrollo'; // set your room name
        this.user = {
            name: 'AccioNext' // set your username
        }
    }

    ngAfterViewInit(): void {
        this.options = {
            roomName: this.room,
            //   width: 900,
            height: 400,
            configOverwrite: {
                disableDeepLinking: true,
                prejoinPageEnabled: false,
                disableLocalVideoFlip: true,
                doNotFlipLocalVideo: true,
                hideParticipantsStats: true,
                disableRemoteMute: true,
                disableRemoteControl: true,
                hideConferenceTimer: true,
                remoteVideoMenu: {
                    disableKick: true,
                    disableGrantModerator: true,
                },
                subject: 'Custom Title'
            },
            interfaceConfigOverwrite: {
                // overwrite interface properties
                FILM_STRIP_MAX_HEIGHT: '0.1',
                SHOW_CHROME_EXTENSION_BANNER: false,
                DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
                LANG_DETECTION: true,
                VIDEO_QUALITY_LABEL_DISABLED: true,
                CONNECTION_INDICATOR_DISABLED: true,
                TOOLBAR_BUTTONS: [ 'fullscreen']


            },
            parentNode: document.querySelector('#jitsi-iframe'),
            userInfo: {
                displayName: this.user.name
            }
        }

        this.api = new JitsiMeetExternalAPI(this.domain, this.options);

        this.api.addEventListeners({
            readyToClose: this.handleClose,
            participantLeft: this.handleParticipantLeft,
            participantJoined: this.handleParticipantJoined,
            videoConferenceJoined: this.handleVideoConferenceJoined,
            videoConferenceLeft: this.handleVideoConferenceLeft,
            audioMuteStatusChanged: this.handleMuteStatus,
            videoMuteStatusChanged: this.handleVideoStatus
        });
    }


    handleClose = () => {
        console.log("handleClose");
    }

    handleParticipantLeft = async (participant) => {
        console.log("handleParticipantLeft", participant); // { id: "2baa184e" }
        const data = await this.getParticipants();
    }

    handleParticipantJoined = async (participant) => {
        console.log("handleParticipantJoined", participant); // { id: "2baa184e", displayName: "Shanu Verma", formattedDisplayName: "Shanu Verma" }
        const data = await this.getParticipants();
    }

    handleVideoConferenceJoined = async (participant) => {
        console.log("handleVideoConferenceJoined", participant); // { roomName: "bwb-bfqi-vmh", id: "8c35a951", displayName: "Akash Verma", formattedDisplayName: "Akash Verma (me)"}
        const data = await this.getParticipants();
    }

    handleVideoConferenceLeft = () => {
        console.log("handleVideoConferenceLeft");
        this.router.navigate(['/thank-you']);
    }

    handleMuteStatus = (audio) => {
        console.log("handleMuteStatus", audio); // { muted: true }
    }

    handleVideoStatus = (video) => {
        console.log("handleVideoStatus", video); // { muted: true }
    }

    getParticipants() {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(this.api.getParticipantsInfo()); // get all participants
            }, 500)
        });
    }

    // custom events
    executeCommand(command: string) {

        this.api.executeCommand(command);;
        if (command == 'hangup') {
            this.router.navigate(['/thank-you']);
            return;
        }

        if (command == 'toggleAudio') {
            this.isAudioMuted = !this.isAudioMuted;
        }

        if (command == 'toggleVideo') {
            this.isVideoMuted = !this.isVideoMuted;
        }
    }
}
