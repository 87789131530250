


<section class="page-section pt-1" id="services">
    <div class="container-fluid">
      <div class="text-center">
        <!-- <h2 class="section-heading text-uppercase">¡Bienvenido a accioges!</h2> -->
      </div>
  
      <!-- Content Row -->
      <div class="row mt-1 mb-3">
  
        <div class="col-xl-3 col-md-6 mb-2 p-1">
          <div class="card border-info py-1 btn bAzul" [routerLink]="['/pages/ingresar']">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2 d-flex justify-content-start">
                  <h6 class="text-info">Nueva venta</h6>
                </div>
                <div class="col-auto">
                  <!-- <i class="fas fa-hand-holding-usd fa-inverse text-info h1 m-1"></i> -->
  
                  <i class="fas fa-cash-register fa-inverse text-info h1 m-1"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
  
  
  
        <div class="col-xl-3 col-md-6 mb-2 p-1">
          <div class="card border-info py-1 btn bAzul" [routerLink]="['/pages/caja/consultar']">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2 d-flex justify-content-start">
                  <h6 class="text-info">Consultar</h6>
                </div>
                <div class="col-auto">
                  <!-- <i class="fas fa-business-time fa-inverse text-info h1 m-1"></i> -->
                  <i class="iconoir-data-transfer-check lead text-info h1 m-1"> </i>
                </div>
              </div>
            </div>
          </div>
        </div>
  
  
  
        <div class="col-xl-3 col-md-6 mb-2 p-1">
          <div class="card border-info py-1 btn bAzul" [routerLink]="['/pages/caja/ingresar']">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2 d-flex justify-content-start">
                  <h6 class="text-info">Ingresar</h6>
                </div>
                <div class="col-auto">
                  <!-- <i class="fas fa-hand-holding-usd fa-inverse text-info h1 m-1"></i> -->
  
                  <!-- <i class="fas fa-cash-register fa-inverse text-info h1 m-1"></i> -->
                  <i class="iconoir-download-square-outline lead text-info h1 m-1"> </i>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="col-xl-3 col-md-6 mb-2 p-1">
          <div class="card border-info py-1 btn bAzul" [routerLink]="['/pages/caja/retirar']">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2 d-flex justify-content-start">
                  <h6 class="text-info">Retirar</h6>
                </div>
                <div class="col-auto">
                  <!-- <i class="fas fa-business-time fa-inverse text-info h1 m-1"></i> -->
                  <i class="iconoir-upload-square-outline lead text-info h1 m-1"> </i>
                </div>
              </div>
            </div>
          </div>
        </div>
  
  
      </div>
    </div>
  </section>
  
  
  
  
  
  
  <section class="page-section mt-1 pt-1" id="services">
    <div class="container-fluid">
      <div class="text-center">
        <!-- <h2 class="section-heading text-uppercase">¡Bienvenido a accioges!</h2> -->
      </div>
  
  
  
  
      <!-- Content Row -->
      <!-- <div class="row mt-1 mb-3">
        <div class="col-md-12 text-left">
  
  
  
          <nb-card accent="info">
  
            <nb-card-header>
              <h5 class="text-center">Ultimos movimientos</h5>
            </nb-card-header>
  
  
            <nb-card-body class="">
  
  
  
              <div class="d-flex justify-content-between">
                <p class="">Hoy se ha ingresado </p>
                <h6 class="">{{datosIngresos.hoy ? datosIngresos.hoy : '0'}} €</h6>
              </div>
  
              <div class="d-flex justify-content-between">
                <p class="">Ayer se ha ingresado </p>
                <h6 class="">{{datosIngresos.ayer ? datosIngresos.ayer : '0'}} €</h6>
              </div>
  
  
              <div class="d-flex justify-content-between">
                <p class="">Esta semana se ha ingresado</p>
                <h6 class="">{{datosIngresos.semana ? datosIngresos.semana : '0'}}
                  €</h6>
  
              </div>
  
  
  
            </nb-card-body>
  
            <nb-card-footer>
  
              <div class="d-flex justify-content-between">
                <p class="">La semana pasada se ha ingresado</p>
                <h6 class="">{{datosIngresos.semanaPasada ?
                  datosIngresos.semanaPasada : '0'}} €</h6>
                La fecha se puede poner aqui con unos cuantos espacios 
              </div>
  
            </nb-card-footer>
  
  
          </nb-card>
  
        </div>
  
  
      </div> -->
    </div>
  </section>
  