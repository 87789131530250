import { Component, OnInit, OnDestroy, ElementRef, SimpleChanges } from '@angular/core';
import { NbWindowService } from '@nebular/theme';
import { ActivatedRoute, Router,NavigationEnd  } from '@angular/router';
import * as $ from "jquery";

import { ApiService } from 'src/app/services/conexionApi.service';
import { Peticion } from 'src/app/services/peticion';
@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit{

  ruta="";
  imgPoster="";
  videos = [];

  video ;
  nombreVideo="";
  rutaVideo="";
  t = localStorage.getItem('auth_app_token');
  tema= 0;
  navigationSubscription;
  imagen: any;
  constructor(private https:ApiService,  private windowService: NbWindowService, private route: ActivatedRoute, private router: Router, private elRef: ElementRef ) { 
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      
      if (e instanceof NavigationEnd) {      
        this.initialiseInvites();
      }
    });
  }


  recargar(){
    if (this.video!=null) {
      return true;
    } 
  }


  initialiseInvites() { 
    this.video = this.route.snapshot.paramMap.get('video');
    if(this.video != null && this.video.indexOf("-") != -1){
      this.rutaVideo = this.video.substr(0, this.video.indexOf("-"));
      this.nombreVideo =this.video.substr(this.video.indexOf("-")+1);
    }
    this.ruta = "/app/media/"+this.rutaVideo+".mp4";
    this.imgPoster = "/app/media/"+this.rutaVideo+".png";
    
   if (this.video!=null) {
       $(document).ready(function () {
        const player = document.getElementsByTagName('video')[0];  
        player.load();

      });
   }
  
    
}

  

  postVideo(body: Peticion) {
    this.https.postRespuesta(body,"https://autoschool.es/api/temario/video.php").subscribe(
      info => {
     this.videos = info;   
      
      },
      err => {}
    );
  }
 

ngOnInit(): void {
    var self = this;    
    this.postVideo({});
   
  }
  
  ngOnDestroy() {
    if (this.navigationSubscription) {  
       this.navigationSubscription.unsubscribe();
    }
  }
  postEnviarDatosVideo(body: Peticion) {
    this.https.postRespuesta(body,"https://autoschool.es/api/estadisticas/datosLecturaVideo.php").subscribe(
      info => {
      },
      err => {}
    );
  }

  go( tema, nombre, titulo) {
    this.rutaVideo = nombre;   
    this.tema = tema;
    titulo = nombre+"-"+titulo;
    this.postEnviarDatosVideo({token:this.t, accion:'video', tema: this.tema});
    this.router.navigate(['/pages/temario/videos',titulo]); 
  }
}
