import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';


import { map, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/conexionApi.service';
import { NbAuthService, NbAuthJWTToken } from 'src/framework/auth/public_api';
import { Peticion } from 'src/app/services/peticion';
import { FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';

@Component({
  selector: 'app-add-domiciliacion',
  templateUrl: './add-domiciliacion.component.html',
  styleUrls: ['./add-domiciliacion.component.scss']
})
export class AddDomiciliacionComponent implements OnInit {


  inputDNI: string = "";
  inputNombre: string = '';
  inputApellidos: string = '';
  inputTelefono: string = '';
  inputEmail: string = '';


 constructor(private authService: NbAuthService,private _location: Location, private router: Router,private cdr: ChangeDetectorRef, private http: ApiService, private cdRef: ChangeDetectorRef, private formBuilder: FormBuilder) {



   }

  ngOnInit() {
    setTimeout(() => {

      this.cdr.markForCheck ();
    }, 500);
  }


 ingresarPost(body: Peticion) {
  this.http.postRespuesta(body, "https://app.accionext.com/api/clientes/addcliente.php").subscribe(
    data => {
      //this.datos = data;
      // this.user['data']['fullname'] = this.datos['fullname'];
      // localStorage.setItem('auth_app_token', JSON.stringify({'name':'nb:auth:jwt:token','ownerStrategyName':'email','createdAt':1589115670000,'value': this.datos['token']}));

    },
    err => { }
  );



}

ingresar() {
   //alert(this.inputServicio + " -- " + this.inputImporte + " -- "+ this.inputCliente);
  var t = localStorage.getItem('auth_app_token');
  this.ingresarPost({ dni:this.inputDNI,nombre:this.inputNombre,apellidos:this.inputApellidos,telefono:this.inputTelefono,email:this.inputEmail, token: t });

  this.goBack();
  // alert(this.inputDNI + " -- " + this.inputNombre + " -- "+ this.inputApellidos + " -- "+ this.inputTelefono + " -- "+ this.inputEmail);
}

goBack(){

  this.router.navigate(['pages/clientes']);
}


}
