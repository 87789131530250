import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tarificar',
  templateUrl: './tarificar.component.html',
  styleUrls: ['./tarificar.component.scss']
})
export class TarificarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
