<nb-card>
    <nb-card-body>

        <input nbInput [nbFilterInput]="dataSource" id="search" class="search-input" placeholder="Buscar...">

        <table [nbTreeGrid]="dataSource" [nbSort]="dataSource" (sort)="updateSort($event)" class="mt-2">

            <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="allColumns"></tr>
            <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: allColumns"></tr>

            <ng-container class="text-center" [nbTreeGridColumnDef]="customColumn">
                <th  class="text-center"  nbTreeGridHeaderCell [nbSortHeader]="getSortDirection(customColumn)" *nbTreeGridHeaderCellDef>
                    {{customColumn}}
                </th>

                <td nbTreeGridCell *nbTreeGridCellDef="let row">

                    <span *ngIf="row.data.Video==null; else link">{{row.data[customColumn]}}</span>
                    <ng-template #link>                      
                        <a role="button" class="link text-primary" data-toggle="modal" href="#portfolioModal1" (mouseenter)="establecerRuta(row.data[customColumn])">
                            <nb-icon icon="book-open-outline" [options]="{ animation: { type: 'flip' } }" status="basic"></nb-icon> {{row.data[customColumn]}}</a>
                    </ng-template>

                </td>

            </ng-container>

            <ng-container *ngFor="let column of defaultColumns; let index = index" [nbTreeGridColumnDef]="column" [showOn]="getShowOn(index)">
                <th  class="text-center"  nbTreeGridHeaderCell [nbSortHeader]="getSortDirection(column)" *nbTreeGridHeaderCellDef>
                    {{column}}
                </th>
                <td  class="text-center"  nbTreeGridCell *nbTreeGridCellDef="let row">
                    <a *ngIf="column!='Video'; else link" class="link text-primary" [routerLink]="['/pages/test/temas']" >
                        <nb-icon *ngIf="row.data.Test!=null" icon="checkmark-square-outline"  [options]="{ animation: { type: 'flip' } }" status="basic"></nb-icon>{{row.data[column]}}</a>
                    <ng-template #link>
                        <a class="link text-primary" (click)="establecerRutaVideo(row.data.Nombre)">
                            <nb-icon *ngIf="row.data.Video!=null"  icon="film-outline" [options]="{ animation: { type: 'flip' } }" status="basic"></nb-icon> {{row.data[column]}}</a>
                    </ng-template>
                </td>
            </ng-container>

        </table>

    </nb-card-body>
</nb-card>

<div class="portfolio-modal modal fade" id="portfolioModal1" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12 p-0">
                        <div class="modal-body p-0">
                         
                            <button data-dismiss="modal" type="button"><nb-icon icon="close-outline" data-eva-animation="zoom" status="danger"></nb-icon></button>
                           
                            <ngx-extended-pdf-viewer 
                                [src]="urlPdf" 
                                useBrowserLocale="true" 
                                height="95vh"  
                                [showDownloadButton]="false" 
                                [showRotateButton]="false" 
                                [showOpenFileButton]="false" 
                                [showZoomButtons]="false" 
                                [zoom]="'page-width'" 
                                [showBorders]="false" 
                                [showPrintButton]="false" 
                                [backgroundColor]=""
                                [enablePrint]="false" 
                                [showBookmarkButton]="false" 
                                [showSecondaryToolbarButton]="false" 
                                [showPagingButtons]="false" 
                                [sidebarVisible]="false" 
                                [showSidebarOnLoad]=false>
                            </ngx-extended-pdf-viewer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>