import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';

import { Router } from '@angular/router';
import { Key } from 'protractor';
import { ApiService } from 'src/app/services/conexionApi.service';

import { Peticion } from 'src/app/services/peticion';
import { NbAuthService, NbAuthJWTToken } from 'src/framework/auth/public_api';

@Component({
  selector: 'app-estadisticasJornadas',
  templateUrl: './estadisticasJornadas.component.html',
  styleUrls: ['./estadisticasJornadas.component.scss']
})
export class EstadisticasJornadasComponent implements OnInit {

  datos = [] ;
  elementos = [];

  constructor(private authService: NbAuthService,private router: Router, private http: ApiService, private cdRef: ChangeDetectorRef, ) { }

  ngOnInit() {
    var t = localStorage.getItem('auth_app_token');
    this.obtenerDatos({ token: t, fechainicio: "2022-05-10", fechafin: "2022-05-16" });//año-mes.dia
  }

  
  obtenerDatos(body: Peticion) {
    this.http.postRespuesta(body, "https://app.accionext.com/api/estadisticas/estadisticasJornadas.php").subscribe(
      info => {
        this.datos = info;
        console.log(this.datos);
        
      },
      err => {
        console.log(err);
      }
    );
  }

}
