
<nb-card accent="primary">
  <nb-card-body>
    <div class="row">
      <div class="col-sm-12 mb-4">


        <div class="text-center d-flex justify-content-between">
          <div>
            <h3 class="section-heading">Nuevo cliente</h3>

          </div>
          <div>

            <!-- <button nbButton outline status="warning" data-toggle="modal" data-target="#modelId" class="btnAddClient mt-2" >
              <i class="fas fa-info-circle"></i>
            </button> -->

            
      

          </div>

        </div>
      </div>
    </div>

    <form (ngSubmit)="ingresar()" novalidate>
      <div class="row">
        <div class="col-md-12 justify-content-around">
          <input type="text" class="mb-3 mw-100 w-100" placeholder="DNI" [(ngModel)]="inputDNI" name="dni" nbInput
            fullWidth />
            <input type="text" class="mb-3 mw-100 w-100" placeholder="Nombre" [(ngModel)]="inputNombre" name="nombre" nbInput
            fullWidth />
            <input type="text" class="mb-3 mw-100 w-100" placeholder="Apellido" [(ngModel)]="inputApellidos" name="apellidos" nbInput
            fullWidth />
            <input type="text" class="mb-3 mw-100 w-100" placeholder="Nº Teléfono" [(ngModel)]="inputTelefono" name="telefono" nbInput
            fullWidth />
            <input type="text" class="mb-3 mw-100 w-100" placeholder="Email" [(ngModel)]="inputEmail" name="email" nbInput
            fullWidth />
    
            <!-- <p>- {{inputEmail}}</p> -->
    
        </div>
    
      </div>
    
      <div class="row">
        <div class="col-12">
          <!-- <button nbButton fullWidth status="primary">Ingresar</button> -->
          <button type="submit" nbButton fullWidth  outline status="primary" class="text-primary w-100">
            Crear
          </button>

          <!-- <ngx-toast-notifier></ngx-toast-notifier> -->
    
    
        </div>
        
      </div>
    
    
    
    
    </form>

  </nb-card-body>
</nb-card>
