<section class="mt-1 pt-1">

  <app-autocomplete-clientes class="mb-3 mw-100 w-100" fullWidth>
  </app-autocomplete-clientes>




  <div class="row" *ngFor="let item of datosTotales">
    <div class="col-md-6 text-left ">

      <nb-card accent="info">


        <nb-card-header>
          <h5 class="text-center ">Datos</h5>
        </nb-card-header>


        <nb-card-body class="">

          <div class="d-flex justify-content-between">
            <p class=" ">Hoy has ingresado </p>
            <h6 class=" ">{{item.sumaTotalHoy}} €</h6>

          </div>

          <div class="d-flex justify-content-between">
            <p class=" ">Ayer has ingresado </p>
            <h6 class=" ">{{item.sumaTotalAyer}} €</h6>

          </div>


          <div class="d-flex justify-content-between">
            <p class=" ">Esta semana has ingresado</p>
            <h6 class=" ">{{item.sumaTotalSemana}} €</h6>

          </div>



        </nb-card-body>

        <nb-card-footer>

          <div class="d-flex justify-content-between">
            <p class=" ">La semana pasada has ingresado</p>
            <h6 class=" ">{{item.sumaTotalSemanaPasada}} €</h6>

          </div>

        </nb-card-footer>








      </nb-card>

    </div>
    <div class="col-md-6">
      <nb-card accent="info" class="">

        <nb-card-header>
          <h5 class="text-center">Este mes</h5>
        </nb-card-header>


        <nb-card-body class=" ">
          <nb-progress-bar [value]="porcentaje" [displayValue]="true" [status]="status" size="giant"></nb-progress-bar>
          <h6 class="text-center m-4">{{nombre}}, has ingresado {{item.sumaTotalMes}} €</h6>
        </nb-card-body>

        <nb-card-footer>

          <div class="d-flex justify-content-between">
            <p class=" ">El mes pasado has ingresado </p>
            <h6 class=" ">{{item.sumaTotalMesPasado}} €</h6>

          </div>

        </nb-card-footer>








      </nb-card>

    </div>

  </div>

  <!-- <div class="row" *ngFor="let item of datosTotales">
    <div class="col-md-6 text-left ">

      <nb-card accent="info">


        <nb-card-header>
          <h5 class="text-center ">La semana pasada</h5>
        </nb-card-header>


        <nb-card-body class=" p-0 text-white">

          <ngx-charts-bar-vertical
            class=" text-white"
            [scheme]="colorScheme"
            [results]="single"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            (select)="onSelect($event)">
          </ngx-charts-bar-vertical>



        </nb-card-body>

        <nb-card-footer>

          <div class="d-flex justify-content-between">
            <p class=" ">La semana pasada has ingresado</p>
            <h6 class=" ">{{item.sumaTotalSemana}} €</h6>

          </div>

        </nb-card-footer>








      </nb-card>

    </div>
    <div class="col-md-6">
      <nb-card accent="info" class="">

        <nb-card-header>
          <h5 class="text-center">Esta semana</h5>
        </nb-card-header>


        <nb-card-body class=" p-0">
          <ngx-charts-bar-vertical

          [scheme]="colorScheme"
          [results]="single"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="showLegend"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
          (select)="onSelect($event)">
        </ngx-charts-bar-vertical>
        </nb-card-body>

        <nb-card-footer>

          <div class="d-flex justify-content-between">
            <p class=" ">El mes pasado has ingresado </p>
            <h6 class=" ">{{item.sumaTotalMesPasado}} €</h6>

          </div>

        </nb-card-footer>








      </nb-card>

    </div>

  </div> -->

  <!-- <div class="row" *ngFor="let item of datosTotales">
    <div class="col-12 ">

      <nb-card accent="info">


        <nb-card-header>
          <h5 class="text-center ">Evulución del mes</h5>
        </nb-card-header>


        <nb-card-body class=" p-0 text-white">

          <ngx-charts-bar-vertical
            class=" text-white"

            [scheme]="colorScheme"
            [results]="singleMes"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            (select)="onSelect($event)">
          </ngx-charts-bar-vertical>



        </nb-card-body>

        <nb-card-footer>

          <div class="d-flex justify-content-between">
            <p class=" ">El total ingresado en este mes es</p>
            <h6 class=" ">{{totalMes}} €</h6>

          </div>

        </nb-card-footer>








      </nb-card>

    </div>


  </div> -->


  <!-- <nb-card accent="info">
    <nb-card-body class="p-0"> -->
      <div class="row">
        <div class="col-md-12">
          <nb-card accent="info" class="">
  
            <nb-card-header>
              <h5 class="text-center">Resumen de los ultimos ingresos</h5>
            </nb-card-header>
  
  
            <nb-card-body class="p-0">
  
              <div class="col-sm-12 mb-2" *ngFor="let i of datosTotales">
                <nb-accordion>
                  <nb-accordion-item>
                    <nb-accordion-item-header class="d-flex justify-content-between pr-5">
                      <div>
                        {{ i.usuario }}
                      </div>
                      <div>
                        {{i.sumaTotalMes}}€
                      </div>
  
                    </nb-accordion-item-header>
                    <nb-accordion-item-body>
                      <div class="row text-center">
                        <div class="col-md col-sm-12">
                          <ng-container *ngFor="let item of datos">
  
                            <nb-card class="fondo m-1 mr-0 ml-0" *ngIf="i.usuario==item.usuario">
                              <nb-card-body class="text-center">
  
                                <div class="row no-gutters flex-column">
                                  <div class="d-flex justify-content-between">
                                    <p class="mb-0"> {{item.nombre}}</p>
                                    {{item.cantidad}}€
                                  </div>
                                  <div class="d-flex justify-content-betweenalign-items-center">
                                    <p class="mb-0" *ngIf="item.cliente!=sinespecificar; else elseTemplate;"></p>
                                    <ng-template #elseTemplate>
                                      <p class="mb-0">{{item.fullname}}</p>
                                    </ng-template>
                                    <p class="mb-0">{{item.fecha}}</p>
                                  </div>
                                </div>
  
                              </nb-card-body>
                            </nb-card>
  
                          </ng-container>
  
                        </div>
                      </div>
                    </nb-accordion-item-body>
                  </nb-accordion-item>
                </nb-accordion>
              </div>
  
            </nb-card-body>

  
          </nb-card>
  
        </div>
  
      </div>
  
      <!-- <div class="row  d-flex justify-content-center">



        <table>
          <thead>
            <tr>
              <th>Servicio</th>
              <th>Cliente</th>
              <th>Fecha</th>
              <th>Cantidad</th>

            </tr>
          </thead>
          <tbody class="text-left">
            <tr *ngFor="let item of datos">
              <td>{{item.nombre}}</td>
              <td>{{item.fullname}}</td>
              <td>{{item.fecha}}</td>
              <td>{{item.cantidad}} €</td>

            </tr>
             <tr>
                            <td>Reparación móvil</td>
                            <td>{{da}}</td>
                        </tr> -->
            <!-- <tr>
                            <td>Gestión empadronamiento</td>
                            <td>10€</td>
                        </tr>
                        <tr>
                            <td>Pagina web</td>
                            <td>1200€</td>
                        </tr> 

          </tbody>
        </table>

      </div> -->


    <!-- </nb-card-body>
  </nb-card> -->


</section>
