import { animate } from '@angular/animations';
import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [

  {
    title: 'Inicio',
    // icon: 'home-outline',
    icon: { icon: 'home-outline', pack: 'eva' },
    link: '/pages/inicio',
  },
  {
    title: 'Fichar',
    // icon: 'checkmark-square-outline',
    icon: { icon: 'checkmark-square-outline', pack: 'eva' },
    link: '/pages/fichar',
  },
  {
    title: 'Ingresar',
    // icon: 'log-in-outline',
    icon: { icon: 'log-in-outline', pack: 'eva' },
    link: '/pages/ingresar',

  },
  {
    title: 'Servicios',
    // icon: 'settings-2-outline',
    icon: { icon: 'settings-2-outline', pack: 'eva' },
    link: '/pages/servicios',

    children: [
      {
        title: 'Todos',
        // icon: 'plus-circle-outline',
        icon: { icon: 'settings-2-outline', pack: 'eva' },
        link: '/pages/servicios',
      },
      {
        title: 'Nuevo',
        // icon: 'plus-circle-outline',
        icon: { icon: 'plus-circle-outline', pack: 'eva' },
        link: '/pages/addServicio',
      },

    ]

  },
  {
    title: 'Clientes',
    // icon: 'people-outline',
    icon: { icon: 'people-outline', pack: 'eva' },
    link: '/pages/clientes',

    children: [
      {
        title: 'todos',
        // icon: 'person-add-outline',
        icon: { icon: 'people-outline', pack: 'eva' },
        link: '/pages/clientes',
      },
      {
        title: 'Nuevo',
        // icon: 'person-add-outline',
        icon: { icon: 'person-add-outline', pack: 'eva' },
        link: '/pages/addClient',
      },

    ]

  },
  // {
  //   title: 'Sugerencias',
  //   icon: 'award-outline',
  //   link: '/pages/clientes',

  // },

  // {
  //   title: 'Formación',
  //   icon: 'book-outline',
  //   link: '/pages/temario',
  //   children: [
  //     {
  //       title: 'Lectura',
  //       icon: 'folder-outline',
  //       link: '/pages/temario/texto',
  //     },
  //     {
  //       title: 'Videos',
  //       icon: 'video-outline',
  //       link: '/pages/temario/videos',
  //     },
  //   ]
  // },
  // {
  //   title: 'Estadísticas',
  //   icon: 'trending-up-outline',
  //   link: '/pages/estadisticas',
  //   children: [
  //     {
  //       title: 'Ingresos',
  //       icon: 'log-in-outline',
  //       link: '/pages/ingresos',
  //     },
  //     {
  //       title: 'Gastos',
  //       icon: 'log-out-outline',
  //       link: '/pages/gastos',
  //     },
  //   ]
  // },


  {
    title: 'Gestor de tareas',
    // icon: 'list-outline',
    icon: { icon: 'list-outline', pack: 'eva' },
    link: '/pages/calendario',


  },
  {
    title: 'Calendario',
    // icon: 'calendar-outline',
    icon: { icon: 'calendar-outline', pack: 'eva' },
    link: '/pages/calendario',


  }

];
