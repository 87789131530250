import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/conexionApi.service';
import { Peticion } from 'src/app/services/peticion';

@Component({
  selector: 'app-estadisticasGenerales',
  templateUrl: './estadisticasGenerales.component.html',
  styleUrls: ['./estadisticasGenerales.component.scss']
})
export class EstadisticasGeneralesComponent implements OnInit {

  servicioMasVendidoAno = "";
  servicioMasIngresosAno = "";
  servicioMasVendidoMes = "";
  servicioMasIngresosMes = "";
  diezServiciosMasVendidosAno = [];
  serviciosMasIngresosCadaMes = [];
  serviciosMasVentasCadaMes = [];

  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  showDataLabel:boolean = true;
  legendPosition: string = 'right';
  view: any[];
  colorScheme = {
    domain: ['#5564eb', '#4c2882', '#094293', '#333c87', '#bc8e47', '#00825a', '#4040ff', '#009975']
  };

  //other options
  showXAxis = true;
  showYAxis = true;
  showXAxisLabel = false;
  xAxisLabel = 'Meses';
  showYAxisLabel = false;
  yAxisLabel = 'Servicios';
  legend = true;
  view2: any[];


  constructor(private http: ApiService,private cdr: ChangeDetectorRef ) {
    this.view = [innerWidth * 0.92, 600];
    this.view2 = [innerWidth * 0.45, 500];
    this.cdr.markForCheck ();
    Object.assign(this.diezServiciosMasVendidosAno);
    if (innerWidth < 578) {
      this.showLegend = false;
    }
   }

  ngOnInit() {
    var t = localStorage.getItem('auth_app_token');
    this.obtenerIngresosVentas({token:t});

    this.cdr.markForCheck ();
  }

  obtenerIngresosVentas(body: Peticion) {
    this.http.postRespuesta(body, "https://app.accionext.com/api/servicios/estadisticasServicios.php").subscribe(
      info => {
        //variables texto
        this.servicioMasIngresosAno = info['serviciomasingresosano'];
        this.servicioMasVendidoAno = info['serviciomassolicitadoano'];
        this.servicioMasIngresosMes = info['serviciomasingresosmes'];
        this.servicioMasVendidoMes = info['serviciomassolicitadomes'];
        //arrays
        this.diezServiciosMasVendidosAno = info['diezmasvendidos'];
        this.serviciosMasIngresosCadaMes = info['serviciosmasingresospormes'];
        this.serviciosMasVentasCadaMes = info['serviciosmasvendidospormes'];
        this.cdr.markForCheck();
        console.log(this.serviciosMasVentasCadaMes);
      },
      err => {
        console.log(err);
      }
    );
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onSelect2(data): void {
    //console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }
  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
}

