<button [disabled]="disabled"
        [ngClass]="selectButtonClasses"
        (blur)="trySetTouched()"
        (keydown.arrowDown)="show()"
        (keydown.arrowUp)="show()"
        class="select-button"
        type="button"
        #selectButton>

    <ng-container *ngIf="selectionModel.length; else placeholderTemplate">
      <ng-container *ngIf="customLabel; else defaultSelectionTemplate">
        <ng-content select="nb-select-label"></ng-content>
      </ng-container>

      <ng-template #defaultSelectionTemplate>{{ selectionView }}</ng-template>
    </ng-container>

    <ng-template #placeholderTemplate>{{ placeholder }}</ng-template>

    <nb-icon icon="chevron-down-outline" pack="nebular-essentials" (click)="disabled && $event.stopPropagation()" aria-hidden="true">
    </nb-icon>
</button>

<nb-option-list *nbPortal [size]="size" [position]="overlayPosition" [style.width.px]="hostWidth">
  <ng-content select="nb-option, nb-option-group"></ng-content>
</nb-option-list>
