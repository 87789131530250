import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApiService } from 'src/app/services/conexionApi.service';
import { NbAuthService, NbAuthJWTToken } from 'src/framework/auth/public_api';
import { Peticion } from 'src/app/services/peticion';
import { DatePipe, Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-tomaDatosSeguros',
  templateUrl: './tomaDatosSeguros.component.html',
  styleUrls: ['./tomaDatosSeguros.component.scss']
})
export class TomaDatosSegurosComponent implements OnInit {

  firstForm: FormGroup;
  secondForm: FormGroup;
  thirdForm: FormGroup;

  inputDNI: string = "";
  inputNombre: string = '';
  inputApellidos: string = '';
  inputTelefono: string = '';
  inputEmail: string = '';
  idCliente: number = 0;
  datos: any;
  public subscriber: Subscription;

  inputNumPoliza: string = "";
  inputEstadoActual:string = "";
  inputCompania:string = "";
  inputFraccionamiento:number= 1;
  inputFechaInicio:Date;
  inputFechaFin:Date;
  inputPrima:number=0;

  //auto
  inputMatricula:string = "";
  //casa
  inputDireccion:string = "";
  inputTipo:string = "";
  inputMetros:number = 0;
  //salud
  inputTipoSalud:string = "";

  constructor(private fb: FormBuilder, private authService: NbAuthService,private _location: Location, private router: Router,private cdr: ChangeDetectorRef, private http: ApiService, private cdRef: ChangeDetectorRef, private formBuilder: FormBuilder,private route: ActivatedRoute,public datepipe: DatePipe) {
  }

  ngOnInit() {
    this.subscriber = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event) => {
      //  console.log('The URL changed to: ' + event['url'])
      //  alert("ha cambiado");
      var t = localStorage.getItem('auth_app_token');
      this.obtenerDatos({ token: t, id_cliente: this.route.snapshot.url.toString() });
      //  this.completarDatos();
    });
    var t = localStorage.getItem('auth_app_token');
      this.obtenerDatos({ token: t, id_cliente: this.route.snapshot.url.toString() });
  }

  ingresarPost(body: Peticion) {
    this.http.postRespuesta(body, "https://app.accionext.com/api/servicios/addservicio.php").subscribe(
      data => {
        //this.datos = data;
        // this.user['data']['fullname'] = this.datos['fullname'];
        // localStorage.setItem('auth_app_token', JSON.stringify({'name':'nb:auth:jwt:token','ownerStrategyName':'email','createdAt':1589115670000,'value': this.datos['token']}));

      },
      err => { }
    );

  }

  ingresar() {
    //alert(this.inputServicio + " -- " + this.inputImporte + " -- "+ this.inputCliente);
   var t = localStorage.getItem('auth_app_token');
   this.ingresarPost({ dni:this.inputDNI,nombre:this.inputNombre,apellidos:this.inputApellidos,telefono:this.inputTelefono,email:this.inputEmail, token: t });

  //  this.goBack();
   // alert(this.inputDNI + " -- " + this.inputNombre + " -- "+ this.inputApellidos + " -- "+ this.inputTelefono + " -- "+ this.inputEmail);
  }

  guardarInfo() {
    var t = localStorage.getItem('auth_app_token');
    if (this.idCliente == 0) {
      //añadir cliente primero para sacar su id y establecer idCliente
      var body1 = {token: t,dni: this.inputDNI, nombre: this.inputNombre, apellidos: this.inputApellidos, telefono:this.inputTelefono, email: this.inputTelefono};
      this.http.postRespuesta(body1, "https://app.accionext.com/api/clientes/addcliente.php").subscribe(
        info => {
          this.idCliente = info.id; //falta hacer esto en la api
        },
        err => {
          console.log(err);
        }
      );
      console.log("No existe mi id");
    } 
    

    // var fechainicio = this.datepipe.transform(this.selected.startDate, 'yyyy-MM-dd');
    // var fechafin = this.datepipe.transform(this.selected.endDate, 'yyyy-MM-dd');
    var tipoSeguro = 'coche';
    if(this.inputMatricula == "" && this.inputTipoSalud == "")
    {
      tipoSeguro = 'hogar';
    }else if(this.inputTipoSalud != "")
    {
      tipoSeguro = 'salud';
    }
    var frac = "Anual";
    switch(this.inputFraccionamiento)
    {
      case 2:
        frac = "Semestral";
      break;
      case 3:
        frac= "Trimestral";
      break;

    }
    var fechainicio = this.datepipe.transform(this.inputFechaInicio, 'yyyy-MM-dd');
    var fechafin = this.datepipe.transform(this.inputFechaFin, 'yyyy-MM-dd');

    var body = {token:t,idcliente:this.idCliente,numpoliza:this.inputNumPoliza,estadoactual:this.inputEstadoActual,compania:this.inputCompania,fraccionamiento:frac,fechainicio:fechainicio,fechafin:fechafin,primatotal:this.inputPrima,tipo:tipoSeguro,matricula:this.inputMatricula,direccion:this.inputDireccion,metros:this.inputMetros,tipohogar:this.inputTipo,tiposalud:this.inputTipoSalud};

   // var body = {token: t,idreserva: this.inputIdReserva, origen: this.inputOrigen, destino: this.inputDestino, compania:this.inputCompania, fechaIda: fechainicio, fechaVuelta:fechafin,importe:this.inputImporteTotal, tiporeserva: this.inputTipoReserva, cantidad:this.inputNumeroIntegrantes,idcliente:this.idCliente};
    this.http.postRespuesta(body, "https://app.accionext.com/api/seguros/addpoliza.php").subscribe(
      info => {
       console.log(info);
      },
      err => {
        console.log(err);
      }
    );

    this.router.navigate(['/pages/seguros/polizas']);
  }

  obtenerDatos(body: Peticion) {
    this.http.postRespuesta(body, "https://app.accionext.com/api/clientes/cliente.php").subscribe(
      info => {
        this.datos = info;
        this.idCliente = this.datos[0].id;
        this.inputDNI = this.datos[0].dni;
        this.inputNombre = this.datos[0].nombre;
        this.inputApellidos = this.datos[0].apellidos;
        this.inputTelefono = this.datos[0].telefono;
        this.inputEmail = this.datos[0].email;

        this.cdr.markForCheck();
      },
      err => {
        console.log(err);
      }
    );
  }

}
