import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';


import { map, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/conexionApi.service';
import { NbAuthService, NbAuthJWTToken } from 'src/framework/auth/public_api';
import { Peticion } from 'src/app/services/peticion';
import { FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';

@Component({
  selector: 'app-addServicio',
  templateUrl: './addServicio.component.html',
  styleUrls: ['./addServicio.component.scss']
})
export class AddServicioComponent implements OnInit {



  options = [
    { value: 'Administración', label: 'Administración' },
    { value: 'Desarrollo', label: 'Desarollo' },
    { value: 'Soporte', label: 'Soporte' },

  ];




  inputNombre: string = '';
  inputDescripcion: string = '';
  inputTipo: string = '';
  inputPrecio: string = '';


 constructor(private authService: NbAuthService,private _location: Location, private router: Router,private cdr: ChangeDetectorRef, private http: ApiService, private cdRef: ChangeDetectorRef, private formBuilder: FormBuilder) {



   }

  ngOnInit() {
    setTimeout(() => {

      this.cdr.markForCheck ();
    }, 500);
  }

  ingresarPost(body: Peticion) {
    this.http.postRespuesta(body, "https://app.accionext.com/api/servicios/addservicio.php").subscribe(
      data => {
        //this.datos = data;
        // this.user['data']['fullname'] = this.datos['fullname'];
        // localStorage.setItem('auth_app_token', JSON.stringify({'name':'nb:auth:jwt:token','ownerStrategyName':'email','createdAt':1589115670000,'value': this.datos['token']}));

      },
      err => { }
    );

  }


  ingresar() {
    // alert(this.inputNombre + " -- "+ this.inputDescripcion + " -- "+ this.inputTipo + " -- "+ this.inputPrecio);

    if (this.inputNombre == "") {

      alert("El campo nombre está vació");
    } else {

      if (this.inputDescripcion == "") {

        alert("El campo descripción está vació");

      } else {

        if (this.inputPrecio == "") {

          alert("El campo precio está vació");
        } else {

          if (this.inputTipo == "") {

            alert("El campo precio está vació");

          } else {

            var t = localStorage.getItem('auth_app_token');
            this.ingresarPost({ nombre:this.inputNombre,descripcion:this.inputDescripcion,tipo:this.inputTipo,precio:this.inputPrecio, token: t });
            this.goBack();

          }


        }

      }



    }


 }


 goBack(){


  this.router.navigate(['pages/servicios']);
}


}
