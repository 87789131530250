import { ChangeDetectionStrategy, Component} from '@angular/core';
import { MENU_ITEMS } from './pages-menu';
import { NbSidebarService } from '@nebular/theme';

@Component({
  selector: 'app-pages',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent {
  items = MENU_ITEMS;

  constructor(private sidebarService: NbSidebarService) {
  }
  valor : string;
  abrir(): boolean {
    this.sidebarService.expand('menu-sidebar');
    return false;
  }
  cerrar(): boolean {
    this.sidebarService.toggle(true,'menu-sidebar');
    return false;
  }

  tabs: any[] = [
    {
      // title: ' Inicio',
      icon:   'fas fa-home ',
     // extension: 'fas',
      route: '/pages/inicio',
      // icon: { icon: 'checkmark-square-outline', pack: 'eva' },

       responsive: true // hide title before `route-tabs-icon-only-max-width` value // queryParams: { param1: 123456, param2: 'test' }, (para pasar parametros a la url)

    },
    {
      // title: ' Fichar',
      route: '/pages/fichar',
      icon: 'fas fa-clipboard-check',
     // extension: 'fas',
      responsive: true,
    },
    {
      // title: ' Perfil',
      route: '/pages/perfil',
      icon: 'far fa-user',
     // extension: 'far',
      responsive: true, // hide title before `route-tabs-icon-only-max-width` value
    },
    {
      // title: ' Ajustes',
      route: '/pages/notificaciones',
      icon: 'fas fa-paperclip',
     // extension: 'fas',
      responsive: true, // hide title before `route-tabs-icon-only-max-width` value
    }
  ];

}
