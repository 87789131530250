<section class="mt-1 pt-1">
  <div class="container-fluid">
    <div class="text-center d-flex justify-content-between">
      <div>
        <h1 class="section-heading">Servicios

        </h1>
      </div>

      <div>
        <button nbButton outline status="primary" class="btnAddClient m-1" routerLink="/pages/addServicio"><i
            class="fas fa-plus"></i></button>
        <button nbButton outline status="danger" class="btnAddClient m-1"  (click)="deleteServicio()"><i class="fas fa-minus"></i></button>
      </div>
    </div>


    <div class="row">
      <div class="col">
        <ng2-smart-table [settings]="settings" [source]="source" width="100%" (userRowSelect)="onUserRowSelect($event)"></ng2-smart-table>

      </div>

    </div>




  </div>


</section>
