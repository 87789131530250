


<nb-card>
  <nb-card-header>
    <div class="row">
      <div class="col-sm-12 mb-1">


        <div class="text-center d-flex justify-content-between">
          <div>
            <h4 class="section-heading">Inversión de los socios en la empresa</h4>

          </div>
          <div>

            <button nbButton outline status="warning" data-toggle="modal" data-target="#modelId" class="btnAddClient mt-2" >
              <i class="fas fa-info-circle"></i>
            </button>


      <div class="modal fade w-100" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
        <div class="modal-dialog w-100" role="document">
          <div class="modal-content p-1">
            <div class="modal-header">
              <h5 class="modal-title text-dark font-weight-bold">Información</h5>

            </div>
            <div class="modal-body">
              <h6 class="text-success text-left m-1">Inversión de los socios</h6>
              <p class="text-dark text-left">Aquí se reflejan las inversiones de los socios</p>



            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

          </div>

        </div>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>

    <div class="row text-center p-2 d-flex justify-content-center">

      <h4 class="text-center m-1">Total invertido en la empresa<br><span
          class="display-4">{{totalInversion}}€</span> <br></h4>

    </div>

  </nb-card-body>
</nb-card>



<!-- Agregar la parte de intruducir datos -->

<div class="row row-gastos-usuario">
  <div class="col">



    <h5>Inversión de cada socio</h5>

    <div class="col-sm-12 mb-2" *ngFor="let i of datosTotales">
      <nb-accordion>
        <nb-accordion-item>
          <nb-accordion-item-header class="d-flex justify-content-between  pr-5">
            <div>
              {{ i.usuario}}
            </div>
            <div>
              {{i.sumaTotalMes}}€
            </div>


          </nb-accordion-item-header>
          <nb-accordion-item-body>
            <div class="row text-center">
              <div class="col-md col-sm-12">
                <ng-container *ngFor="let item of datos">

                  <nb-card class="fondo m-1 mr-0 ml-0" *ngIf="i.usuario==item.usuario">
                    <nb-card-body class="text-center ">

                      <div class="row no-gutters align-items-center">
                        <div class="col d-flex justify-content-start">
                          <p class="mb-0"> {{item.nombre}}</p>
                        </div>
                        <div class="col-auto">

                          {{item.cantidad}}€

                        </div>
                      </div>

                    </nb-card-body>
                  </nb-card>

                </ng-container>

              </div>
            </div>
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
    </div>

  </div>

</div>

