import { Component, OnInit } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ApiService } from 'src/app/services/conexionApi.service';
import { Peticion } from 'src/app/services/peticion';
@Component({
  selector: 'app-chart1',
  templateUrl: './chart1.component.html',
  styleUrls: ['./chart1.component.scss']
})
export class Chart1Component implements OnInit {

  //a lo que te devuelve la api
  single = [];

  blueAutoSchool = '#44d9e6';
  orangeAutoSchool = '#ffbd4a';
  greenAutoSchool = '#35b729';
  redAutoSchool = '#ff5c5c';
  // options
  gradient: boolean = false;
  showLegend: boolean = false;
  showLabels: boolean = false;
  isDoughnut: boolean = true;

  colorScheme = {
    domain: [this.redAutoSchool, this.greenAutoSchool]
  };

  t = localStorage.getItem('auth_app_token');
  constructor( private https: ApiService) {
    Object.assign(this.single);
  }
  ngOnInit(): void {
    this.postEnviarDatosEstadisticas({token:this.t});
    
  }
  
  postEnviarDatosEstadisticas(body: Peticion) {
    this.https.postRespuesta(body,"https://autoschool.es/api/estadisticas/estadisticas.php").subscribe(
      info => {
        this.single = info;
      },
      err => {}
    );
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
}
