import { Component, Input, OnInit } from '@angular/core';
import { NbSortDirection, NbSortRequest, NbTreeGridDataSource, NbTreeGridDataSourceBuilder } from '@nebular/theme';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/conexionApi.service';
import { Peticion } from 'src/app/services/peticion';

interface TreeNode<T> {
  data: T;
  children?: TreeNode<T>[];
  expanded?: boolean;
}

interface FSEntry {
  Nombre: string;
  Video?: string;
  Test?: string;
}



@Component({
  selector: 'tree-grid',
  templateUrl: './tree-grid.component.html',
  styleUrls: ['./tree-grid.component.scss'],
})
export class TreeGridComponent implements OnInit{
  customColumn = 'Nombre';
  defaultColumns = [ 'Video', 'Test' ];
  otherColumns = ['rutaFichero', 'rutaVideo']; 
  allColumns = [ this.customColumn, ...this.defaultColumns ];

  dataSource: NbTreeGridDataSource<FSEntry>;

  sortColumn: string;
  sortDirection: NbSortDirection = NbSortDirection.NONE;
  http: any;

  // pdf 
  urlPdf = "";
  urlVideo = "";
  t = localStorage.getItem('auth_app_token');
  tema= 0;

  constructor(private router: Router, private https: ApiService, private dataSourceBuilder: NbTreeGridDataSourceBuilder<FSEntry> ) {
   
  }
  ngOnInit(): void {
    this.postSentServices({numeroTest: 1}); 
  }

  updateSort(sortRequest: NbSortRequest): void {
    this.sortColumn = sortRequest.column;
    this.sortDirection = sortRequest.direction;
  }

  getSortDirection(column: string): NbSortDirection {
    if (this.sortColumn === column) {
      return this.sortDirection;
    }
    return NbSortDirection.NONE;
  }

  private data : any;

  postSentServices(body: Peticion) {
    this.https.postRespuesta(body,"https://autoschool.es/api/temario/lectura.php").subscribe(
      info => {
        this.data = info;
        this.dataSource = this.dataSourceBuilder.create(this.data);
      },
      err => {}
    );

  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + (nextColumnStep * index);
  }

  url ;
 
  establecerRuta(nombre){
    for (let index = 0; index < this.data.length; index++) {
        for (let j = 0; j < this.data[index]['children'].length; j++) {  
          if(this.data[index]['children'][j]['data']['Nombre']==nombre){
            this.url = this.data[index]['children'][j]['data']['rutaFichero'];
            let arrayTema = this.data[index]['data']['Nombre'].split(" ",3);
            this.tema = Number( arrayTema[1]);
          }
        }
    }
    this.postEnviarDatosLectura({token:this.t, accion : 'lectura', tema : this.tema}) 
    this.urlPdf = "/app/lectura/"+this.url;
  }

  postEnviarDatosLectura(body: Peticion) {
    this.https.postRespuesta(body,"https://autoschool.es/api/estadisticas/datosLecturaVideo.php").subscribe(
      info => {
      },
      err => {}
    );
  }

  establecerRutaVideo(apartado){
    for (let index = 0; index < this.data.length; index++) {
        for (let j = 0; j < this.data[index]['children'].length; j++) {  
          if(this.data[index]['children'][j]['data']['Nombre']==apartado){
             this.url = this.data[index]['children'][j]['data']['rutaVideo'];
             this.url +="-"+apartado;
            let arrayTema = this.data[index]['data']['Nombre'].split(" ",3);
            this.tema = Number( arrayTema[1]);
            
          }
        }
    }
    this.postEnviarDatosVideo({token:this.t, accion:'video', tema: this.tema});
    this.router.navigate(['/pages/temario/videos', this.url]);
  }

   postEnviarDatosVideo(body: Peticion) {
    this.https.postRespuesta(body,"https://autoschool.es/api/estadisticas/datosLecturaVideo.php").subscribe(
      info => {
      },
      err => {}
    );
  }
}
