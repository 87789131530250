import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NbSidebarService }from '@nebular/theme';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { MenuUserComponent } from './menu-user/menu-user.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']

})
export class HeaderComponent implements OnInit{
  user = {};

  notificacionesNuevas = false;

  enInicio = false;

 

  constructor (private sidebarService: NbSidebarService, private _location: Location, private router: Router, private cdr: ChangeDetectorRef){ }
 

  ngOnInit(): void {
   
    console.log(this.router.url);

    this.cdr.markForCheck();


    setTimeout(() => {
      
      console.log(this.router.url);

      this.cdr.markForCheck();
    }, 5000);


  }

  ngAfterViewInit(): void {
      var t = localStorage.getItem('auth_app_token');
      console.log(this.router.url);
      this.cdr.markForCheck();
  }

  // this.searchService.onSearchSubmit()
  //     .subscribe((data: any) => {
  //       this.value = data.term;
  //     })

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  activaBuscador(){
    this.sidebarService.expand();
  }
  goBack(){
    this._location.back();
  }

}
