<nb-card>
  <nb-card-header *ngIf="showNavigation" class="calendar-navigation">
    <nb-calendar-view-mode [date]="visibleDate"
                           [viewMode]="activeViewMode"
                           (changeMode)="onChangeViewMode()">
    </nb-calendar-view-mode>

    <nb-calendar-pageable-navigation (prev)="navigatePrev()" (next)="navigateNext()">
    </nb-calendar-pageable-navigation>
  </nb-card-header>

  <nb-card-body [ngSwitch]="activeViewMode">

    <nb-calendar-day-picker *ngSwitchCase="ViewMode.DATE"
                            [boundingMonths]="boundingMonth"
                            [cellComponent]="dayCellComponent"
                            [min]="min"
                            [max]="max"
                            [filter]="filter"
                            [visibleDate]="visibleDate"
                            [size]="size"
                            [date]="date"
                            [showWeekNumber]="showWeekNumber"
                            (dateChange)="dateChange.emit($any($event))"
                            [weekNumberSymbol]="weekNumberSymbol">
    </nb-calendar-day-picker>

    <nb-calendar-year-picker *ngSwitchCase="ViewMode.YEAR"
                             [cellComponent]="yearCellComponent"
                             [date]="$any(date)"
                             [min]="min"
                             [max]="max"
                             [filter]="filter"
                             [size]="size"
                             [year]="visibleDate"
                             (yearChange)="setVisibleDate($event); setViewMode(ViewMode.MONTH)">
    </nb-calendar-year-picker>

    <nb-calendar-month-picker *ngSwitchCase="ViewMode.MONTH"
                              [cellComponent]="monthCellComponent"
                              [min]="min"
                              [max]="max"
                              [filter]="filter"
                              [size]="size"
                              [month]="visibleDate"
                              [date]="$any(date)"
                              (monthChange)="setVisibleDate($event); setViewMode(ViewMode.DATE)">
    </nb-calendar-month-picker>

  </nb-card-body>

</nb-card>
