import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import { ApiService } from 'src/app/services/conexionApi.service';
import { Peticion } from 'src/app/services/peticion';
import { NbTokenService } from 'src/framework/auth/public_api';

@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss']
})
export class ChatsComponent implements OnInit {

  datos:any = {};

  constructor(private https: ApiService,  private t: NbTokenService, private router: Router, private activatedRoute: ActivatedRoute) {


  }

  ngOnInit() {

    var t = localStorage.getItem('auth_app_token');
    this.obtenerDatos({ token: t });
    // console.log(this.datos);

  }



  obtenerDatos(body: Peticion) {
    this.https.postRespuesta(body, "https://app.accionext.com/api/usuarios/usuarios.php").subscribe(
      info => {
        this.datos = info;
        // console.log(this.datos);
        // this.source = new LocalDataSource(this.datos);
      },
      err => {
        console.log(err);
      }
    );
  }


  verCliente(id){
    this.router.navigate(['/pages/chats', id], {relativeTo: this.activatedRoute})

  }



}
