import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/conexionApi.service';
import { Peticion } from 'src/app/services/peticion';



@Component({
  selector: 'app-chart4',
  templateUrl: './chart4.component.html',
  styleUrls: ['./chart4.component.scss']
})
export class Chart4Component implements OnInit{

  multi = [];

  blueAutoSchool = '#44d9e6';
  orangeAutoSchool = '#ffbd4a'; 
  greenAutoSchool = '#35b729';
  redAutoSchool = '#ff5c5c';

  // options
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;

  yAxis: boolean = false;
  xAxis: boolean = true;


  colorScheme = {
    domain: [this.redAutoSchool, this.orangeAutoSchool, this.greenAutoSchool, this.blueAutoSchool]
  };

  t = localStorage.getItem('auth_app_token');

  constructor(private https: ApiService) {
    Object.assign(this.multi);
  }

  ngOnInit(): void {
    this.postEnviarDatosEstadisticas({token:this.t});
  }

  postEnviarDatosEstadisticas(body: Peticion) {
    this.https.postRespuesta(body,"https://autoschool.es/api/estadisticas/estadisticasSemana.php").subscribe(
      info => {
        this.multi = info;
      },
      err => {}
    );
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }
  
  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

}
