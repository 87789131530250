import { Component, OnInit } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ApiService } from 'src/app/services/conexionApi.service';
import { Peticion } from 'src/app/services/peticion';


@Component({
  selector: 'app-chart2',
  templateUrl: './chart2.component.html',
  styleUrls: ['./chart2.component.scss']
})
export class Chart2Component  implements OnInit{

  
  blueAutoSchool = '#44d9e6';
  orangeAutoSchool = '#ffbd4a';
  greenAutoSchool = '#35b729';
  redAutoSchool = '#ff5c5c';
  view = [220,220];
  temas = [ ]
  
  // options
  showLegend: boolean = true;
  showLabels: boolean = false;

  colorScheme = {
    domain: [this.blueAutoSchool, this.greenAutoSchool, this.blueAutoSchool, this.redAutoSchool, this.greenAutoSchool, this.blueAutoSchool]
  };

  t = localStorage.getItem('auth_app_token');

  constructor(private https: ApiService) {
    Object.assign(this.temas);
  }
  ngOnInit(): void {
    this.postEnviarDatosEstadisticas({token:this.t});
  }

  postEnviarDatosEstadisticas(body: Peticion) {
    this.https.postRespuesta(body,"https://autoschool.es/api/estadisticas/estadisticasTemas.php").subscribe(
      info => {
        this.temas = info;
      },
      err => {}
    );
  }

  onSelect(event) {
    console.log(event);
  }

}
