import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { ApiService } from 'src/app/services/conexionApi.service';
import { Peticion } from 'src/app/services/peticion';
import { NbTokenService } from 'src/framework/auth/public_api';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.scss']
})
export class ServiciosComponent implements OnInit {

  source: LocalDataSource;
  datos:any = {};
  selected;

  settings = {
    actions: {
      columnTitle: 'Acciones',
      add: false,
      edit: false,
      delete: false,
      position: 'center',

    },
    add: {

      addButtonContent: '<i class="fas fa-plus" title="Añadir " ></i>',
      createButtonContent: '<i class="far fa-check-square" title="Añadir " ></i>',
      cancelButtonContent: '<i class="far fa-window-close" title="cancelar " ></i>'
    },
    edit: {
      editButtonContent: '<i class="fas fa-pen" title="Editar"></i>',
      saveButtonContent: '<i class="far fa-edit" title="actualizar " ></i>',
      cancelButtonContent: '<i class="far fa-window-close" title="cancelar " ></i>'
    },
    delete: {
      deleteButtonContent: '<i class="far fa-trash-alt" title="Eliminar" ></i>',
      confirmDelete: true
    },

    pager: {

      display: true
    },


    columns:
    {


      nombre:{
        title: 'Nombre',

      },
      descripcion:{
        title: 'Descripción',

      },
      tipo:{
        title: 'Tipo',

      },
      precio:{
        title: 'Precio',
      },


    }
  }


  constructor(private https: ApiService,  private t: NbTokenService, private cdr: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    var t = localStorage.getItem('auth_app_token');
    this.obtenerDatos({ token: t });
    this.cdr.markForCheck();

    setTimeout(() => {
      this.obtenerDatos({ token: t });
      this.cdr.markForCheck();
    }, 5000);


  }

  ngAfterViewInit(): void {
      var t = localStorage.getItem('auth_app_token');
      this.obtenerDatos({ token: t });
      this.cdr.markForCheck();
  }


  obtenerDatos(body: Peticion) {
    this.https.postRespuesta(body, "https://app.accionext.com/api/servicios/servicios.php").subscribe(
      info => {
        this.datos = info;
        this.source = new LocalDataSource(this.datos);
        this.cdr.markForCheck ();
      },
      err => {
        console.log(err);
      }
    );
  }

  deleteServicio(){
    var t = localStorage.getItem('auth_app_token');
    var body = {token:t,id: this.selected[0].id};
    console.log(body);
    this.https.postRespuesta(body, "https://app.accionext.com/api/servicios/deleteServicio.php").subscribe(
      info => {
        console.log("Servicio borrado correctamente");
        this.cdr.markForCheck ();
        this.obtenerDatos({ token: t });
      },
      err => {
        console.log(err);
      }
    );
  }

  onUserRowSelect(event) { 
    this.selected = event.selected; 
    console.log('selected list: ', this.selected); 
  }




}
