import { Component, OnInit ,ChangeDetectorRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import { ApiService } from 'src/app/services/conexionApi.service';
import { Peticion } from 'src/app/services/peticion';
import { NbTokenService } from 'src/framework/auth/public_api';

@Component({
  selector: 'app-domicializaciones',
  templateUrl: './domicializaciones.component.html',
  styleUrls: ['./domicializaciones.component.scss']
})
export class DomicializacionesComponent implements OnInit {

  source: LocalDataSource;
  datos:any = {};

  settings = {
    actions: {
      columnTitle: 'Acciones',
      add: false,
      edit: true,
      delete: false,
      position: 'right',

    },
    add: {

      addButtonContent: '<i class="fas fa-plus" title="Añadir " ></i>',
      createButtonContent: '<i class="far fa-check-square" title="Añadir " ></i>',
      cancelButtonContent: '<i class="far fa-window-close" title="cancelar " ></i>'
    },
    edit: {
      editButtonContent: '<i class="fa fa-user-circle text-primary m-1" title="Ver cliente"></i>',
      saveButtonContent: '<i class="far fa-edit" title="actualizar " ></i>',
      cancelButtonContent: '<i class="far fa-window-close" title="cancelar " ></i>'
    },
    delete: {
      deleteButtonContent: '<i class="far fa-trash-alt" title="Eliminar" ></i>',
      confirmDelete: true
    },

    mode: 'external',

    pager: {

      display: true
    },


    columns:
    {

      dni:{
        title: 'DNI',
        editable: true
      },
      nombre:{
        title: 'Nombre',
        editable: true
      },
      apellidos:{
        title: 'Apellidos',
        editable: true
      },
      telefono:{
        title: 'NºTelefono',
        editable: true
      },
      email:{
        title: 'Email',
        editable: true
      }


    }
  }

  constructor(private https: ApiService,  private t: NbTokenService, private router: Router, private activatedRoute: ActivatedRoute, private cdr: ChangeDetectorRef) {


  }

  ngOnInit(): void {
    var t = localStorage.getItem('auth_app_token');
    this.obtenerDatos({ token: t });
    this.cdr.markForCheck();
    
    setTimeout(() => {
      this.obtenerDatos({ token: t });
      this.cdr.markForCheck();
    }, 5000);
    

  }

  ngAfterViewInit(): void {
      var t = localStorage.getItem('auth_app_token');
      this.obtenerDatos({ token: t });
      this.cdr.markForCheck();
  }


  obtenerDatos(body: Peticion) {
    this.https.postRespuesta(body, "https://app.accionext.com/api/clientes/clientes.php").subscribe(
      info => {
        this.datos = info;
        // console.log(this.datos);
        this.source = new LocalDataSource(this.datos);
        this.cdr.markForCheck();
      },
      err => {
        console.log(err);
      }
    );
  }

edit(event){
  this.router.navigate(['./', event.index], {relativeTo: this.activatedRoute})
  console.log(event)
}

verCliente(id){
  this.router.navigate(['./', id], {relativeTo: this.activatedRoute})

}




}
