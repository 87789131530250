import { Component, OnInit } from '@angular/core';
import { NbAuthService, NbAuthJWTToken } from 'src/framework/auth/public_api';
import { Router } from '@angular/router';
import {NgForm} from '@angular/forms';
import { switchMap } from 'rxjs/operators';
import { ChangeDetectorRef} from '@angular/core';
import { ApiService } from 'src/app/services/conexionApi.service';
import { Peticion } from 'src/app/services/peticion';
import { SwPush } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { interval } from 'rxjs';


declare const navigator: any;

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {
 
  user :any;
  x:any;
  peticion = new Peticion();
  datos =[] ;
  checkedNotif:boolean = false;
  checkedUbi:boolean = false;
  checkedCamara:boolean = false;
  checkedMicrofono:boolean = false;
  pushSubscription: PushSubscription;
  locationPermissions: any;
  ubicacionSoportada = false;
  permissionDescriptors = [
    {name: 'camera'},
    {name: 'microphone'}
  ]

  constructor(private authService: NbAuthService,private router: Router, private http: ApiService, private cdRef: ChangeDetectorRef, private swPush: SwPush, private https: ApiService ) {
    
    this.authService.onTokenChange()
    .subscribe((token: NbAuthJWTToken) => {

      if (token.isValid()) {
        this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable 
      }
    });
    
    
  }
  t = localStorage.getItem('auth_app_token');


  ngOnInit(): void {
    this.checkSuscription();
    this.checkPermissions();
  }

  postPerfil(body: Peticion) {
    this.http.postRespuesta(body, "https://autoschool.es/api/auth/perfil.php").subscribe(
      data => {
      this.datos = data;
      this.user['data']['fullname'] = this.datos['fullname'];
      localStorage.setItem('auth_app_token', JSON.stringify({'name':'nb:auth:jwt:token','ownerStrategyName':'email','createdAt':1589115670000,'value': this.datos['token']}));
      
      },
      err => {}
    );
    
  }

  guardar(usuario: NgForm){
    if(usuario.dirty){
      this.postPerfil({ nombre : usuario.value, token: this.t });
      this.router.navigateByUrl("pages/inicio");
    }
    
  }

  //Comprueba el estado de la suscripción a las notificaciones push.

  checkSuscription(): boolean{
    
    if(this.swPush.isEnabled){
      this.swPush.subscription.subscribe((res) =>{ //Pregunta al servicio swPush por las suscripciones.
        console.log(res);
        this.pushSubscription = res;  //Se le asigna las suscripciones push que tiene el navegador
        if(this.pushSubscription === null){ //Si la suscripción es null, es que no está suscrito, por lo tanto, no hay permisos de notificaciones.
          this.checkedNotif = false; 
          localStorage.removeItem('notiPush');
        }else{ //Si no es null, está suscrito, por lo tanto, hay permisos.
          this.checkedNotif = true;
          localStorage.setItem('notiPush', JSON.stringify(this.checkedNotif))
        }
      })

      return this.checkedNotif; //Devuelve el estado de la notificación.

    }
  }

  //Cambia entre activa o inactiva la suscripción e inserta o borra una suscripción.
  changePushSubscription(){
    if(this.swPush.isEnabled){

      if(this.pushSubscription == null){
        //Añadir la suscripción push
        this.pushSubscribe();
        this.checkedNotif = true;
      }else{ 
        //Borrar la suscripción push y desuscribirse
        this.https.postRespuesta({token: this.t, suscripcion: this.pushSubscription}, "https://app.accionext.com/api/sub_push/deleteSubscription.php").subscribe();
        this.pushSubscription.unsubscribe().then((res) => {
          this.checkSuscription();
        });

      }

      return this.checkedNotif
    }

  }

  //Se suscribe a las notificaciones push.
  pushSubscribe(){

    //Pregunta al navegador (y al usuario) por permisios de notificaciones.
    this.swPush
      .requestSubscription({
        serverPublicKey: environment.VAPID_KEY,
      })
      .then((sub) => {
        //Si el usuario acepta los permisos de notificaciones, se crea una nueva suscripción y se envía a la api para su insercion en la base de datos.
        this.https
          .addSubscription(
            sub,
            localStorage.getItem('auth_app_token'),
            'https://app.accionext.com/api/sub_push/push_subscription.php'
          )
          .subscribe();
          //Asigna la suscripcion push recién creada al objeto pushSubscription
          this.swPush.subscription.subscribe((res) => {
            this.pushSubscription = res;

          })

      })
      .catch((err) => {
        console.log(err);
      });
  

}

//Cambia los permisos de geolocalización, preguntando al usuario la primera vez.

changeLocationPermissions(){
  //Si la geolocalización es soportada en el navegador...
  if(navigator.geolocation){
    if(this.checkedUbi == false){
      this.checkedUbi = true;
      // Pregunta al usuario por los permisos de ubicación y trae la ubicacion actual, aunque no se usa (la ubicacion actual).
     this.locationPermissions = navigator.geolocation.getCurrentPosition( (location) => {
       localStorage.setItem('locationPermissions', JSON.stringify(this.checkedUbi));
      console.log("Localización realizada!")

     }, (err) =>{
       //Si el usuario bloquea los permisos, se actualiza el localStorage y el estado del toggle.
       this.checkedUbi = false;
       localStorage.setItem('locationPermissions', JSON.stringify(this.checkedUbi));

     })
     
    }else{
      this.checkedUbi = false;
      localStorage.setItem('locationPermissions', JSON.stringify(this.checkedUbi));
    }

  }else {
    console.log("Geolocation is not supported by this browser.")
  }
}


//Comprueba los permisos de geolocalización y añade el estado al localStorage

async checkPermissions() {
  //Toma los permisos del navegador y comprueba los de camara, micrófono y geolocalización.
  if(navigator.geolocation){
    this.ubicacionSoportada = true;
  }
  const permissionDescriptors = [
    {name: 'camera'},
    {name: 'microphone'},
    {name: 'geolocation'}
  ];
  
  //Añade a un array los permisos encontrados y su estado
  const permissions =  await Promise.all(permissionDescriptors.map(async descriptor => ({
    descriptor,
    status: await navigator.permissions.query(descriptor),
  })));
  
  //Recorre el array y comprueba el tipo de permiso y su estado, asignandole un valor al localStorage para que aparezca como activado o desactivado.
  for (const {descriptor, status} of permissions) {
    if(descriptor.name == 'camera'){ //Si el permiso es de la cámara
      if(status.state == 'granted'){ //Y está permitido
        this.checkedCamara = JSON.parse(localStorage.getItem('cameraPermissions')); //Asigna el valor del localStorage al atributo checkedCamara

      }else if( status.state == 'prompt' || status.state == 'denied'){ // si no está permitido o no se ha pedido, asigna un valor al localStorage y a checkedCamera
        localStorage.setItem('cameraPermissions', 'false');
        this.checkedCamara = JSON.parse(localStorage.getItem('cameraPermissions'));
      }
    }else if(descriptor.name == 'microphone'){ //Si el permiso es del micrófono
      if(status.state == 'granted'){
        this.checkedMicrofono = JSON.parse(localStorage.getItem('microphonePermissions'));

      }else if( status.state == 'prompt' || status.state == 'denied'){
        localStorage.setItem('microphonePermissions', 'false');
        this.checkedMicrofono = JSON.parse(localStorage.getItem('microphonePermissions'));
      }
    }else{
      if(status.state == 'granted'){ //Si el permiso es de la geolocalización y está permitido
        this.checkedUbi = JSON.parse(localStorage.getItem('locationPermissions'));

      }else if( status.state == 'prompt' || status.state == 'denied'){
        localStorage.setItem('locationPermissions', 'false');
        this.checkedUbi = JSON.parse(localStorage.getItem('locationPermissions'));
      }
    }
  }
}



}
