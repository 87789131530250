import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagesComponent } from './pages.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { InicioComponent } from './inicio/inicio.component';

import { EstadisticasComponent } from './estadisticas/estadisticas.component';
import { VideosComponent } from './temario/videos/videos.component';
import { TextoComponent } from './temario/texto/texto.component';
import { PerfilComponent } from './perfil/perfil.component';

import { TreeGridComponent } from './temario/tree-grid/tree-grid.component';
import { CalendarioComponent } from './calendario/calendario.component';
import { PublicoComponent } from './foro/publico/publico.component';
import { PrivadoComponent } from './foro/privado/privado.component';
import { CompetitivoComponent } from './competitivo/competitivo.component';
import { FicharComponent } from './fichar/fichar.component';
import { IngresosComponent } from './ingresos/ingresos.component';
import { GastosComponent } from './gastos/gastos.component';
import { ServiciosComponent } from './servicios/servicios.component';

import { RetirarComponent } from './retirar/retirar.component';
import { ClientesComponent } from './clientes/clientes.component';
import { IngresarComponent } from './ingresar/ingresar.component';
import { AddClientComponent } from './addClient/addClient.component';
import { AddServicioComponent } from './addServicio/addServicio.component';
import { CorrectoComponent } from './Correcto/Correcto.component';
import { SegurosComponent } from './seguros/seguros.component';
import { DomicializacionesComponent } from './domicializaciones/domicializaciones.component';
import { ContabilidadComponent } from './contabilidad/contabilidad.component';
import { TomaDatosSegurosComponent } from './seguros/tomaDatosSeguros/tomaDatosSeguros.component';
import { PolizasComponent } from './seguros/polizas/polizas.component';
import { TarificarComponent } from './seguros/tarificar/tarificar.component';
import { CauComponent } from './cau/cau.component';
import { EstadisticasGeneralesComponent } from './estadisticasGenerales/estadisticasGenerales.component';
import { InversionComponent } from './inversion/inversion.component';
import { NotificacionesComponent } from '../header/notificaciones/notificaciones.component';
import { VerClienteComponent } from './clientes/ver-cliente/ver-cliente.component';
import { ChatsComponent } from './chats/chats.component';
import { NextChatComponent } from './chats/nextChat/nextChat.component';
import { EstadisticasJornadasComponent } from './estadisticasJornadas/estadisticasJornadas.component';
import { FacturasComponent } from './facturas/facturas.component';
import { ViajesComponent } from './viajes/viajes.component';
import { ReservasComponent } from './viajes/reservas/reservas.component';
import { NewComponent } from './viajes/new/new.component';
import { GestionEconomicaComponent } from './gestion-economica/gestion-economica.component';
import { TesoreriaComponent } from './gestion-economica/tesoreria/tesoreria.component';
import { PresupuestoComponent } from './gestion-economica/presupuesto/presupuesto.component';
import { GastosFijosComponent } from './gastosFijos/gastosFijos.component';
import { IngresosFijosComponent } from './ingresosFijos/ingresosFijos.component';
import { ReunionesComponent } from './reuniones/reuniones.component';
import { MeetComponent } from './reuniones/meet/meet.component';
import { GestorDeTareasComponent } from './gestor-de-tareas/gestor-de-tareas.component';
import { CajaComponent } from './caja/caja.component';


const routes: Routes = [

{
  path: '',
  component: PagesComponent,
  children: [
    {
      path: 'perfil',
      component: PerfilComponent,
    },

    {
      path: 'inicio',
      component: InicioComponent,
    },
    {
      path: 'estadisticas',
      children: [
        {
          path: '',
          component: EstadisticasComponent
        },
        {
          path: 'jornadas',
          component : EstadisticasJornadasComponent
        }
      ]
    },
    {
      path: 'fichar',
      component: FicharComponent
    },
    {
      path: 'ingresos',
      component: IngresosComponent
    },
    {
      path: 'gastos',
      component: GastosComponent
    },

    {
      path: 'facturas',
      component: FacturasComponent
    },

    {
      path: 'tareas',
      component: GestorDeTareasComponent
    },

    {
      path: 'caja',
      component: CajaComponent
    },

    {
      path: 'reuniones',
      children: [
        {
          path: '',
          component: ReunionesComponent
        },
        {
          path: 'meet',
          children: [
            {
              path: '',
              component: MeetComponent
            }
          ]
        },
      ]
    },

    {
      path: 'domicializaciones',
      component: DomicializacionesComponent
    },
    {
      path: 'contabilidad',
      component: ContabilidadComponent
    },
    {
      path: 'economia',
      children: [
        {
          path: '',
          component: GestionEconomicaComponent
        },
        {
          path: 'tesoreria',
          children: [
            {
              path: '',
              component: TesoreriaComponent
            },
            {
              path: 'gastosfijos',
              component : GastosFijosComponent
            },
            {
              path: 'ingresosfijos',
              component : IngresosFijosComponent
            }
          ]
        },
        {
          path: 'probabilidad',
          children: [
            {
              path: '',
              component: PresupuestoComponent
            }
          ]
        },
      ]
    },

    {
      path: 'ingresar',
      component: IngresarComponent
    },
    {
      path: 'retirar',
      component: RetirarComponent
    },
    {
      path: 'chats',
      children: [
        {
          path: '',
          component: ChatsComponent
        },
        {
          path: ':id',
          component: ChatsComponent
        }
      ]

    },
    {
      path: 'nextchat',
      children: [
        {
          path: '',
          component: NextChatComponent
        },
        {
          path: ':id',
          component: NextChatComponent
        }
      ]

    },
    {
      path: 'clientes',
      children: [
        {
          path: '',
          component: ClientesComponent
        },
        {
          path: ':id',
          component: VerClienteComponent
        }
      ]
    },
    {
      path: 'inversion',
      component: InversionComponent
    },

    {
      path: 'addClient',
      component: AddClientComponent
    },
    {
      path: 'addServicio',
      component: AddServicioComponent
    },
    {
      path: 'correcto',
      component: CorrectoComponent
    },
    {
      path: 'estadisticasGenerales',
      component: EstadisticasGeneralesComponent
    },
    {
      path: 'seguros',
      children: [
        {
          path: '',
          component: SegurosComponent,
        },
        {
          path: 'new',
          children: [
            {
              path: '',
              component: TomaDatosSegurosComponent,
            },
            {
              path: ':id',
              component: TomaDatosSegurosComponent,
            },
          ]

        },
        {
          path: 'polizas',
          component: PolizasComponent,
        },
        {
          path: 'tarificar',
          component: TarificarComponent,
        },
      ]
    },
    {
      path: 'viajes',
      children: [
        {
          path: '',
          component: ViajesComponent,
        },
        {
          path: 'new',
          children: [
            {
              path: '',
              component: NewComponent,
            },
            {
              path: ':id',
              component: NewComponent,
            },
          ]
        },

        {
          path: 'reservas',
          component: ReservasComponent,
        },

      ]
    },
    {
      path: 'servicios',
      component: ServiciosComponent
    },

    {
      path: 'notificaciones',
      component: NotificacionesComponent
    },
    {
      path: 'temario',
      children: [
        {
          path: '',
          component: TextoComponent,
        },
        {
          path: 'texto',
          component: TreeGridComponent,
        },
        {
          path: 'videos',
          component: VideosComponent,
        },
        {
          path: 'videos/:video',
          component: VideosComponent,
        }
      ]
    },
    {
      path: 'calendario',
      component: CalendarioComponent,
    },
    {
      path: 'cau',
      component: CauComponent,
    },
    {
      path: 'foro',
      children: [
        {
          path: '',
          component: PublicoComponent,
        },
        {
          path: 'publico',
          component: PublicoComponent,
        },
        {
          path: 'privado',
          component: PrivadoComponent,
        },
      ]
    },
    {
      path: 'competitivo',
      component: CompetitivoComponent,
    },
    { path: '**' , component: NotFoundComponent},
  ]
  },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
