<nb-card>
  <nb-card-header>
    <div class="row">
      <div class="col-sm-12 mb-1">


        <div class="text-center d-flex justify-content-between">
          <div>
            <h1 class="section-heading">Nueva póliza</h1>

          </div>
          <div>

            <button nbButton outline status="warning" data-toggle="modal" data-target="#modelId"
              class="btnAddClient mt-2">
              <i class="fas fa-info-circle"></i>
            </button>

            <!-- Modal -->
            <div class="modal fade w-100" id="modelId" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
              aria-hidden="true">
              <div class="modal-dialog w-100" role="document">
                <div class="modal-content p-1">
                  <div class="modal-header">
                    <h5 class="modal-title text-dark font-weight-bold">Información</h5>
                    <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button> -->
                  </div>
                  <div class="modal-body">
                    <h6 class="text-success text-left m-1">¿Como registramos los nuevos clientes?</h6>
                    <p class="text-dark text-left">Tomas todos los datos y los registramos con nosotros, tras eso
                      tarificamos con todas nuetras compañías y colaboradores</p>
                    <!-- <ul >
                <li class="text-dark">Tu continuidad con respecto al uso de la aplicación.</li>
                <li class="text-dark">Numero de preguntas bien y mal respondidas.</li>
                <li class="text-dark">Numero de videos vistos.</li>
                <li class="text-dark">Numero de pdf leídos.</li>
              </ul> -->
                    <hr>
                    <!-- <h6 class="text-secondary">Estadísticas basadas en tus preguntas</h6>
              <p class="text-dark">Este apartado de estadísticas cuenta cuantas preguntas están bien respondidas y mal respondidas y las compara entre las dos.</p>
              <hr>
              <h6 class="text-primary">Tu dia a dia</h6>
              <p class="text-dark">Este apartado de estadísticas muestra tus acciones de los últimos 7 dias.</p>
              <ul>
                <li class="text-dark">Numero de apartados leídos y vistos</li>
                <li class="text-dark">Numero de tests por días.</li>
              </ul>
              <hr>
              <h6 class="text-danger">Tu progreso por temas</h6>
              <p class="text-dark">Este apartado de estadísticas muestra el porcentaje de preguntas bien respondidas por tema.</p>
              <hr> -->
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Cerrar</button>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <nb-stepper orientation="horizontal">
      <nb-step [label]="labelOne">
        <ng-template #labelOne>1er paso</ng-template>
        <h4>Datos del tomador</h4>

        <form (ngSubmit)="ingresar()" novalidate>
          <div class="row">
            <div class="col-md-12 justify-content-around">
              <app-completar-cliente class="mb-3 mw-100 w-100 " fullWidth></app-completar-cliente>


                <input type="text" class="mb-3 mw-100 w-100" required placeholder="DNI" [(ngModel)]="inputDNI" name="dni" nbInput fullWidth />
                <input type="text" class="mb-3 mw-100 w-100" required placeholder="Nombre" [(ngModel)]="inputNombre" name="nombre" nbInput fullWidth />
                <input type="text" class="mb-3 mw-100 w-100" required placeholder="Apellido" [(ngModel)]="inputApellidos" name="apellidos" nbInput fullWidth />
                <input type="text" class="mb-3 mw-100 w-100" required placeholder="Nº Teléfono" [(ngModel)]="inputTelefono" name="telefono" nbInput fullWidth />
                <input type="text" class="mb-3 mw-100 w-100" required placeholder="Email" [(ngModel)]="inputEmail" name="email" nbInput fullWidth />

            </div>

          </div>


          <div class="w-100 d-flex justify-content-end">
            <button nbButton disabled nbStepperPrevious>Anterior</button>
            <button type="submit" class="ml-3" nbButton nbStepperNext>Siguiente</button>

          </div>

        </form>


      </nb-step>
      <nb-step [label]="labelTwo">
        <ng-template #labelTwo>2º paso</ng-template>
        <h4>Datos genéricos de póliza</h4>
          <input type="text" class="mb-3 mw-100 w-100" required placeholder="Nº de póliza" [(ngModel)]="inputNumPoliza" name="numpoliza" nbInput fullWidth />
          <input type="text" class="mb-3 mw-100 w-100" required placeholder="Estado Actual" [(ngModel)]="inputEstadoActual" name="estadoactual" nbInput fullWidth />
          <input type="text" class="mb-3 mw-100 w-100" required placeholder="Compañía" [(ngModel)]="inputCompania" name="compania" nbInput fullWidth />
         
          <nb-radio-group class="d-flex flex-row p-2  mb-1" [(ngModel)]="inputFraccionamiento " name="fraccionamiento">
            <nb-radio [value]="1">
              Anual
            </nb-radio>
            <nb-radio [value]="2">
              Semestral
            </nb-radio>
            <nb-radio [value]="3">
              Trimestral
            </nb-radio>
          </nb-radio-group>
          <!-- <input type="text" class="mb-3 mw-100 w-100" required placeholder="Trimestral/semestral/anual" [(ngModel)]="inputFraccionamiento" name="fraccionamiento" nbInput fullWidth /> -->
          
          <!-- <input type="text" placeholder="Fecha de Inicio" 
                [locale]="{ format: 'YYYY-MM-DD', firstDay: 1}" [autoApply]="true"
                [singleDatePicker]="true" ngxDaterangepickerMd [(ngModel)]="inputFechaInicio" class="form-control mb-3 mw-100 w-100" />
          <input type="text" placeholder="Fecha de Fin"
                [locale]="{ format: 'YYYY-MM-DD', firstDay: 1}" [autoApply]="true"
                [singleDatePicker]="true" ngxDaterangepickerMd [(ngModel)]="inputFechaFin" class="form-control mb-3 mw-100 w-100" /> -->
          <input nbInput placeholder="Fecha de Inicio"  [nbDatepicker]="dateTimePicker" [(ngModel)]="inputFechaInicio"  class="form-control mb-3 mw-100 w-100">
          <nb-datepicker #dateTimePicker format="yyyy-MM-dd"></nb-datepicker>
          <input nbInput placeholder="Fecha de Fin" [nbDatepicker]="dateTimePickerFin" [(ngModel)]="inputFechaFin"  class="form-control mb-3 mw-100 w-100">
          <nb-datepicker #dateTimePickerFin format="yyyy-MM-dd"></nb-datepicker>
          <input type="number" class="mb-3 mw-100 w-100" required placeholder="Prima total" [(ngModel)]="inputPrima" name="prima" nbInput fullWidth />
        

        <div class="w-100 d-flex justify-content-end">
          <button nbButton nbStepperPrevious>Anterior</button>
          <button type="submit" class="ml-3" nbButton nbStepperNext>Siguiente</button>
        </div>  
      </nb-step>
      <nb-step label="3er paso">
        <ng-template #labelTwo>3er paso</ng-template>
        <h4>Datos específicos de la póliza</h4>
        <nb-tabset>
          <nb-tab tabTitle="Auto">
            <input type="text" class="mb-3 mw-100 w-100" required placeholder="Matricula" [(ngModel)]="inputMatricula" name="matricula" nbInput fullWidth />



          </nb-tab>
          <nb-tab tabTitle="Hogar">
            <input type="text" class="mb-3 mw-100 w-100" required placeholder="Calle, numero, (codigo postal) poblacion provincia" [(ngModel)]="inputDireccion" name="direccion" nbInput fullWidth />
            <input type="text" class="mb-3 mw-100 w-100" required placeholder="Tipo de casa (Adosasdo, piso...)" [(ngModel)]="inputTipo" name="tipocasa" nbInput fullWidth />
            <input type="number" class="mb-3 mw-100 w-100" required placeholder="Metros de la casa" [(ngModel)]="inputMetros" name="metros" nbInput fullWidth />


          </nb-tab>
          <nb-tab tabTitle="Salud">
            <input type="text" class="mb-3 mw-100 w-100" required placeholder="Tipo (completo,copago,dental)" [(ngModel)]="inputTipoSalud" name="tiposalud" nbInput fullWidth />


          </nb-tab>
         </nb-tabset>

        <div class="w-100 d-flex justify-content-end">
          <button nbButton nbStepperPrevious>Anterior</button>
          <button type="submit" class="ml-3" nbButton nbStepperNext>Siguiente</button>
        </div>
      </nb-step>
      <nb-step [label]="labelFour">
        <ng-template #labelFour>4º paso</ng-template>
        <h4>Resumen y guardar datos</h4>
        <h5>Datos del tomador</h5>
        <p>Dni: {{inputDNI}}</p> 
        <p>Nombre: {{inputNombre}}</p> 
        <p>Teléfono: {{inputTelefono}}</p>
        <h5>Datos de la póliza</h5>
        <p>Número de póliza: {{inputNumPoliza}}</p>
        <p>Estado: {{inputEstadoActual}}</p>
        <p>Fraccionamiento: {{inputFraccionamiento}}</p>
        <p>Compañía: {{inputCompania}}</p>
        <p>Fecha: {{inputFechaInicio}} - {{inputFechaFin}}</p>
        <p>Total: {{inputPrima}} €</p>

        <ng-container *ngIf="inputMatricula.length > 0">
          <p>Matrícula: {{inputMatricula}}</p>
        </ng-container>
        <ng-container *ngIf="inputTipoSalud.length > 0">
          <p>Tipo de seguro de salud: {{inputTipoSalud}}</p>
        </ng-container>
        <ng-container *ngIf="inputDireccion.length > 0">
          <p>Direccion: {{inputDireccion}}</p>
          <p>Metros {{inputMetros}}</p>
          <p>Tipo hogar: {{inputTipo}}</p>
        </ng-container>
        <div class="w-100 d-flex justify-content-end">
          <button nbButton nbStepperPrevious>Anterior</button>
          <button type="submit" class="ml-3" (click)="guardarInfo()" nbButton nbStepperNext>Guardar póliza</button>
        </div>
      </nb-step>
    </nb-stepper>
  </nb-card-body>
</nb-card>
