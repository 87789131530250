import { Component, Input, OnInit } from '@angular/core';

import { FormControl, Validators } from '@angular/forms';
import { TaskModel } from 'src/app/models/task.model';
import { ItemService } from 'src/app/services/item.service';


@Component({
  selector: 'app-to-do',
  templateUrl: './to-do.component.html',
  styleUrls: ['./to-do.component.scss']
})
export class ToDoComponent implements OnInit {

  items: TaskModel[] = [];
  txtInput: FormControl;



  @Input() item;
  @Input() index;
  chkItem: FormControl;

  listItems: TaskModel[];
  codeFilter: string;
  title: string;


  constructor(private itemServices: ItemService, private itemService: ItemService) {
    this.txtInput = new FormControl('',
      [Validators.minLength(5),
      Validators.maxLength(35)])

  }

  ngOnInit(): void {
    this.itemService.codeFilter$.subscribe(code => {
      this.codeFilter = code;
      this.changeTitle(code);
    })

    this.itemService.filter('A');

    this.itemService.item$.subscribe(data => this.listItems = data);
    this.itemService.get();

  }


  save() {
    let newItem = {
      name: this.txtInput.value,
      state: false
    }

    if (this.txtInput.invalid || this.txtInput.value === "") return;
    this.itemService.add(newItem);
    this.txtInput.reset();
  }


  filter(code: string) {
    this.itemServices.filter(code);
  }




  changeTitle(code: string) {
    const All = "A", Completed = "C", Pendings = "P";
    if (code === All) this.title = "Todas";
    else if (code === Completed) this.title = "Hechas";
    else this.title = "Pendientes";
  }

}
