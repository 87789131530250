import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagesRoutingModule } from './pages-routing.module';
import {
  NbMenuModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbUserModule,
  NbIconModule, NbButtonModule, NbTreeGridModule, NbRadioModule, NbListModule, NbAutocompleteComponent, NbAutocompleteDirective, NbInputModule, NbToggleModule, NbPopoverModule, NbChatModule, NbCalendarModule, NbSearchModule, NbTabsetModule, NbSelectModule, NbDatepickerModule
} from '@nebular/theme';
import { NotFoundComponent } from './not-found/not-found.component';

import { InicioComponent } from './inicio/inicio.component';

import { VideosComponent } from './temario/videos/videos.component';
import { TextoComponent } from './temario/texto/texto.component';
import { EstadisticasComponent } from './estadisticas/estadisticas.component';
import { PerfilComponent } from './perfil/perfil.component';
import { TreeGridComponent } from './temario/tree-grid/tree-grid.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { Chart2Component } from './estadisticas/chart2/chart2.component';
import { Chart1Component } from './estadisticas/chart1/chart1.component';
import { Chart3Component } from './estadisticas/chart3/chart3.component';
import { Chart4Component } from './estadisticas/chart4/chart4.component';

import { NbCardModule, NbCalendarKitModule, NbProgressBarModule, NbFormFieldModule, NbAutocompleteModule, NbStepperModule, NbRouteTabsetModule } from 'src/framework/theme/public_api';
import { HttpClientModule } from '@angular/common/http';


import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { CalendarioComponent } from './calendario/calendario.component';


import { CountdownModule } from 'ngx-countdown';
import { PublicoComponent } from './foro/publico/publico.component';
import { PrivadoComponent } from './foro/privado/privado.component';
import { CompetitivoComponent } from './competitivo/competitivo.component';

import { NgFallimgModule } from 'ng-fallimg';
import { FicharComponent } from './fichar/fichar.component';
import { IngresosComponent } from './ingresos/ingresos.component';
import { GastosComponent } from './gastos/gastos.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { ApiService } from 'src/app/services/conexionApi.service';
import { IngresarComponent } from './ingresar/ingresar.component';
import { RetirarComponent } from './retirar/retirar.component';
import { ClientesComponent } from './clientes/clientes.component';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NbAccordionModule } from '../../../framework/theme/components/accordion/accordion.module';
import { AutocompleteModule } from './ingresar/autocomplete/autocomplete.module';
import { AddClientComponent } from './addClient/addClient.component';
import { AddServicioComponent } from './addServicio/addServicio.component';
import { CorrectoComponent } from './Correcto/Correcto.component';
import { SegurosComponent } from './seguros/seguros.component';
import { ContabilidadComponent } from './contabilidad/contabilidad.component';
import { TomaDatosSegurosComponent } from './seguros/tomaDatosSeguros/tomaDatosSeguros.component';
import { PolizasComponent } from './seguros/polizas/polizas.component';
import { TarificarComponent } from './seguros/tarificar/tarificar.component';
import { CauComponent } from './cau/cau.component';
import { EstadisticasGeneralesComponent } from './estadisticasGenerales/estadisticasGenerales.component';
import { InversionComponent } from './inversion/inversion.component';
import { NotificacionesComponent } from '../header/notificaciones/notificaciones.component';
import { VerClienteComponent } from './clientes/ver-cliente/ver-cliente.component';
import { ModalEditarComponent } from './clientes/ver-cliente/components/modal-editar/modal-editar.component';
import { isFoundPipe } from './pipes/isFound/is-found.pipe';
import { DomicializacionesComponent } from './domicializaciones/domicializaciones.component';
import { ChatsComponent } from './chats/chats.component';
import { NextChatComponent } from './chats/nextChat/nextChat.component';
import { EstadisticasJornadasComponent } from './estadisticasJornadas/estadisticasJornadas.component';
import { FacturasComponent } from './facturas/facturas.component';
import { AddDomiciliacionComponent } from './add-domiciliacion/add-domiciliacion.component';
import { ViajesComponent } from './viajes/viajes.component';
import { ReservasComponent } from './viajes/reservas/reservas.component';
import { NewComponent } from './viajes/new/new.component';
import { AutocompletarComponent } from './autocompletar/autocompletar.component';
import { CompletarClienteRoutingModule } from './autocompletar/completar-cliente/completar-cliente-routing.module';
import { CompletarClienteModule } from './autocompletar/completar-cliente/completar-cliente.module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { GestionEconomicaComponent } from './gestion-economica/gestion-economica.component';
import { TesoreriaComponent } from './gestion-economica/tesoreria/tesoreria.component';
import { PresupuestoComponent } from './gestion-economica/presupuesto/presupuesto.component';
import { GastosFijosComponent } from './gastosFijos/gastosFijos.component';
import { IngresosFijosComponent } from './ingresosFijos/ingresosFijos.component';
import { MeetComponent } from './reuniones/meet/meet.component';
import { ReunionesComponent } from './reuniones/reuniones.component';
import { SubirArchivoComponent } from './subir-archivo/subir-archivo.component';
import { GestorDeTareasComponent } from './gestor-de-tareas/gestor-de-tareas.component';

import {DragDropModule} from '@angular/cdk/drag-drop';
import { ToDoComponent } from './gestor-de-tareas/to-do/to-do.component';
import { ItemComponent } from './gestor-de-tareas/to-do/item/item.component';
import { FilterPipe } from 'src/app/pipes/filter.pipe';
import { CajaComponent } from './caja/caja.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';



// import { CompletarClienteComponent } from './autocompletar/completar-cliente/completar-cliente.component';


// import { TextInputAutocompleteModule } from 'angular-text-input-autocomplete';

// import { polyfill as keyboardEventKeyPolyfill } from 'keyboardevent-key-polyfill';

// keyboardEventKeyPolyfill();


@NgModule({
  declarations: [
    NotFoundComponent,
    InicioComponent,
    VideosComponent,
    TextoComponent,
    EstadisticasComponent,
    PerfilComponent,
    TreeGridComponent,
    Chart2Component,
    Chart1Component,
    Chart3Component,
    Chart4Component,

    CalendarioComponent,
    PublicoComponent,
    PrivadoComponent,
    CompetitivoComponent,
    FicharComponent,
    IngresosComponent,
    GastosComponent,
    ServiciosComponent,
    IngresarComponent,
    RetirarComponent,
    ClientesComponent,
    
    AddServicioComponent,
    CorrectoComponent,
    SegurosComponent,
    ContabilidadComponent,
    TomaDatosSegurosComponent,
    PolizasComponent,
    TarificarComponent,
    CauComponent,
    EstadisticasGeneralesComponent,
    InversionComponent,
    NotificacionesComponent,
    DomicializacionesComponent,
    VerClienteComponent,
    ModalEditarComponent,
    isFoundPipe,
    ChatsComponent,
    NextChatComponent,
    EstadisticasJornadasComponent,
    FacturasComponent,
    AddDomiciliacionComponent,
    ViajesComponent,
    ReservasComponent,
    NewComponent,
    AutocompletarComponent,
    GestionEconomicaComponent,
    TesoreriaComponent,
    PresupuestoComponent,
    GastosFijosComponent,
    IngresosFijosComponent,
    MeetComponent,
    ReunionesComponent,
    SubirArchivoComponent,
    GestorDeTareasComponent,
    ToDoComponent,
    ItemComponent,
    FilterPipe,
    CajaComponent


  ],

  imports: [
    CommonModule,
    PagesRoutingModule,
    NbSidebarModule,
    NbMenuModule,
    NbCardModule,
    NbButtonModule,
    NbContextMenuModule,
    NbUserModule,
    NbIconModule,
    NbTreeGridModule,
    NgxChartsModule,
    NbSearchModule,
    NbRadioModule,
    NbInputModule,
    FormsModule,
    HttpClientModule,
    NgxExtendedPdfViewerModule,
    NbCalendarKitModule,
    NbListModule,
    CountdownModule,
    NgFallimgModule,
    Ng2SmartTableModule,
    NbAccordionModule,
    NbProgressBarModule,
    NbCalendarModule,
    NbFormFieldModule,
    NbToggleModule,
    NbAutocompleteModule,
    ReactiveFormsModule,
    NbRouteTabsetModule,
    AutocompleteModule,
    CompletarClienteModule,
    NbStepperModule,
    NbPopoverModule,
    NbTabsetModule,
    NgxDaterangepickerMd.forRoot(),

    NbChatModule.forRoot(),

    NbSelectModule,

    NbDatepickerModule,

    DragDropModule,



   
    ToastrModule.forRoot(), // ToastrModule added


 



  ],
  providers: [ApiService],
})
export class PagesModule { }
