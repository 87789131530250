<h1 id="title" class="title">Registrar</h1>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Oh snap!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Hooray!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="register()" #form="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-name">Nombre completo:</label>
    <input nbInput
           [(ngModel)]="user.fullName"
           #fullName="ngModel"
           id="input-name"
           name="fullName"
           placeholder="Nombre completo"
           autofocus
           fullWidth
           fieldSize="large"
           [status]="fullName.dirty ? (fullName.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.fullName.required')"
           [minlength]="getConfigValue('forms.validation.fullName.minLength')"
           [maxlength]="getConfigValue('forms.validation.fullName.maxLength')"
           [attr.aria-invalid]="fullName.invalid && fullName.touched ? true : null">
    <ng-container *ngIf="fullName.invalid && fullName.touched">
      <p class="caption status-danger" *ngIf="fullName.errors?.required">
        Se requiere nombre completo!
      </p>
      <p class="caption status-danger" *ngIf="fullName.errors?.minlength || fullName.errors?.maxlength">
        El nombre completo debe contener
         {{getConfigValue('forms.validation.fullName.minLength')}}
        a {{getConfigValue('forms.validation.fullName.maxLength')}}
        caracteres
      </p>
    </ng-container>
  </div>

  <div class="form-control-group">
    <label class="label" for="input-email">Correo electrónico:</label>
    <input nbInput
           [(ngModel)]="user.email"
           #email="ngModel"
           id="input-email"
           name="email"
           pattern=".+@.+..+"
           placeholder="Correo electrónico"
           fullWidth
           fieldSize="large"
           [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.email.required')"
           [attr.aria-invalid]="email.invalid && email.touched ? true : null">
    <ng-container *ngIf="email.invalid && email.touched">
      <p class="caption status-danger" *ngIf="email.errors?.required">
        ¡Correo electronico es requerido!
      </p>
      <p class="caption status-danger" *ngIf="email.errors?.pattern">
        ¡El correo electrónico debe ser el verdadero!
      </p>
    </ng-container>
  </div>

  <div class="form-control-group">
    <label class="label" for="input-password">Contraseña:</label>
    <input nbInput
           [(ngModel)]="user.password"
           #password="ngModel"
           type="password"
           id="input-password"
           name="password"
           placeholder="Contraseña"
           fullWidth
           fieldSize="large"
           [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.password.required')"
           [minlength]="getConfigValue('forms.validation.password.minLength')"
           [maxlength]="getConfigValue('forms.validation.password.maxLength')"
           [attr.aria-invalid]="password.invalid && password.touched ? true : null">
    <ng-container *ngIf="password.invalid && password.touched">
      <p class="caption status-danger" *ngIf="password.errors?.required">
        ¡Se requiere contraseña!
      </p>
      <p class="caption status-danger" *ngIf="password.errors?.minlength || password.errors?.maxlength">
        La contraseña debe contener
         {{ getConfigValue('forms.validation.password.minLength') }}
        a {{ getConfigValue('forms.validation.password.maxLength') }}
        caracteres
      </p>
    </ng-container>
  </div>

  <div class="form-control-group">
    <label class="label" for="input-re-password">Confirma la contraseña:</label>
    <input nbInput
           [(ngModel)]="user.confirmPassword"
           #rePass="ngModel"
           type="password"
           id="input-re-password"
           name="rePass"
           placeholder="Confirma la contraseña"
           fullWidth
           fieldSize="large"
           [status]="rePass.dirty ? (rePass.invalid || password.value != rePass.value  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.password.required')"
           [attr.aria-invalid]="rePass.invalid && rePass.touched ? true : null">
    <ng-container *ngIf="rePass.invalid && rePass.touched">
      <p class="caption status-danger" *ngIf="rePass.errors?.required">
        Se requiere confirmación de contraseña!
      </p>
      <p class="caption status-danger" *ngIf="password.value != rePass.value && !rePass.errors?.required">
        La contraseña no coincide con la contraseña de confirmación.
      </p>
    </ng-container>
  </div>

  <div class="form-control-group accept-group" *ngIf="getConfigValue('forms.register.terms')">
    <nb-checkbox name="terms" [(ngModel)]="user.terms" [required]="getConfigValue('forms.register.terms')">
      De acuerdo con <a href="../terminos.html" target="_blank"><strong>Términos y condiciones</strong></a>
    </nb-checkbox>
  </div>

  <button nbButton
          fullWidth
          status="primary"
          size="large"
          [disabled]="submitted || !form.valid"
          [class.btn-pulse]="submitted">
    Registrarse
  </button>
</form>

<section *ngIf="socialLinks && socialLinks.length > 0" class="links" aria-label="Social sign in">
  o entra con:
  <div class="socials">
    <ng-container *ngFor="let socialLink of socialLinks">
      <a *ngIf="socialLink.link"
         [routerLink]="socialLink.link"
         [attr.target]="socialLink.target"
         [attr.class]="socialLink.icon"
         [class.with-icon]="socialLink.icon">
        <nb-icon *ngIf="socialLink.icon; else title" [icon]="socialLink.icon"></nb-icon>
        <ng-template #title>{{ socialLink.title }}</ng-template>
      </a>
      <a *ngIf="socialLink.url"
         [attr.href]="socialLink.url"
         [attr.target]="socialLink.target"
         [attr.class]="socialLink.icon"
         [class.with-icon]="socialLink.icon">
        <nb-icon *ngIf="socialLink.icon; else title" [icon]="socialLink.icon"></nb-icon>
        <ng-template #title>{{ socialLink.title }}</ng-template>
      </a>
    </ng-container>
  </div>
</section>

<section class="another-action" aria-label="Sign in">
¿Ya tienes una cuenta? <a class="text-link" routerLink="../login">Iniciar sesión</a>
</section>
