
<section class="page-section mt-1 pt-1" id="services">
    <div class="container-fluid">
        <div class="text-center">
            <!-- <h2 class="section-heading text-uppercase">¡Bienvenido a accioges!</h2> -->
        </div>

        <!-- Content Row -->
        <div class="row mt-1 mb-3">
            <div class="col-md-8 text-left">



                <nb-card accent="info">

                    <nb-card-header>

                        <div class="text-center d-flex justify-content-between">
                            <div>
                                <h5 class="text-center">Desarollo</h5>

                            </div>
                            <div>


                            </div>

                        </div>
                    </nb-card-header>


                    <nb-card-body class="">

                        <div id="jitsi-iframe" class="w-100 h-100"></div>
                    </nb-card-body>

                    <nb-card-footer>

                       
                    </nb-card-footer>


                </nb-card>

            </div>

            <div class="col-md-4 text-left">



                <nb-card accent="info">

                    <nb-card-header>

                        <div class="text-center d-flex justify-content-between">
                            <div>
                                <h5 class="text-center">Opciones</h5>

                            </div>
                            <div>
                                <!-- <button nbButton outline status="primary" class=" " (click)="borrarDesglose()"> -->
                                <!-- <i class="iconoir-undo lead"> </i> -->
                                <!-- <i class="iconoir-undo-action lead h2" (click)="borrarDesglose()"> </i> -->
                                <!-- </button> -->


                            </div>

                        </div>
                    </nb-card-header>


                    <nb-card-body class="">

                        <button nbButton outline status="primary" class="btnAddClient   m-1" (click)="executeCommand('toggleAudio')">
                            <i class="fas  grey-color"
                            [ngClass]="isAudioMuted ? 'fa-microphone-slash' : 'fa-microphone'" aria-hidden="true"
                            title="Mute / Unmute"></i> Micro
                          </button>

                          <button nbButton outline status="primary" class="btnAddClient   m-1" (click)="executeCommand('hangup')">
                            <i  class="fas fa-phone-slash  red-color"
                            aria-hidden="true" title="Leave"></i> Salir
                          </button>

                          <button nbButton outline status="primary" class="btnAddClient  m-1" (click)="executeCommand('toggleVideo')">
                            <i  class="fas "
                            [ngClass]="isVideoMuted ? 'fa-video-slash' : 'fa-video'" aria-hidden="true"
                            title="Start / Stop camera"></i>
                          </button>

                          <button nbButton outline status="primary" class="btnAddClient  m-1" (click)="executeCommand('toggleShareScreen')">
                            <i class="fas fa-film r"
                                aria-hidden="true" title="Share your screen"></i>
                          </button>
                        
                        
                            
                            

                           

                           
                            
                       

                    </nb-card-body>

                    <nb-card-footer>

                        <!-- <button nbButton outline status="primary" class="btnAddClient mt-2 w-100" (click)="siguiente()">
                            Siguiente
                           
                        </button> -->

                    </nb-card-footer>


                </nb-card>

            </div>


        </div>
    </div>
</section>